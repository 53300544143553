import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d5fc098"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "grid formgrid p-fluid mt-3 mb-3" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "field col" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_12 = { class: "col field" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "field col" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "field col" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_19 = { class: "col field" }
const _hoisted_20 = { class: "text-sm mr-3" }
const _hoisted_21 = { class: "grid formgrid p-fluid mb-3" }
const _hoisted_22 = { class: "field col" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "field col" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 0,
  class: "flex justify-content-end"
}

export function render(_ctx, _cache) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_app_checkbox = _resolveComponent("app-checkbox")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[18] || (_cache[18] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
    }, [
      _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 28rem)"} }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.weeeClasification')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_weee_relevant?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.weeeClasification,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.bulkEditSwitches.weeeClasification) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_dropdown, {
                modelValue: _ctx.v$.weeeClasification.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.weeeClasification.$model) = $event)),
                options: _ctx.weeeOptions,
                optionLabel: "label",
                optionValue: "value",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.weeeClasification,
                onChange: _cache[2] || (_cache[2] = $event => (_ctx.setDropdownValue('weeeClasification', $event))),
                class: _normalizeClass([{'p-invalid':_ctx.v$.weeeClasification.$invalid && _ctx.submitted}, "w-full"])
              }, null, 8, ["modelValue", "options", "disabled", "class"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.battgClass')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_batteriegesetz_class?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.battgClass,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.bulkEditSwitches.battgClass) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_dropdown, {
                modelValue: _ctx.v$.battgClass.$model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.battgClass.$model) = $event)),
                options: _ctx.battgClassOptions,
                optionLabel: "label",
                optionValue: "value",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.battgClass,
                onChange: _cache[5] || (_cache[5] = $event => (_ctx.setDropdownValue('battgClass', $event))),
                class: _normalizeClass([{'p-invalid':_ctx.v$.battgClass.$invalid && _ctx.submitted}, "w-full"])
              }, null, 8, ["modelValue", "options", "disabled", "class"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _withDirectives((_openBlock(), _createElementBlock("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.lastCheckDate')), 1)
                ])), [
                  [
                    _directive_tooltip,
                    _ctx.customAttributesDefinition?.article_compliance_date_last_check?.attributeDescription,
                    void 0,
                    { top: true }
                  ]
                ]),
                (_ctx.isBulkEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_InputSwitch, {
                        modelValue: _ctx.bulkEditSwitches.lastCheckDate,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.bulkEditSwitches.lastCheckDate) = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.lastCheckDate,
                modelValue: _ctx.v$.lastCheckDate.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.lastCheckDate.$model) = $event)),
                class: "full-width",
                showButtonBar: ""
              }, null, 8, ["disabled", "modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['conformityAvailable', 'safetyInstructions', 'emc', 'atex'], (fieldId) => {
              return _createElementVNode("div", _hoisted_10, [
                _withDirectives((_openBlock(), _createElementBlock("label", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.' + fieldId)), 1)
                ])), [
                  [
                    _directive_tooltip,
                    _ctx.customAttributesDefinition?.[_ctx.mapping[fieldId]]?.attributeDescription,
                    void 0,
                    { top: true }
                  ]
                ]),
                (!_ctx.isBulkEdit)
                  ? (_openBlock(), _createBlock(_component_app_checkbox, {
                      key: 0,
                      modelValue: _ctx.v$[fieldId].$model,
                      "onUpdate:modelValue": $event => ((_ctx.v$[fieldId].$model) = $event),
                      binary: true
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                      key: 1,
                      modelValue: _ctx.v$[fieldId].$model,
                      "onUpdate:modelValue": $event => ((_ctx.v$[fieldId].$model) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              ])
            }), 64))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.doc')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_compliance_doc?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.doc,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.bulkEditSwitches.doc) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.doc,
                modelValue: _ctx.v$.doc.$model,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.doc.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.doc.$invalid && _ctx.submitted})
              }, null, 8, ["disabled", "modelValue", "class"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.additionalInformation')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_compliance_additional_info?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.additionalInformation,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.bulkEditSwitches.additionalInformation) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.additionalInformation,
                modelValue: _ctx.v$.additionalInformation.$model,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.additionalInformation.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.additionalInformation.$invalid && _ctx.submitted})
              }, null, 8, ["disabled", "modelValue", "class"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.manufacturerAddress')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_compliance_manufacturer_address?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.manufacturerAddress,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.bulkEditSwitches.manufacturerAddress) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.manufacturerAddress,
                modelValue: _ctx.v$.manufacturerAddress.$model,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.manufacturerAddress.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.manufacturerAddress.$invalid && _ctx.submitted})
              }, null, 8, ["disabled", "modelValue", "class"])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['marketReady', 'testProtocol', 'red', 'lvd', 'rohs'], (fieldId) => {
              return _createElementVNode("div", _hoisted_19, [
                _withDirectives((_openBlock(), _createElementBlock("label", _hoisted_20, [
                  _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.' + fieldId)), 1)
                ])), [
                  [
                    _directive_tooltip,
                    _ctx.customAttributesDefinition?.[_ctx.mapping[fieldId]]?.attributeDescription,
                    void 0,
                    { top: true }
                  ]
                ]),
                (!_ctx.isBulkEdit)
                  ? (_openBlock(), _createBlock(_component_app_checkbox, {
                      key: 0,
                      modelValue: _ctx.v$[fieldId].$model,
                      "onUpdate:modelValue": $event => ((_ctx.v$[fieldId].$model) = $event),
                      binary: true
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                  : (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                      key: 1,
                      modelValue: _ctx.v$[fieldId].$model,
                      "onUpdate:modelValue": $event => ((_ctx.v$[fieldId].$model) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]))
              ])
            }), 64))
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.germanManual')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_compliance_german_manual?.attributeDescription,
                  void 0,
                  { top: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.germanManual,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.bulkEditSwitches.germanManual) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.germanManual,
                modelValue: _ctx.v$.germanManual.$model,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$.germanManual.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.germanManual.$invalid && _ctx.submitted})
              }, null, 8, ["disabled", "modelValue", "class"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productCompliance.germanInstructions')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_german_instructions?.attributeDescription,
                  void 0,
                  { right: true }
                ]
              ]),
              (_ctx.isBulkEdit)
                ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                    _createVNode(_component_InputSwitch, {
                      modelValue: _ctx.bulkEditSwitches.germanInstructions,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.bulkEditSwitches.germanInstructions) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                disabled: _ctx.isBulkEdit && !_ctx.bulkEditSwitches.germanInstructions,
                modelValue: _ctx.v$.germanInstructions.$model,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.v$.germanInstructions.$model) = $event)),
                class: _normalizeClass({'p-invalid':_ctx.v$.germanInstructions.$invalid && _ctx.submitted})
              }, null, 8, ["disabled", "modelValue", "class"])
            ])
          ])
        ]),
        _: 1
      }),
      (!_ctx.isBulkEdit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
            _createVNode(_component_p_button, {
              disabled: _ctx.savingInProgress,
              severity: "success",
              textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
              type: "submit"
            }, null, 8, ["disabled", "textContent"])
          ]))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}