import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;
    const params = Object.assign(
        {},
        {
            allEnvs:
                typeof input.allEnvs !== 'undefined' ? input.allEnvs : false,
            ownOnly: input.ownOnly ?? false,
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {})),
            filterConjunction: input.filterConjunction || 'and'
        }
    );

    return apiClient.get('/api/v1/imports', {params});
};

export const getSingle = (id: number, input: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/imports/' + id + '/logs', {
        params: {
            start: input.first || 0,
            end: (input.first || 0) + (input.rows || 10),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {}))
        }
    });
};

export const getResultFile = (id: number): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/imports/' + id + '/logs-export', {
        responseType: 'blob'
    });
};
