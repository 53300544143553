<!--<loading v-model:active="savingInProgress" />-->
<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <Panel>
        <template #header>
            <div class="flex justify-content-between w-full">
                <div class="font-bold">Maße & Gewichte</div>
            </div>
        </template>
        <template v-for="(item, index) in state.packageItems" :key="index">
            <div class="grid">
                <div class="col">
                    <label>Karton scannen</label>
                    <div>
                        <AutoComplete
                            v-if="index === 0"
                            v-model="item.weclappProduct"
                            :suggestions="filteredProducts"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].weclappProduct.$invalid}"
                            @complete="searchProducts($event, index)"
                            @item-select="onProductChange($event, index)"
                            optionLabel="name"
                            dropdown
                            ref="firstRowAutocompleteField"
                            :id="'package_selection_' + index"
                        >
                        </AutoComplete>
                        <AutoComplete
                            v-else
                            v-model="item.weclappProduct"
                            :suggestions="filteredProducts"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].weclappProduct.$invalid}"
                            @complete="searchProducts($event, index)"
                            @item-select="onProductChange($event, index)"
                            optionLabel="name"
                            dropdown
                            ref="firstRowAutocompleteField"
                            :id="'package_selection_' + index"
                        >
                        </AutoComplete>
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.packageItems.$each.$response.$errors[index].weclappProduct"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                        <small class="font-bold ml-1"
                            >{{item.orderItem?.title ||
                            item.article?.name}}</small
                        >
                    </div>
                </div>
                <div class="col">
                    <label>{{$t('labels.productHome.grossWeightShort')}}</label>
                    <div>
                        <InputNumber
                            :locale="locale"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].grossWeight.$invalid}"
                            mode="decimal"
                            :min="item?.weclappProduct?.articleGrossWeight || 0"
                            showButtons
                            :suffix="' kg'"
                            :minFractionDigits="1"
                            :step="0.1"
                            v-model="item.grossWeight"
                            @input="onPastedDimension(index, 'grossWeight')"
                            ref="grossWeightField"
                            :id="'gross_weight_' + index"
                        />
                        <small
                            v-if="item?.weclappProduct?.articleGrossWeight"
                            style="display: block"
                        >
                            <span>Kartongewicht: </span
                            ><span class="font-bold"
                                >{{formatter.format(item?.weclappProduct?.articleGrossWeight)
                                }} kg</span
                            >
                        </small>
                        <small
                            v-if="submitted"
                            style="display: block"
                            class="p-error"
                            v-for="error in v$.packageItems.$each.$response.$errors[index].grossWeight"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="col">
                    <label>{{$t('labels.productHome.lengthShort')}}</label>
                    <div>
                        <InputNumber
                            :locale="locale"
                            :suffix="' cm'"
                            :min="0"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].length.$invalid}"
                            showButtons
                            :step="1"
                            :maxFractionDigits="1"
                            v-model="item.length"
                            @input="onPastedDimension(index, 'length')"
                            ref="lengthField"
                            :id="'length_' + index"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            class="p-error"
                            v-for="error in v$.packageItems.$each.$response.$errors[index].length"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="col">
                    <label>{{$t('labels.productHome.widthShort')}}</label>
                    <div>
                        <InputNumber
                            :locale="locale"
                            :suffix="' cm'"
                            :min="0"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].width.$invalid}"
                            showButtons
                            :step="1"
                            :maxFractionDigits="1"
                            v-model="item.width"
                            @input="onPastedDimension(index, 'width')"
                            ref="widthField"
                            :id="'width_' + index"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            class="p-error"
                            v-for="error in v$.packageItems.$each.$response.$errors[index].width"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="col">
                    <label>{{$t('labels.productHome.heightShort')}}</label>
                    <div>
                        <InputNumber
                            :locale="locale"
                            :suffix="' cm'"
                            :min="0"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].height.$invalid}"
                            showButtons
                            :step="1"
                            :maxFractionDigits="1"
                            v-model="item.height"
                            @input="onPastedDimension(index, 'height')"
                            ref="heightField"
                            :id="'height_' + index"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            class="p-error"
                            v-for="error in v$.packageItems.$each.$response.$errors[index].height"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="col-1" v-if="hasDangerousGoods">
                    <label>Gefahrgut</label>
                    <div
                        v-tooltip.left="dangerousGoodsSuitableRecipientAddress ? '' : $t('messages.deliveryOfDangerousGoodsToNotSuitableCountry')"
                    >
                        <p-checkbox
                            :disabled="!dangerousGoodsSuitableRecipientAddress"
                            binary
                            class="mt-2"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].dangerousGoods.$invalid}"
                            v-model="item.dangerousGoods"
                        />
                    </div>
                    <small
                        v-if="submitted"
                        style="display: block"
                        class="p-error"
                        v-for="error in v$.packageItems.$each.$response.$errors[index].dangerousGoods"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col" v-if="hasDangerousGoods">
                    <label>{{$t('labels.productHome.netWeightShort')}}</label>
                    <div>
                        <InputNumber
                            v-tooltip.left="(dangerousGoodsSuitableRecipientAddress || !item.dangerousGoods) ? '' : $t('messages.deliveryOfDangerousGoodsToNotSuitableCountry')"
                            :locale="locale"
                            :min="0"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].netWeight.$invalid}"
                            mode="decimal"
                            :suffix="' kg'"
                            showButtons
                            :minFractionDigits="1"
                            :step="0.1"
                            :disabled="!item.dangerousGoods || !dangerousGoodsSuitableRecipientAddress"
                            v-model="item.netWeight"
                            @input="onPastedDimension(index, 'netWeight')"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            class="p-error"
                            v-for="error in v$.packageItems.$each.$response.$errors[index].netWeight"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="col" v-if="hasDangerousGoods">
                    <label> {{$t('labels.shipment.upsChemicalId')}} </label>
                    <div>
                        <p-dropdown
                            v-model="item.chemicalId"
                            :options="chemicalOptions"
                            optionLabel="idNumber"
                            optionValue="idNumber"
                            class="w-full"
                            showClear
                            :disabled="!item.dangerousGoods || !dangerousGoodsSuitableRecipientAddress"
                            :class="{'p-invalid':submitted && v$.packageItems.$each.$response.$data[index].chemicalId.$invalid}"
                        >
                        </p-dropdown>
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$.packageItems.$each.$response.$errors[index].chemicalId"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                        <small v-if="item.chemicalId" style="display: block">
                            {{ chemicalOptions.find((opt) => opt.idNumber ===
                            item.chemicalId).hazardousMaterialsDescription }}
                        </small>
                    </div>
                </div>

                <div class="col-1">
                    <span
                        class="pi mt-4 pi-plus-circle text-green-600 text-2xl"
                        style="position: relative; top: 1px; cursor: pointer"
                        @click="addPackageItem(null)"
                    ></span>
                    <span
                        v-if="index > 0"
                        class="pi ml-3 pi-times-circle text-red-600 text-2xl"
                        style="position: relative; top: 1px; cursor: pointer"
                        @click="removePackageItem(index)"
                    ></span>
                </div>
            </div>
        </template>
    </Panel>
</form>
