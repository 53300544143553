import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAllCustomers = (
    input: any,
    platform: string | null = null
): Promise<AxiosResponse> => {
    const filters = Object.assign(
        {
            customer: {
                value: true,
                matchMode: 'equals'
            }
        },
        input.filters
    );
    return apiClient.get('/api/v1/parties', {
        params: Object.assign(
            {
                start: input.first || 0,
                end: (input.first || 0) + (input.rows || 10),
                sortField: input.sortField || '',
                sortOrder: input.sortOrder === 1 ? 'asc' : 'desc',
                columns: JSON.stringify(input.columns || []),
                filters: JSON.stringify(clearEmptyFilters(filters || {})),
                filterConjunction: input.filterConjunction || 'or'
            },
            platform
                ? {
                      platform
                  }
                : {}
        )
    });
};

export const getAllCustomersUsingHashedData = (
    company: string | null = null,
    street: string | null = null,
    postalCode: string | null = null,
    countryCode: string | null = null,
    platform: string | null = null
): Promise<AxiosResponse> => {
    const asArray = Object.entries({
        company,
        street,
        postalCode,
        countryCode,
        platform
    });

    const filtered = asArray.filter((value: any) => {
        return (
            value.length === 2 && value[1] !== null && value[1].trim() !== ''
        );
    });

    const params = Object.fromEntries(filtered);

    return apiClient.get('/api/v1/search-weclapp-customer-data', {params});
};

export const recalculateCustomerDataHashes = (
    customerNumbers: string[]
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/recalculate-customer-data-hash', {
        customerNumbers
    });
};

export const editSingleCustomer = (
    customerNumber: string | string[],
    payload: any,
    platform: string | null = null
): Promise<AxiosResponse> => {
    if (platform) {
        return apiClient.put('/api/v1/parties/' + customerNumber, payload, {
            params: {platform}
        });
    }
    return apiClient.put('/api/v1/parties/' + customerNumber, payload);
};
