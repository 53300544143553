<loading v-model:active="loading" />
<Panel :header="$t('labels.accessLog')">
    <DataTable
        :value="accessLogs"
        rowGroupMode="subheader"
        groupRowsBy="representative.name"
        sortMode="single"
        sortField="representative.name"
        :sortOrder="1"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
    >
        <Column field="representative.name"></Column>
        <Column field="date"></Column>
        <Column field="hits" header="Hits"></Column>
        <!--<Column field="updates" header="Updates"></Column>-->

        <template #groupheader="slotProps">
            <div class="flex align-items-center gap-2">
                <font-awesome-icon
                    class="layout-menuitem-icon"
                    :icon="slotProps.data.representative.iconClass"
                />

                <span class="layout-menuitem-text font-bold">
                    {{ $t(slotProps.data.representative.label) }}</span
                >
            </div>
        </template>
    </DataTable>
</Panel>
