<loading v-model:active="loading" />
<Panel :header="$t('labels.shipment.shipmentMask')">
    <div class="p-fluid mb-5">
        <InputText
            :disabled="shipmentNumberPreSelected"
            :placeholder="$t('labels.shipment.shipmentNumber')"
            autocomplete="off"
            v-model.trim="shipmentNumber"
            @paste="onShipmentNumberPaste"
            @input="onShipmentNumberChange"
            v-on:keyup.enter="onShipmentNumberEnter"
            ref="shipmentNumberInputField"
        />
    </div>
    <template v-if="shipmentDetails">
        <Panel :header="'Übersicht'" toggleable>
            <div class="grid mb-2">
                <div class="col">
                    <div class="flex align-content-center">
                        <span
                            v-if="shipmentDetails?.salesChannel"
                            class="sales-channel-dot mr-2"
                            :class="{'sales-channel-teltec': shipmentDetails.salesChannel === 'NET1', 'sales-channel-videodata': shipmentDetails.salesChannel === 'NET2'}"
                        ></span>
                        <span class="text-2xl">
                            <a
                                :href="shipmentDetails.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + shipmentDetails.id"
                                target="_blank"
                                >{{ shipmentDetails.shipmentNumber }}</a
                            >
                        </span>
                    </div>
                    <div class="mt-3">
                        <span
                            class="product-badge text-white"
                            :class="{'bg-orange-600' : shipmentDetails.status === 'DELIVERY_NOTE_PRINTED', 'bg-blue-600':shipmentDetails.status !== 'DELIVERY_NOTE_PRINTED'}"
                            >{{$t('labels.shipment.statusOptions.' +
                            shipmentDetails.status) }}</span
                        >
                    </div>
                    <div class="text-sm font-semibold mt-2">
                        {{$t('labels.productHome.grossWeightShort')}}:
                        {{formatter.format(getShipmentTotalGrossWeight(shipmentDetails))
                        }} kg
                    </div>
                </div>
                <div class="col">
                    <div class="text-xl mb-2 font-semibold">
                        {{$t('labels.shipment.recipient')}}
                    </div>
                    <div
                        class="text-purple-600"
                        v-if="shipmentDetails.shipmentType === 'INTERNAL'"
                    >
                        <font-awesome-icon
                            class="text-2xl text-purple-600 mr-2"
                            :icon="['fas', 'house-user']"
                        />
                        {{$t('labels.shipment.shipmentTypeOptions.INTERNAL')}}
                    </div>
                    <div v-else>
                        <font-awesome-icon
                            class="text-xl mr-2"
                            :icon="['fas', 'user']"
                        />
                        <a
                            :href="shipmentDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + shipmentDetails.recipientParty?.id"
                            target="_blank"
                            >{{ outputRecipient(shipmentDetails) }}</a
                        >
                    </div>
                </div>

                <div class="col">
                    <div class="text-xl mb-2 font-semibold">
                        {{$t('labels.shipment.shipmentAddress')}}
                    </div>
                    <div
                        class="font-semibold text-sm"
                        v-if="shipmentDetails.recipientAddress?.company"
                    >
                        {{shipmentDetails.recipientAddress?.company}}
                    </div>
                    <div
                        class="font-semibold text-sm"
                        v-else-if="shipmentDetails.recipientAddress?.lastName"
                    >
                        {{shipmentDetails.recipientAddress?.salutation}}
                        {{shipmentDetails.recipientAddress?.firstName}}
                        {{shipmentDetails.recipientAddress?.lastName}}
                    </div>
                    <div class="font-semibold text-sm">
                        {{shipmentDetails.recipientAddress?.street1}},
                        {{shipmentDetails.recipientAddress?.zipcode}}
                        {{shipmentDetails.recipientAddress?.city}},
                        {{shipmentDetails.recipientAddress?.countryCode}}
                    </div>
                </div>
                <div class="col">
                    <div class="text-xl mb-2 font-semibold">
                        {{$t('labels.shipment.shippingCarrier')}}
                    </div>
                    <p-dropdown
                        class="w-full"
                        v-model="v$.shippingCarrier.$model"
                        optionLabel="label"
                        optionValue="value"
                        :options="shippingCarrierOptions"
                        @change="onShippingCarrierChange"
                        :disabled="shipmentDetails.isPickup"
                        :class="{'p-invalid':v$.shippingCarrier.$invalid && submitted}"
                    />
                    <small
                        v-if="shipmentDetails?.customAttributes?.cust_ups_account_no"
                    >
                        <span>{{ $t('labels.shipment.upsNumber') }} </span>
                        <span class="font-semibold ml-2">
                            {{shipmentDetails?.customAttributes?.cust_ups_account_no}}
                        </span>
                    </small>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.shippingCarrier.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col">
                    <div class="text-xl mb-2 font-semibold">
                        {{$t('labels.shipment.shipmentLocation')}}
                    </div>
                    <template v-for="platformItem in shippingLocationOptions">
                        <div
                            v-if="v$.shippingLocation.$model === platformItem.value"
                            @click="toggleDataTable"
                            style="cursor: pointer"
                        >
                            <font-awesome-icon :icon="['fas', 'truck']" />
                            &nbsp;&nbsp;{{ platformItem.label }}
                        </div>
                    </template>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.shippingLocation.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </Panel>
        <div class="grid mt-1">
            <div class="col">
                <Panel :header="$t('labels.shipmentPositions')" toggleable>
                    <DataTable
                        class="p-datatable-sm mask-positions"
                        stripedRows
                        ref="dt"
                        dataKey="id"
                        :value="groupedShipmentItems"
                        responsiveLayout="scroll"
                        v-model:expandedRows="expandedRows"
                        :rowClass="rowClass"
                    >
                        <Column :expander="true" headerStyle="width: 3em" />
                        <Column
                            field="positionNumber"
                            :sortable="groupedShipmentItems?.length > 1"
                            style="min-width: 9rem"
                        >
                        </Column>
                        <Column
                            v-if="v$.groupedShipments.$model && v$.groupedShipments.$model.length > 0"
                            style="min-width: 9rem"
                            :header="$t('labels.shipment.shipmentNumber')"
                        >
                            <template #body="{data}">
                                {{ data.shipmentNumber }}
                            </template>
                        </Column>
                        <Column
                            field="title"
                            :sortable="shipmentDetails.shipmentItems?.length > 1"
                            style="min-width: 9rem"
                            :header="$t('labels.article')"
                        >
                            <template #body="{data}">
                                <div class="flex align-items-center">
                                    {{ data.title }}
                                    <img
                                        v-if="data.article_flag_akku_groesser_100wh"
                                        src="@/assets/img/akku_groesser.png"
                                        :alt="$t('labels.productHome.dangerousGoods')"
                                        style="height: 30px; padding-left: 10px"
                                        v-tooltip.top="$t('labels.productHome.dangerousGoods')"
                                    />
                                    <img
                                        v-if="data.article_flag_akku_kleiner_100wh"
                                        src="@/assets/img/akku_kleiner.jpg"
                                        :alt="$t('labels.productHome.dangerousGoodsSmall')"
                                        style="height: 30px; padding-left: 10px"
                                        v-tooltip.top="$t('labels.productHome.dangerousGoodsSmall')"
                                    />
                                    <img
                                        v-if="data.article_flag_aerosole"
                                        src="@/assets/img/aerosole.png"
                                        :alt="$t('labels.productHome.containsAerosols')"
                                        style="height: 30px; padding-left: 10px"
                                        v-tooltip.top="$t('labels.productHome.containsAerosols')"
                                    />
                                </div>
                            </template>
                        </Column>
                        <Column
                            field="quantity"
                            :sortable="shipmentDetails.shipmentItems?.length > 1"
                            style="min-width: 9rem"
                            :header="$t('labels.shipment.shipmentQuantity')"
                        >
                            <template #body="{data}">
                                {{ data.quantity }} {{ data.unitName }}
                            </template>
                        </Column>
                        <Column
                            field="articleNumber"
                            :sortable="shipmentDetails.shipmentItems?.length > 1"
                            style="min-width: 9rem"
                            :header="$t('labels.articleNumber')"
                        >
                        </Column>
                        <Column
                            :sortable="shipmentDetails.shipmentItems?.length > 1"
                            style="min-width: 9rem"
                            :header="$t('labels.serialNumbers')"
                        >
                            <template #body="{data}">
                                {{ data.serialNumbers?.join(', ') }}
                            </template>
                        </Column>
                        <Column style="max-width: 4rem">
                            <template #body="{data}">
                                <i
                                    v-if="showPackageDimensionForm && data?.article?.articleGrossWeight && data?.article?.articleLength && data?.article?.articleHeight && data?.article?.articleWidth"
                                    @click="useArticleDimensions(data)"
                                    class="pi pi-box ml-2"
                                    v-tooltip.right="'Artikelmaße verwenden'"
                                    style="cursor: pointer"
                                ></i>
                            </template>
                        </Column>
                        <template #expansion="slotProps">
                            <div
                                v-if="slotProps.data.description"
                                v-html="slotProps.data.description"
                            ></div>
                            <div v-else>No description available.</div>
                        </template>
                    </DataTable>
                </Panel>
            </div>
            <div
                class="col-3"
                v-if="(isUpsCarrier || isDhlCarrier) && groupedShipmentOptions.length > 0"
            >
                <Panel :header="'Weitere Lieferungen des Kunden'">
                    <template
                        v-for="(grOption, grIndex) in groupedShipmentOptions"
                        :key="grIndex"
                        ><div class="grid p-2 mb-2">
                            <div class="w-full flex">
                                <p-checkbox
                                    v-model="v$.groupedShipments.$model"
                                    :disabled="groupedMultiSelectOptionFilterFunction(grOption)"
                                    :value="grOption.shipmentNumber"
                                    @change="onCheckboxChange"
                                ></p-checkbox>
                                <span
                                    class="ml-2 mt-1"
                                    :class="{'text-red-600' : groupedMultiSelectOptionFilterFunction(grOption)}"
                                >
                                    {{grOption.shipmentNumber}}
                                    <span
                                        v-if="(grOption.shipmentItems || []).length > 0"
                                        class="text-sm"
                                    >
                                        ({{$t('labels.productHome.grossWeightShort')}}:
                                        {{formatter.format(getShipmentTotalGrossWeight(grOption))
                                        }} kg)
                                    </span>
                                </span>
                            </div>
                        </div>
                    </template>
                </Panel>
            </div>
        </div>
        <Message
            v-if="shipmentDetails?.pickingInstructions"
            :closable="false"
            severity="error"
            ><template #messageicon><span></span> </template
            >{{shipmentDetails.pickingInstructions}}</Message
        >

        <div class="grid" v-if="showPackageDimensionForm">
            <div class="col">
                <PackageDimensionsForm
                    :shipment="shipmentDetails"
                    :hasDangerousGoods="showUpsDangerousGoodsButton"
                    @data-entered="onPackageDimensionsEntered"
                ></PackageDimensionsForm>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.packageItems.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid" v-if="!isStatusNew && (isUpsCarrier || isDhlCarrier)">
            <div class="col">
                <ExternalCarrierForm
                    :shipping-carrier-type="isUpsCarrier ? 'ups' : (isDhlCarrier ? 'dhl' : null)"
                    @data-entered="onExternalCarrierDataEntered"
                    toggleable
                    collapsed
                >
                </ExternalCarrierForm>
            </div>
        </div>
        <div class="flex justify-content-end mt-3">
            <span
                v-if="isStatusNew && !(isUpsCarrier || isDhlCarrier)"
                v-tooltip.top="haveCompleteWorkflowButtonDisabled"
            >
                <p-button
                    class="ml-2"
                    severity="info"
                    :disabled="haveCompleteWorkflowButtonDisabled"
                    v-text="'Lieferungs-Komplett-Workflow'"
                    @click="handleStatusUpdate('full')"
                >
                </p-button>
            </span>
            <span v-tooltip.top="haveSavingButtonDisabled">
                <p-button
                    class="ml-2"
                    severity="success"
                    :disabled="haveSavingButtonDisabled"
                    v-text="savingInProgress ? $t('labels.inProgress') : (isStatusNew ? $t('buttons.createDeliveryNote') : $t('buttons.completeShipment'))"
                    @click="isStatusNew ? handleStatusUpdate('create-delivery-note') : handleWeightSubmit()"
                >
                </p-button>
            </span>
            <p-button
                severity="danger"
                :disabled="savingInProgress"
                @click="handleCancelClick"
                class="ml-2"
            >
                {{ $t("buttons.cancelShipment") }}
            </p-button>
        </div>
    </template>
</Panel>

<OverlayPanel
    ref="opShippingLocations"
    appendTo="body"
    id="overlay_panel_shipping_locations"
>
    <DataTable
        :value="shippingLocationOptions"
        :paginator="shippingLocationOptions?.length > 5"
        :rows="5"
        responsiveLayout="scroll"
    >
        <Column headerStyle="min-width:12rem;display:none;"
            ><template #body="{data}">
                <div
                    style="cursor: pointer"
                    @click="changeShippingLocation(data.value);toggleDataTable();"
                >
                    <font-awesome-icon :icon="['fas', 'truck']" />
                    &nbsp;&nbsp;{{ data.label }}
                </div>
            </template></Column
        >
    </DataTable>
</OverlayPanel>
<!-- /.card -->
<p-dialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="displayDangerousGoodsConfirmationDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '450px'}"
    :modal="true"
>
    <div class="confirmation-content">
        <div class="grid mb-3">
            <div
                class="col d-flex justify-content-center"
                v-if="confirmationNeededForAkkuGroesser"
            >
                <img
                    class="rounded"
                    src="@/assets/img/akku_groesser.png"
                    :alt="$t('labels.productHome.dangerousGoods')"
                    style="height: 120px"
                    v-tooltip.top="$t('labels.productHome.dangerousGoods')"
                />
            </div>
            <div
                class="col d-flex justify-content-center"
                v-if="confirmationNeededForAkkuKleiner"
            >
                <img
                    class="rounded"
                    src="@/assets/img/akku_kleiner.jpg"
                    :alt="$t('labels.productHome.dangerousGoodsSmall')"
                    style="height: 120px"
                    v-tooltip.top="$t('labels.productHome.dangerousGoodsSmall')"
                />
            </div>
            <div
                class="col d-flex justify-content-center"
                v-if="confirmationNeededForAerosols"
            >
                <img
                    class="rounded"
                    src="@/assets/img/aerosole.png"
                    :alt="$t('labels.productHome.containsAerosols')"
                    style="height: 120px"
                    v-tooltip.top="$t('labels.productHome.containsAerosols')"
                />
            </div>
            <div
                class="col d-flex justify-content-center mb-2"
                v-if="confirmationNeededForSpedition"
            >
                <font-awesome-icon
                    :icon="['fas', 'cart-flatbed']"
                    style="font-size: 4rem"
                />
            </div>
        </div>
        <div v-if="confirmationNeededForSpedition">
            {{ $t('messages.speditionProductWarning', {product:
            confirmationNeededForSpedition}) }}
        </div>
        <div v-else>{{ $t('messages.dangerousGoodsConfirmation') }}</div>
    </div>
    <template #footer>
        <div class="w-full flex justify-content-between">
            <p-button
                severity="danger"
                @click="displayDangerousGoodsConfirmationDialog = false"
            >
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                @click="handleDangerousGoodsConfirmClick"
            >
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </template>
</p-dialog>
<p-dialog
    :header="$t('labels.result')"
    v-model:visible="showWorkflowExecutionOverviewDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '600px'}"
    modal
    :closable="false"
>
    <div v-for="item in workflowExecutionResults" class="w-full mb-3">
        <i
            v-if="!item.error"
            class="pi text-green-600 text-lg pi-check-circle mr-2"
        ></i>
        <i
            v-else="!item.error"
            class="pi text-lg text-red-600 pi-times-circle mr-2"
        ></i>
        <span class="font-semibold"
            >{{$t("labels.shipment.workflow.steps." + item?.step)}}</span
        >

        <div>
            <small v-if="item.result && typeof item.result === 'string'"
                >{{item.result}}</small
            >
        </div>
    </div>
    <template #footer>
        <div
            class="w-full flex justify-content-end"
            :class="{'justify-content-between' : workflowIdenfier==='full', 'justify-content-end' : workflowIdenfier!=='full'}"
        >
            <p-button
                v-if="false"
                severity="danger"
                @click="showWorkflowExecutionOverviewDialog = false"
            >
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                @click="handleWorkflowResultsConfirmed"
            >
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </template>
</p-dialog>
