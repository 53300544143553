import {computed, onMounted, ref, watch} from 'vue';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';
import {getAll} from '@/services/metadata';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import Chips from 'primevue/chips';
import store from '@/store';
import {DateTime} from 'luxon';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {getProfile} from '@/services/auth';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        duplicateCheckValues: {
            type: Object,
            default: null
        },
        pairValues: {
            type: Object,
            default: null
        },
        hideBackButton: {
            type: Boolean,
            default: false
        },
        customAttributesDefinition: {
            type: Object,
            default: null
        }
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        'p-dropdown': Dropdown,
        InputNumber,
        Chips
    },
    setup(props: any, context: any) {
        const hideBackButton = ref(props.hideBackButton);
        const pairValues = ref(props.pairValues);
        const submitted = ref(false);
        const toast = useToast();
        const unitOptions = ref([]);
        const currencyOptions = ref([]);
        const customAttributesDefinition = ref(
            props.customAttributesDefinition
        );
        const duplicateCheckValues = ref(props.duplicateCheckValues);

        const weeeOptions = computed(() => {
            return (
                customAttributesDefinition.value?.article_weee_relevant
                    ?.selectableValues || []
            ).map((item: {id: string; value: string}) => {
                return {
                    label: stripTagsAndTruncate(item.value, 100, '...'),
                    value: item.id
                };
            });
        });

        const warrantyTimeOptions = computed(() => {
            return (
                customAttributesDefinition.value?.article_garantiezeit
                    ?.selectableValues || []
            ).map((item: {id: string; value: string}) => {
                return {
                    label: stripTagsAndTruncate(item.value, 100, '...'),
                    value: item.id
                };
            });
        });

        onMounted(async () => {
            getAll(['unit', 'currency'])
                .then((data) => {
                    if (data.data?.unit) {
                        unitOptions.value = parseIntoDropdownList(
                            data.data.unit
                        );
                    }
                    if (data.data?.currency) {
                        currencyOptions.value = parseIntoDropdownList(
                            data.data.currency
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });

            const user =
                (await store.getters['auth/user']) || (await getProfile());

            Object.assign(state.value, {
                articleCreator: user?.username
            });
        });

        const prePopulateForm = (sourceObj: any) => {
            Object.assign(state.value, {
                name:
                    sourceObj?.name || duplicateCheckValues.value?.name || null,
                ean: sourceObj?.ean || duplicateCheckValues.value?.ean || null,
                manufacturerPartNumber:
                    sourceObj?.manufacturerPartNumber ||
                    duplicateCheckValues.value?.manufacturerPartNumber ||
                    null,
                uvpPrice: sourceObj?.uvpPrice || null,
                productGroupId: sourceObj?.productGroupId || null,
                articleOversized:
                    sourceObj?.customAttributes?.article_ueberlaenge || false,
                articleBulkGoods:
                    sourceObj?.customAttributes?.article_sperrgut || false,
                articleBattg:
                    sourceObj?.customAttributes
                        ?.article_batteriegesetz_relevant || false,

                articleDangerousGoods:
                    sourceObj?.customAttributes
                        ?.article_flag_akku_groesser_100wh || false,

                articleSellOut:
                    sourceObj?.customAttributes?.article_sell_out || false,

                internalInfo: sourceObj?.customAttributes?.article_memo || null
            });

            if (sourceObj?.customAttributes?.article_weee_relevant) {
                const weeeOption = (weeeOptions.value || []).find(
                    (item: {id: string; value: string}) => {
                        return (
                            item.id ==
                            sourceObj?.customAttributes?.article_weee_relevant
                        );
                    }
                );

                if (weeeOption) {
                    Object.assign(state.value, {
                        articleWeee: weeeOption.value
                    });
                }
            }

            if (sourceObj?.customAttributes?.article_garantiezeit) {
                const warrantyTimeOption = (
                    warrantyTimeOptions.value || []
                ).find((item: {id: string; value: string}) => {
                    return (
                        item.id ==
                            sourceObj?.customAttributes?.article_garantiezeit ||
                        item.value ==
                            sourceObj?.customAttributes?.article_garantiezeit
                    );
                });

                if (warrantyTimeOption) {
                    Object.assign(state.value, {
                        articleWarrantyTime: warrantyTimeOption.value
                    });
                }
            }

            for (const key in state.value) {
                if (Object.keys(sourceObj).includes(key)) {
                    if (!['sellFromDate', 'uvpStartDate'].includes(key)) {
                        Object.assign(state.value, {[key]: sourceObj[key]});
                    } else if (
                        /^\d+$/.test(sourceObj[key]) &&
                        sourceObj[key] > 0
                    ) {
                        Object.assign(state.value, {
                            [key]: DateTime.fromMillis(
                                sourceObj[key]
                            ).toJSDate()
                        });
                    }
                }
            }
        };

        watch(
            () => props.customAttributesDefinition,
            (val) => {
                customAttributesDefinition.value = val;
            }
        );

        watch(
            () => props.hideBackButton,
            (val) => {
                hideBackButton.value = val;
            }
        );

        watch(
            () => props.duplicateCheckValues,
            (val) => {
                if (
                    typeof val === 'object' &&
                    val !== null &&
                    Object.values(val).length > 0
                ) {
                    duplicateCheckValues.value = Object.assign({}, val);
                }
            }
        );

        watch(
            () => props.pairValues,
            (val) => {
                if (
                    typeof val === 'object' &&
                    val !== null &&
                    Object.values(val).length > 0
                ) {
                    pairValues.value = val;
                    prePopulateForm(val);
                }
            }
        );

        const resolvedName = computed(() => {
            return (
                pairValues.value?.name ||
                duplicateCheckValues.value?.name ||
                null
            );
        });

        watch(resolvedName, (newValue) => {
            Object.assign(state.value, {
                name: newValue
            });
        });

        const resolvedEan = computed(() => {
            return (
                pairValues.value?.ean || duplicateCheckValues.value?.ean || null
            );
        });

        watch(resolvedEan, (newValue) => {
            Object.assign(state.value, {
                ean: newValue
            });
        });

        const resolvedMpn = computed(() => {
            return (
                pairValues.value?.manufacturerPartNumber ||
                duplicateCheckValues.value?.manufacturerPartNumber ||
                null
            );
        });

        watch(resolvedMpn, (newValue) => {
            Object.assign(state.value, {
                manufacturerPartNumber: newValue
            });
        });

        const state = ref({
            name: null,
            manufacturerPartNumber: null,
            currency: 'EUR',
            ean: null,
            unit: 'Stk.',
            taxRateType: 'STANDARD',
            sellFromDate: null,
            articleDangerousGoods: false,
            articleBulkGoods: false,
            articleOversized: false,
            articleBattg: false,
            productType: 'STORABLE',
            articleWeee: null,
            articleWarrantyTime: null,
            uvpPrice: null,
            uvpStartDate: new Date(),
            internalInfo: null,
            articleCreator: null,
            articleSellOut: false,
            productGroupId: null,
            tags: []
        });

        const rules = {
            name: {required},
            manufacturerPartNumber: {},
            currency: {required},
            ean: {},
            unit: {required},
            taxRateType: {required},
            sellFromDate: {},
            articleDangerousGoods: {},
            articleSellOut: {},
            articleBulkGoods: {},
            articleOversized: {},
            articleBattg: {},
            productType: {required},
            articleWeee: {},
            articleWarrantyTime: {},
            uvpPrice: {},
            uvpStartDate: {},
            internalInfo: {},
            articleCreator: {required},
            productGroupId: {},
            tags: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 1,
                formValues: state.value
            });
        };

        const parseIntoDropdownList = (data: any) => {
            return data.map((item: {id: string; name: string}) => {
                return {
                    label: item.name,
                    value: item.name
                };
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 1);
        };

        const tagsPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('tags-edit') !== -1;
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            setDropdownValue,
            onBackButtonClicked,
            unitOptions,
            currencyOptions,
            weeeOptions,
            warrantyTimeOptions,
            productTypeOptions: [
                {
                    value: 'STORABLE',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.storable'
                    )
                },
                {
                    value: 'BASIC',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.basic'
                    )
                },
                {
                    value: 'SERVICE',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.service'
                    )
                },
                {
                    value: 'SALES_BILL_OF_MATERIAL',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.salesBillOfMaterial'
                    )
                },
                {
                    value: 'SHIPPING_COST',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.shippingCost'
                    )
                }
            ],
            taxRateTypeOptions: [
                'STANDARD',
                'REDUCED',
                'ZERO',
                'SLIGHTLY_REDUCED',
                'SUPER_REDUCED'
            ].map((item: string) => {
                return {
                    value: item,
                    label: i18n.global.t(
                        'labels.productAdd.taxRateTypeOptions.' + item
                    )
                };
            }),
            locale: i18n.global.locale,
            hideBackButton,
            tagsPermissionAvailable,
            customAttributesDefinition
        };
    }
};
