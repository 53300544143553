<!-- Content Header (Page header) -->
<LoadingPlugin v-model:active="loading" />
<Panel
    ><template #header>
        <span class="text-lg text-900 font-bold"
            >{{$t('labels.shopOrders')}}</span
        >
        <div class="font-bold">
            Teltec
            <p-checkbox
                v-model="searchInTeltec"
                :disabled="!searchInVideodata"
                binary
                class="ml-2"
                @change="handlePlatformClick(false); loadData()"
            />
        </div>
        <div class="font-bold">
            Videodata
            <p-checkbox
                v-model="searchInVideodata"
                :disabled="!searchInTeltec"
                binary
                class="ml-2"
                @change="handlePlatformClick(true); loadData()"
            />
        </div>
    </template>
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="20"
        :paginator-template="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
        :rows-per-page-options="[10,20,50,100,200]"
        :current-page-report-template="'{first} to {last} of {totalRecords}'"
        editMode="cell"
        ref="dt"
        dataKey="id"
        :value="foundOrders"
        responsiveLayout="scroll"
        v-model:filters="filters"
        filterDisplay="row"
        @filter="onFilter"
        v-model:expandedRows="expandedRows"
    >
        <!--<Column
        field="nameNumberCombo"
        style="min-width: 9rem"
        :header="$t('labels.article')"
        sortable
        :filterMatchModeOptions="[{label: 'Contains', value: articleComboFilter}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                :placeholder="$t('labels.name')"
            />
        </template>
        <template #body="{data}">
            <div class="text-sm">
                <span
                    class="sales-channel-dot w-1rem h-1rem mr-1"
                    :class="{'sales-channel-teltec':data?.activeInShop, 'sales-channel-teltec-border':data?.availableInShop, 'bg-gray-200': !data?.availableInShop && !data?.activeInShop}"
                ></span>
                <span
                    class="sales-channel-dot w-1rem h-1rem mr-1"
                    :class="{'sales-channel-videodata':data?.activeInShop2, 'sales-channel-videodata-border':data?.availableInShop2, 'bg-gray-200': !data?.availableInShop2 && !data?.activeInShop2}"
                ></span>
                {{data.name}} [<a
                    :href="data?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                    target="_blank"
                    >{{ data.articleNumber }}</a
                >]
            </div>
            <div class="text-xs">
                <b
                    >{{$t('labels.createdAt')}}
                    {{getDateFormatted(data?.createdDate) }}<span
                        v-if="ekPriceCalculation(data)"
                        class="ml-3"
                        >EK {{ekPriceCalculation(data)}}</span
                    ></b
                >
            </div>
        </template>
    </Column>-->
        <Column expander headerStyle="width: 3em" />
        <Column field="number" :header="$t('labels.shopOrderNumber')" sortable>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="'#'"
                />
            </template>
            <template #body="{data}">
                <div class="flex">
                    <div
                        class="flex-initial flex align-items-center justify-content-center"
                    >
                        <span
                            v-if="data.missingWeclappOrder"
                            class="pi pi-exclamation-triangle mr-2"
                            v-tooltip.top="data.weclappError || 'Bestellung wurde in weclapp nicht reserviert'"
                        ></span>
                        <router-link
                            v-if="editPermissionAvailable"
                            :to="{
                                    name: 'ImportShopOrderDetails',
                                    params: {id: data.number},
                                    query: {platform: data.platform}
                                }"
                            >{{data.number}}</router-link
                        >
                        <i
                            v-if="editPermissionAvailable && data.missingWeclappOrder"
                            class="pi pi-wrench ml-2"
                            style="cursor: pointer"
                            @click="handleManualRecreation(data.number, data.platform)"
                        ></i>
                    </div>
                    <div
                        class="flex-initial flex align-items-center justify-content-center"
                    >
                        <span
                            v-if="data.availability"
                            class="availability-dot ml-3"
                            v-tooltip.left="getAvailabilityTooltipText(data.availability)"
                            :class="{'bg-red-600':'NOTHING_AVAILABLE' === data.availability, 'bg-yellow-600':'PARTIALLY_AVAILABLE' === data.availability, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availability}"
                        ></span>
                        <span
                            v-if="data.availabilityForAllWarehouses"
                            class="availability-dot ml-3"
                            :class="{'bg-red-600':'NOTHING_AVAILABLE' === data.availabilityForAllWarehouses, 'yellow-background':'PARTIALLY_AVAILABLE' === data.availabilityForAllWarehouses, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availabilityForAllWarehouses}"
                            v-tooltip.right="getAvailabilityTooltipText(data.availabilityForAllWarehouses, true)"
                        ></span>
                    </div>
                </div>
            </template>
        </Column>
        <Column field="customerSearchable" :header="$t('labels.customer')">
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                />
            </template>
            <template #body="{data}">
                <Skeleton v-if="!data?.billingAddress"></Skeleton>
                <span v-else>
                    {{data.billingAddress.salutation}}
                    {{data.billingAddress.firstName}}
                    {{data.billingAddress.lastName}}
                    <span v-if="data.billingAddress.company" class="font-bold"
                        >[{{data.billingAddress.company}}]</span
                    >
                </span>
            </template>
        </Column>
        <Column
            field="paymentMethodName"
            :header="$t('labels.shopOrderPaymentMethod')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                />
            </template>
            <template #body="{data}">
                <Skeleton v-if="!data?.paymentMethod"></Skeleton>
                <span v-else>
                    {{data.paymentMethod?.name}}
                    <div
                        v-if="data.paymentMethod?.name !== data.paymentMethod?.description"
                        class="mt-1"
                    ></div>
                </span>
            </template>
        </Column>
        <Column
            field="orderTimeParsed"
            :header="$t('labels.createdAt')"
            sortable
            dataType="date"
        >
            <template #filter="{filterModel,filterCallback}">
                <p-calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                    @date-select="filterCallback()"
                />
            </template>
            <template #body="{data}"> {{data.orderTimeFormatted}} </template>
        </Column>
        <Column
            field="partDelivery"
            :header="$t('labels.partialDelivery')"
            sortable
            dataType="boolean"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}"
                ><i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.partDelivery, 'text-red-600 pi-times-circle': !(data.partDelivery)}"
                ></i>
            </template>
        </Column>
        <Column
            field="amountTotal"
            :header="$t('labels.shopOrderValue')"
            sortable
        >
            <template #body="{data}">
                {{getCurrencyFormatted(data.amountTotal)}}
            </template>
        </Column>
        <template #expansion="slotProps">
            <DataTable
                v-if="slotProps.data?.orderLines"
                class="p-datatable-sm text-sm"
                stripedRows
                :rows="20"
                dataKey="shopInternalId"
                :value="slotProps.data?.orderLines"
                responsiveLayout="scroll"
            >
                <Column
                    field="articleNumber"
                    style="max-width: 3rem"
                    :header="$t('labels.articleNumber')"
                ></Column>
                <Column
                    ><template #body="{data}">
                        <span
                            v-if="data.availability"
                            class="availability-dot ml-3"
                            v-tooltip.left="getAvailabilityTooltipText(data.availability)"
                            :class="{'bg-red-600':'NOTHING_AVAILABLE' === data.availability, 'bg-yellow-600':'PARTIALLY_AVAILABLE' === data.availability, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availability}"
                        ></span>
                        <span
                            v-if="data.availabilityForAllWarehouses"
                            class="availability-dot ml-3"
                            :class="{'bg-red-600':'NOTHING_AVAILABLE' === data.availabilityForAllWarehouses, 'yellow-background':'PARTIALLY_AVAILABLE' === data.availabilityForAllWarehouses, 'bg-green-400':'COMPLETELY_AVAILABLE' === data.availabilityForAllWarehouses}"
                            v-tooltip.right="getAvailabilityTooltipText(data.availabilityForAllWarehouses, true)"
                        ></span> </template
                ></Column>
                <Column
                    field="articleName"
                    :header="$t('labels.articleName')"
                ></Column>
                <Column :header="$t('labels.shopOrderLinePrice')">
                    <template #body="{data}">
                        {{getCurrencyFormatted(data.unitPrice)}}
                    </template></Column
                >
                <Column
                    field="quantity"
                    :header="$t('labels.shopOrderLineQuantity')"
                ></Column>
                <Column :header="$t('labels.productAdd.taxRateType')"
                    ><template #body="{data}">
                        {{(data.taxRate)}} %
                    </template></Column
                >
                <Column :header="$t('labels.shopOrderLineTotalPrice')">
                    <template #body="{data}">
                        {{getCurrencyFormatted(data.totalPrice)}}
                    </template></Column
                >
            </DataTable>
            <div class="grid mt-5">
                <div class="col-8"></div>
                <div class="col">
                    <div class="flex justify-content-between">
                        <span>Summe</span
                        ><span
                            >{{getCurrencyFormatted(slotProps.data?.orderLines.reduce(
                            (accumulator, currentValue) => accumulator +
                            currentValue.totalPrice, 0 ))}}</span
                        >
                    </div>
                    <Divider></Divider>
                    <div class="flex justify-content-between">
                        <span>Versandkosten</span
                        ><span
                            >{{getCurrencyFormatted(slotProps.data?.shippingAmountNet)}}</span
                        >
                    </div>
                    <Divider></Divider>
                    <div class="flex justify-content-between">
                        <span class="font-bold">Gesamtsumme ohne MwSt.</span
                        ><span
                            >{{getCurrencyFormatted(slotProps.data?.orderLines.reduce(
                            (accumulator, currentValue) => accumulator +
                            currentValue.totalPrice, 0 ) +
                            slotProps.data?.shippingAmountNet)}}</span
                        >
                    </div>
                    <Divider></Divider>
                    <div class="flex justify-content-between">
                        <span>zzgl. 19% MwSt.</span
                        ><span
                            >{{getCurrencyFormatted( slotProps.data?.amountTotal
                            - slotProps.data?.orderLines.reduce( (accumulator,
                            currentValue) => accumulator +
                            currentValue.totalPrice, 0 ) -
                            slotProps.data?.shippingAmountNet)}}</span
                        >
                    </div>
                    <Divider></Divider>
                    <div class="flex justify-content-between">
                        <span class="font-bold">Gesamtsumme inkl. MwSt.</span
                        ><span
                            >{{getCurrencyFormatted(
                            slotProps.data?.amountTotal)}}</span
                        >
                    </div>
                </div>
                <div class="col-1"></div>
            </div>
        </template>
    </DataTable>
</Panel>
