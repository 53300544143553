import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center gap-2" }
const _hoisted_2 = { class: "layout-menuitem-text font-bold" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Column = _resolveComponent("Column")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.accessLog')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.accessLogs,
          rowGroupMode: "subheader",
          groupRowsBy: "representative.name",
          sortMode: "single",
          sortField: "representative.name",
          sortOrder: 1,
          scrollable: "",
          scrollHeight: "calc(100vh - 23rem)"
        }, {
          groupheader: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_font_awesome_icon, {
                class: "layout-menuitem-icon",
                icon: slotProps.data.representative.iconClass
              }, null, 8, ["icon"]),
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(slotProps.data.representative.label)), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, { field: "representative.name" }),
            _createVNode(_component_Column, { field: "date" }),
            _createVNode(_component_Column, {
              field: "hits",
              header: "Hits"
            })
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}