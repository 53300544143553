<!--<loading v-model:active="savingInProgress" />-->
<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <Panel :toggleable="toggleable" :collapsed="collapsed">
        <template #header>
            <div class="flex justify-content-between w-full">
                <div class="font-bold">
                    {{$t('labels.shipment.shippingCarrierAdditionalData',
                    {carrierName: (shippingCarrierType
                    ||'ups').toUpperCase()})}}
                </div>
            </div>
        </template>
        <div class="grid p-fluid mb-3" v-if="shippingCarrierType === 'ups'">
            <div class="col field">
                <label> {{$t('labels.shipment.upsDeliveryCosts')}} </label>
                <p-dropdown
                    v-model="v$.deliveryCosts.$model"
                    :options="[{label: 'Versender (Teltec)', value: 0}, {label: 'Kunde/Belegadresse (Dropshipment)', value: 1}, {label: 'Lieferadresse', value: 2}]"
                    optionLabel="label"
                    optionValue="value"
                    class="w-full"
                    :class="{'p-invalid':v$.deliveryCosts.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.deliveryCosts.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col field">
                <label>
                    {{$t('labels.shipment.upsCustomerAccountNumber')}}
                </label>
                <InputText
                    autocomplete="off"
                    v-model="v$.upsAccountNumber.$model"
                    :class="{'p-invalid':v$.upsAccountNumber.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.upsAccountNumber.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid mb-3">
            <div class="col field">
                <label> {{$t('labels.shipment.upsSignatureMandatory')}} </label>

                <p-checkbox
                    v-model="v$.upsSignature.$model"
                    binary
                    class="ml-2 mb-1"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.upsSignature.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col field" v-if="shippingCarrierType === 'ups'">
                <label> {{$t('labels.shipment.upsAccessPointId')}} </label>
                <InputText
                    icon="align-justify"
                    type="text"
                    autocomplete="off"
                    v-model="v$.upsAccessPointId.$model"
                    :class="{'p-invalid':v$.upsAccessPointId.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.upsAccessPointId.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid mb-3" v-if="shippingCarrierType === 'ups'">
            <div class="col field">
                <label>
                    {{$t('labels.shipment.upsShipmentDescription')}}
                </label>

                <InputText
                    autocomplete="off"
                    v-model="v$.upsShipmentDescription.$model"
                    :class="{'p-invalid':v$.upsShipmentDescription.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.upsShipmentDescription.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col field">
                <label> {{$t('labels.shipment.upsPackageDescription')}} </label>
                <InputText
                    icon="align-justify"
                    type="text"
                    autocomplete="off"
                    v-model="v$.upsPackageDescription.$model"
                    :class="{'p-invalid':v$.upsPackageDescription.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.upsPackageDescription.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </Panel>
</form>
