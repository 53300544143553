import {computed, ref, toRefs, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import Promotions from '@/pages/products/details/promotions/promotions.vue';
import {LoadingPlugin} from 'vue-loading-overlay';
import BulkProductAction from './bulk-product-action.vue';
import {bulkUpdatePromotions} from '@/services/products';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import Panel from 'primevue/panel';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Calendar from 'primevue/calendar';
import ToggleButton from 'primevue/togglebutton';
import {getAll} from '@/services/metadata';

export default {
    emits: ['close-dialog'],
    components: {
        TabPanel,
        Promotions,
        BulkProductAction,
        loading: LoadingPlugin,
        Panel,
        TriStateCheckbox,
        Calendar,
        ToggleButton
    },
    props: {
        products: Array,
        productFilters: Object,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isFilterRelated: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isFilterRelated = ref(false);
        const promotionsInput = ref(null);
        const bulkEditSwitches = ref(null);
        const loading = ref(false);
        const customAttributesDefinition: any = ref(null);

        const toast = useToast();

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isFilterRelated.value = args.isFilterRelated || false;
            if (showDialog.value) {
                getAll(['customAttributeDefinition'])
                    .then((cus: any) => {
                        if (
                            typeof cus.data?.customAttributeDefinition ===
                                'object' &&
                            Object.values(cus.data?.customAttributeDefinition)
                                .length > 0
                        ) {
                            Object.values(
                                cus.data?.customAttributeDefinition
                            ).forEach((cad: any) => {
                                customAttributesDefinition.value =
                                    Object.assign(
                                        customAttributesDefinition.value || {},
                                        {
                                            [cad.attributeKey]: {...cad}
                                        }
                                    );
                            });
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                        loading.value = false;
                    });
            }
        });

        const closeResponsive = (forceResfresh: boolean = false) => {
            promotionsInput.value = null;
            bulkEditSwitches.value = null;
            context.emit('close-dialog', forceResfresh);
        };

        const onFormDirty = (input: any, bulkEditSwitchesInput: any) => {
            promotionsInput.value = input;
            bulkEditSwitches.value = bulkEditSwitchesInput;
        };

        const filteredPromotionsInput = computed(() => {
            if (!promotionsInput.value) {
                return null;
            }

            return Object.fromEntries(
                Object.entries(promotionsInput.value).filter(
                    (item: Array<any>) => {
                        if (
                            [
                                'ekPromoSwitch',
                                'vkPromoSwitch',
                                'promoFlag'
                            ].includes(item[0])
                        ) {
                            return item[1] !== null;
                        }

                        return bulkEditSwitches.value[item[0]] === true;
                    }
                )
            );
        });

        const summaryTabVisible = computed(() => {
            return (
                filteredPromotionsInput.value &&
                Object.keys(filteredPromotionsInput.value).length > 0
            );
        });

        const confirmClicked = (startTime: string | null = null) => {
            loading.value = true;

            bulkUpdatePromotions(
                products.value,
                productFilters.value,
                filteredPromotionsInput.value,
                isFilterRelated.value,
                startTime
            )
                .then(() => {
                    toast.success(
                        i18n.global.t(
                            isFilterRelated.value || startTime
                                ? 'messages.cronJobExecutionScheduled'
                                : 'messages.changesSavedSuccessfully'
                        )
                    );
                    closeResponsive(true);
                    loading.value = false;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            showDialog,
            closeResponsive,
            onFormDirty,
            confirmClicked,
            summaryTabVisible,
            loading,
            filteredPromotionsInput,
            locale: i18n.global.locale,
            customAttributesDefinition
        };
    }
};
