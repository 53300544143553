<PrimeDialog
    :header="$t('labels.addArticleAccessories')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '85vw'}"
    @hide="closeResponsive(false)"
    :modal="true"
    position="top"
>
    <LoadingPlugin v-model:active="loading" />
    <TabView v-if="mainProductsSelected">
        <TabPanel :header="$t('labels.mainArticle')">
            <ProductList :products="products" :scrollableHeight="'55vh'">
                <template #product-platfrom-circle="foo">
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                    ></div>
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                    ></div>
                </template>
            </ProductList>
        </TabPanel>
        <TabPanel :header="$t('labels.articleAccessories')">
            <ProductListLazy
                @on-selection-change="onSelectionChange"
                selectionMode="multiple"
                :scrollableHeight="'55vh'"
            >
                <template #product-platfrom-circle="foo">
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                    ></div>
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                    ></div>
                </template>
            </ProductListLazy>
        </TabPanel>
        <TabPanel :header="$t('labels.summary')" :disabled="!showSummaryTab">
            <ScrollPanel style="height: 65vh" class="w-full">
                <Panel class="mb-3">
                    <template #header>
                        <div class="flex justify-content-between w-full">
                            <div class="font-bold">
                                {{$t('labels.articleAccessories')}}
                            </div>
                            <div class="field">
                                <label class="mr-2 font-semibold"
                                    >{{
                                    $t("labels.linkAccessoriesInBothDirections")
                                    }}</label
                                >
                                <p-checkbox
                                    v-model="linkAccessoriesInBothDirections"
                                    binary
                                >
                                </p-checkbox>
                            </div>
                        </div>
                    </template>
                    <ProductList :products="selectedProducts">
                        <template #product-platfrom-circle="foo">
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                            ></div>
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                            ></div>
                        </template>
                    </ProductList>
                </Panel>
                <h1 style="text-align: center">
                    <font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    /><font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    /><font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    />
                </h1>
                <Panel :header="$t('labels.mainArticle')">
                    <ProductList :products="products">
                        <template #product-platfrom-circle="foo">
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                            ></div>
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                            ></div>
                        </template>
                    </ProductList>
                </Panel>
            </ScrollPanel>
            <div class="w-full mt-3 flex justify-content-between">
                <p-button severity="danger" @click="closeResponsive(false)">
                    {{ $t("buttons.cancel") }}
                </p-button>
                <p-button @click="addArticleAccessories" severity="success">
                    {{ $t("buttons.confirm") }}
                </p-button>
            </div>
        </TabPanel>
    </TabView>
    <TabView v-else>
        <TabPanel :header="$t('labels.mainArticle')">
            <ProductListLazy
                @on-selection-change="onSelectionChange"
                selectionMode="multiple"
            >
                <template #product-platfrom-circle="foo">
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                    ></div>
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                    ></div>
                </template>
            </ProductListLazy>
        </TabPanel>
        <TabPanel :header="$t('labels.articleAccessories')">
            <ProductList :products="products">
                <template #product-platfrom-circle="foo">
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                    ></div>
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                    ></div>
                </template>
            </ProductList>
        </TabPanel>
        <TabPanel :header="$t('labels.summary')" :disabled="!showSummaryTab">
            <ScrollPanel style="height: 65vh" class="w-full">
                <Panel class="mb-3">
                    <template #header>
                        <div class="flex justify-content-between w-full">
                            <div class="font-bold">
                                {{$t('labels.articleAccessories')}}
                            </div>
                            <div class="field">
                                <label class="mr-2 font-semibold"
                                    >{{
                                    $t("labels.linkAccessoriesInBothDirections")
                                    }}</label
                                >
                                <p-checkbox
                                    v-model="linkAccessoriesInBothDirections"
                                    binary
                                >
                                </p-checkbox>
                            </div>
                        </div>
                    </template>
                    <ProductList :products="products"
                        ><template #product-platfrom-circle="foo">
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                            ></div>
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                            ></div> </template
                    ></ProductList>
                </Panel>
                <h1 style="text-align: center">
                    <font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    /><font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    /><font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    />
                </h1>
                <Panel :header="$t('labels.mainArticle')" class="mb-3">
                    <ProductList :products="selectedProducts"
                        ><template #product-platfrom-circle="foo">
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}"
                            ></div>
                            <div
                                class="sales-channel-dot w-1rem h-1rem mr-1"
                                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}"
                            ></div> </template
                    ></ProductList>
                </Panel>
            </ScrollPanel>
            <div class="w-full mt-3 flex justify-content-between">
                <p-button severity="danger" @click="closeResponsive(false)">
                    {{ $t("buttons.cancel") }}
                </p-button>
                <p-button @click="addArticleAccessories" severity="success">
                    {{ $t("buttons.confirm") }}
                </p-button>
            </div>
        </TabPanel>
    </TabView>
</PrimeDialog>
