<LoadingPlugin v-model:active="loading" />
<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid font-bold">
        <div class="col-4">{{$t('labels.article')}}</div>
        <div class="col-4">
            {{$t('labels.ticket.retoure.items.shipmentQuantity')}}
        </div>
        <div class="col-2">
            {{$t('labels.ticket.retoure.items.evaluationPrice')}}
        </div>
        <div class="col-2"></div>
    </div>
    <template v-for="(item, index) in state.retoureItems" :key="index">
        <div class="grid mt-3">
            <div class="col-4">
                <AutoComplete
                    v-model="item.articleNumber"
                    :placeholder="$t('labels.article')"
                    :suggestions="filteredProducts"
                    :class="{'p-invalid':v$.retoureItems.$each.$response.$data && v$.retoureItems.$each.$response.$data[index].articleNumber.$invalid}"
                    @complete="searchProducts($event)"
                    @item-select="dropdownSelect(index, $event)"
                    :dropdown="true"
                    optionLabel="label"
                    optionValue="value"
                    forceSelection
                    class="w-full"
                />
                <small
                    style="display: block"
                    v-if="v$.retoureItems.$each.$response.$errors"
                    v-for="error in v$.retoureItems.$each.$response.$errors[index].articleNumber"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
                <small
                    style="display: block"
                    v-if="v$.retoureItems.$each.$response.$errors"
                    v-for="error in v$.retoureItems.$each.$response.$errors[index].article"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
                <small class="font-bold"
                    >{{item.orderItem?.title || item.article?.name}}</small
                >
            </div>
            <div class="col-4">
                <InputNumber
                    :locale="locale"
                    :class="{'p-invalid':v$.retoureItems.$each.$response.$data && v$.retoureItems.$each.$response.$data[index].shipmentQuantity.$invalid}"
                    autocomplete="off"
                    v-model="item.shipmentQuantity"
                    :disabled="item.serialNumberRequired"
                    style="width: 85%"
                />
                Stk.
                <span
                    v-if="item.serialNumberRequired"
                    class="pi pi-plus-circle text-green-600"
                    @click="openAddSerialNumberDialog(index, item)"
                ></span>
                <ul
                    v-if="item.serialNumberRequired && v$.retoureItems.$each.$response.$errors && v$.retoureItems.$each.$response.$errors[index].shipmentQuantity.length < 1"
                >
                    <li
                        class="text-sm"
                        v-for="element in item.selectedSerialNumbers"
                    >
                        {{ element}}
                        <span
                            class="pi pi-times-circle mt-1 text-red-600"
                            @click="removeSerialNumberItem(index, element, $event)"
                        ></span>
                    </li>
                </ul>

                <small
                    style="display: block"
                    class="p-error"
                    v-if="v$.retoureItems.$each.$response.$errors"
                    v-for="error in v$.retoureItems.$each.$response.$errors[index].shipmentQuantity"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col">
                <InputNumber
                    mode="decimal"
                    :locale="locale"
                    :class="{'p-invalid':v$.retoureItems.$each.$response.$data && v$.retoureItems.$each.$response.$data[index].evaluationPrice.$invalid}"
                    :maxFractionDigits="2"
                    autocomplete="off"
                    v-model="item.evaluationPrice"
                    class="w-full"
                />
                <small
                    class="p-error"
                    v-if="v$.retoureItems.$each.$response.$errors"
                    v-for="error in v$.retoureItems.$each.$response.$errors[index].evaluationPrice"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col mt-2">
                <span
                    v-if="state.retoureItems.length - 1 <= index"
                    class="pi pi-plus mr-2 text-green-600"
                    style="cursor: pointer"
                    @click="addRetoureItem"
                ></span>
                <span
                    v-if="state.retoureItems.length > 1 || index > 0"
                    class="pi pi-trash text-red-600"
                    style="cursor: pointer"
                    @click="removeRetoureItem(index);"
                ></span>
            </div>
        </div>
        <div class="grid">
            <div class="col">
                <small style="display: block"
                    >{{stripTagsAndTruncate(item.orderItem?.description ||
                    item.article?.description)}}</small
                >
            </div>
        </div>
    </template>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button
            severity="success"
            v-text="$t('buttons.next')"
            :disabled="savingInProgress"
            type="submit"
        >
        </p-button>
    </div>
</form>

<AddSerialNumber
    :display-add-serial-number-dialog="showAddSerialNumberDialog"
    :row-index="clickedRowIndex"
    :warehouse-stock-movement-filters="addSerialNumberDialogFilters"
    :article-id="clickedRowArticleId"
    @close-dialog="closeDialogListener"
>
</AddSerialNumber>
