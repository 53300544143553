import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getMatchingPurchaseOrders = (
    incomingGoodsNumber: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/incoming-goods/' +
            incomingGoodsNumber +
            '/matching-purchase-orders'
    );
};

export const getSingle = (
    incomingGoodsNumber: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/incoming-goods/' + incomingGoodsNumber);
};

export const getAll = (input: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/incoming-goods', {
        params: {
            start: input.first || 0,
            end: (input.first || 0) + (input.rows || 10),
            sortField: input.sortField || '',
            sortOrder: input.sortOrder === 1 ? 'asc' : 'desc',
            filters: JSON.stringify(clearEmptyFilters(input.filters || {}))
        }
    });
};

export const bookIntenal = async (
    incomingGoodsNumber: string,
    items: Array<{
        id: string;
        serialNumbers: Array<string>;
        shipmentQuantity?: number;
    }>
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/incoming-goods/' + incomingGoodsNumber + '/book-internal',
        {items}
    );
};
