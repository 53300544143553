import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAll = (params: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/filters', {params});
};

export const add = async (
    productComponentId: string,
    payload: any,
    filters: any
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/filters', {
        ...payload,
        productComponentId,
        filters
    });
};

export const remove = async (itemId: string): Promise<AxiosResponse> => {
    return apiClient.delete('/api/v1/filters/' + itemId);
};
