import {computed, onMounted, ref} from 'vue';
import {getAll} from '@/services/products';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Column from 'primevue/column';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';
import Badge from 'primevue/badge';
import TieredMenu from 'primevue/tieredmenu';
import FilterMenu from '@/components/filter-menu/filter-menu.vue';
import BulkAddAccessories from '@/components/dialog/products/bulk-add-accessories.vue';
import BulkAddRemoveBadges from '@/components/dialog/products/bulk-add-remove-badges.vue';
import BulkAddRemoveDiscounts from '@/components/dialog/products/bulk-add-remove-discounts.vue';
import BulkAddWeclappDocuments from '@/components/dialog/products/bulk-add-weclapp-documents.vue';
import BulkAddShopwareDocuments from '@/components/dialog/products/bulk-add-shopware-documents.vue';
import BulkSetShopOnlineStatus from '@/components/dialog/products/bulk-set-shop-online-status.vue';
import BulkEditPromotions from '@/components/dialog/products/bulk-edit-promotions.vue';
import BulkEditMetatags from '@/components/dialog/products/bulk-edit-metatags.vue';
import BulkEditCompliance from '@/components/dialog/products/bulk-edit-compliance.vue';
import BulkEditTags from '@/components/dialog/products/bulk-edit-tags.vue';
import SalesChannelSwitcher from '@/components/sales-channel-switcher/sales-channel-switcher.vue';

import {i18n} from '@/utils/i18n';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import store from '@/store';
import {
    stripTagsAndTruncate,
    isValueEqualCaseInsensitive,
    getShopwareUrl,
    isShopware5
} from '@/utils/helpers';

export default {
    components: {
        DataTable,
        InputText,
        InputSwitch,
        Dropdown,
        'p-button': Button,
        'p-multiselect': MultiSelect,
        FilterMenu,
        Column,
        Calendar,
        Badge,
        BulkAddAccessories,
        loading: LoadingPlugin,
        BulkAddRemoveBadges,
        BulkAddRemoveDiscounts,
        PrimeDialog: Dialog,
        PrimePanel: Panel,
        TriStateCheckbox,
        BulkAddWeclappDocuments,
        BulkAddShopwareDocuments,
        BulkSetShopOnlineStatus,
        BulkEditPromotions,
        BulkEditMetatags,
        BulkEditCompliance,
        BulkEditTags,
        TieredMenu,
        Tag,
        SalesChannelSwitcher
    },
    setup() {
        onMounted(async () => {
            if (localStorage.getItem('products-webshop-view-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filtersDefinition);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                filters: filters.value
            };

            loadLazyData();
        });

        const dialogDefinitions: Array<{
            name: string;
            visible: boolean;
            usingFilter: boolean;
            mainProductSelected?: boolean;
            isRemove?: boolean;
        }> = [
            {
                name: 'Accessories',
                visible: false,
                usingFilter: false,
                mainProductSelected: false
            },
            {
                name: 'Badges',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Discounts',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'WeclappDocuments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'ShopwareDocuments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'OnlineStatus',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Promotions',
                visible: false,
                usingFilter: false
            },
            {
                name: 'Metatags',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Tags',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Compliance',
                visible: false,
                usingFilter: false
            }
        ];

        const dialogs = ref(dialogDefinitions);

        const closeDialog = () => {
            dialogs.value.forEach((item) => {
                item.visible = false;
            });
        };

        const openDialog = (dialogParameters: {
            dialogName: string;
            usingFilter?: boolean;
            mainProductSelected?: boolean;
            isRemove?: boolean;
        }) => {
            closeDialog();
            const chosenDialog = dialogs.value.find(
                (item) => item.name === dialogParameters.dialogName
            );
            chosenDialog.visible = true;
            chosenDialog.usingFilter = dialogParameters.usingFilter || false;
            if (typeof chosenDialog['mainProductSelected'] !== 'undefined') {
                chosenDialog.mainProductSelected =
                    dialogParameters.mainProductSelected || false;
            }

            if (typeof chosenDialog['isRemove'] !== 'undefined') {
                chosenDialog.isRemove = dialogParameters.isRemove || false;
            }
        };

        const tableState = ref(null);
        const totalRecords = ref(0);
        const loading = ref(false);
        const products = ref();
        const selectedProducts = ref([]);
        const mergedSelection = ref([]);
        const toast = useToast();

        const dt = ref();
        const lazyParams: any = ref({});
        const expandedRows = ref([]);

        const bulkMenu = ref();

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(filters.value, {});
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;

            lazyParams.value.filters = Object.assign(filters.value, {});

            loadLazyData();
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(
                tableState.value?.filters || {}
            ).filter((e) => e !== 'view');

            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            if (
                !stateFilters.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                localStorage.removeItem('products-webshop-view-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filtersDefinition);
            } else {
                delete event.filters?.view;
                filters.value = Object.assign(
                    {},
                    filtersDefinition,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event?.rows || 10,
                sortField: event?.sortField || 'articleNumber',
                sortOrder: event?.sortOrder || 1,
                filters: filters.value
            };

            loadLazyData();
        };

        const clearFilters = () => {
            filters.value = Object.assign({}, filtersDefinition);
            onFilter();
        };

        const applyFilters = (savedFilters: any) => {
            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            const filtered = Object.keys(savedFilters)
                .filter((key) => filtersDefinitionKeys.includes(key))
                .reduce((obj: any, key) => {
                    obj[key as keyof typeof obj] = savedFilters[key];
                    return obj;
                }, {});

            filters.value = Object.assign({}, filtersDefinition, {
                ...filtered
            });
            onFilter();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const loadLazyData = () => {
            loading.value = true;
            lazyParams.value.columns = ['articleNumber', 'name', 'ean'];
            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    products.value = data.data.items;
                    selectedProducts.value = mergedSelection.value.filter(
                        (item: any) => {
                            return products.value.some(
                                (product: any) =>
                                    product.articleNumber === item.articleNumber
                            );
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const filtersDefinition: any = {
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},

            ean: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            manufacturerPartNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },

            activeInShopware: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            shopImageExists: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filtersDefinition);

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdd'
                    );
                case 'ttl':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdl'
                    );
                case 'vdd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttd'
                    );
                default:
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttl'
                    );
            }
        });

        const addBulkItems = computed(() => {
            return [
                {
                    label: i18n.global.t('labels.articleAccessories'),
                    icon: 'pi pi-sitemap',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.useSelectedAsMainProduct'
                            ),
                            icon: 'pi pi-sitemap',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Accessories',
                                    mainProductSelected: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.useSelectedAsAccessory'
                            ),
                            icon: 'pi pi-link',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Accessories'
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.productHome.activeInShop'),
                    icon: 'pi pi-shopping-cart',
                    items: [
                        {
                            label:
                                i18n.global.t('labels.putSelectedOffline', {
                                    number: mergedSelection.value?.length || ''
                                }) + '...',
                            icon: 'pi pi-circle-fill text-red-600',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                openDialog({
                                    dialogName: 'OnlineStatus',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label:
                                i18n.global.t('labels.putSelectedOnline', {
                                    number: mergedSelection.value?.length || ''
                                }) + '...',
                            icon: 'pi pi-circle-fill text-green-600',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                openDialog({
                                    dialogName: 'OnlineStatus'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.putAllOffline', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-circle-fill text-red-600',
                            command: () => {
                                openDialog({
                                    dialogName: 'OnlineStatus',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.putAllOnline', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-circle-fill text-green-600',
                            command: () => {
                                openDialog({
                                    dialogName: 'OnlineStatus',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label:
                        i18n.global.t('labels.badges') +
                        ' & ' +
                        i18n.global.t('labels.categories'),
                    icon: 'pi pi-tag',
                    items: [
                        {
                            label: i18n.global.t('labels.addBadgesToSelected', {
                                number: mergedSelection.value?.length || ''
                            }),
                            icon: 'pi pi-tag',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }

                                openDialog({dialogName: 'Badges'});
                            }
                        },
                        {
                            label: i18n.global.t('labels.addBadgesToAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-tag',
                            command: () => {
                                openDialog({
                                    dialogName: 'Badges',
                                    usingFilter: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.removeBadgesFromSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-trash',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }

                                openDialog({
                                    dialogName: 'Badges',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.removeBadgesFromAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-trash',
                            command: () => {
                                openDialog({
                                    dialogName: 'Badges',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.discounts'),
                    icon: 'pi pi-euro',
                    disabled: isShopware5(
                        resolvedPlatform.value?.threeLetterId
                    ),
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addDiscountsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-sitemap',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Discounts'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.addDiscountsToAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-sitemap',
                            command: () => {
                                openDialog({
                                    dialogName: 'Discounts',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.weclappDocuments'),
                    icon: 'pi pi-file-pdf',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addWeclappDocumentsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                openDialog({dialogName: 'WeclappDocuments'});
                            }
                        },
                        {
                            label: i18n.global.t(
                                'labels.addWeclappDocumentsToAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            command: () => {
                                openDialog({
                                    dialogName: 'WeclappDocuments',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.shopwareDocuments'),
                    icon: 'pi pi-file-pdf',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addShopwareDocumentsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }

                                openDialog({dialogName: 'ShopwareDocuments'});
                            }
                        },
                        {
                            label: i18n.global.t(
                                'labels.addShopwareDocumentsToAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            command: () => {
                                openDialog({
                                    dialogName: 'ShopwareDocuments',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.promotions'),
                    icon: 'pi pi-percentage',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.editPromotionsOnSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-pencil',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({dialogName: 'Promotions'});
                            }
                        },

                        {
                            label: i18n.global.t('labels.editPromotionsOnAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-pencil',
                            command: () => {
                                openDialog({
                                    dialogName: 'Promotions',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.productHome.metaTagsSEO'),
                    icon: 'pi pi-tags',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addMetatagsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-tag',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.addMetatagsToAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-tag',
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags',
                                    usingFilter: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.removeMetatagsFromSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-trash',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t(
                                'labels.removeMetatagsFromAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-trash',
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },

                {
                    label: i18n.global.t('labels.compliance'),
                    icon: 'pi pi-check-square',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.editComplianceOnSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-pencil',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({dialogName: 'Compliance'});
                            }
                        },

                        {
                            label: i18n.global.t('labels.editComplianceOnAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-pencil',
                            command: () => {
                                openDialog({
                                    dialogName: 'Compliance',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.tags'),
                    icon: 'pi pi-tags',
                    disabled: !tagsPermissionAvailable.value,
                    items: [
                        {
                            label: i18n.global.t('labels.addTagsToSelected', {
                                number: mergedSelection.value?.length || ''
                            }),
                            class: 'font-normal',
                            icon: 'pi pi-tag',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.addTagsToAll', {
                                number: totalRecords.value
                            }),
                            class: 'font-normal',
                            icon: 'pi pi-tag',
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags',
                                    usingFilter: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.removeTagsFromSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            class: 'font-normal',
                            icon: 'pi pi-trash',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.removeTagsFromAll', {
                                number: totalRecords.value
                            }),
                            class: 'font-normal',
                            icon: 'pi pi-trash',
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                }
            ];
        });

        const closeAddUsingSelectedMainProducts = (
            forceResfresh: boolean = false
        ) => {
            closeDialog();
            if (forceResfresh) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const onCloseBulkBadgesCategoriesDialog = (
            forceResfresh: boolean = false
        ) => {
            closeDialog();
            if (forceResfresh) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const tagsPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('tags-edit') !== -1;
        });

        const showCommentsColumn = computed(() => {
            return (
                products.value &&
                products.value.some(
                    (item: any) => item.comments && item.comments.length > 0
                )
            );
        });

        const selectedProductsInShopware = computed(() => {
            return mergedSelection.value
                ? mergedSelection.value.filter((item: any) => item.shopwareData)
                : [];
        });

        const expandRow = (data: any) => {
            if (
                expandedRows.value?.length > 0 &&
                expandedRows.value.find((item: any) => {
                    return item.id === data.id;
                }) !== null
            ) {
                expandedRows.value = [];
            } else {
                expandedRows.value = products.value.filter(
                    (p: any) => p.id === data.id
                );
            }
        };

        const resolvedShopwareUrl = (
            shopwareUrl: string,
            shopwareData: {name: string; seoUrl: string | null},
            threeLetterId: string
        ) => {
            return (
                shopwareUrl +
                (shopwareData.seoUrl !== null
                    ? shopwareData.seoUrl
                    : getShopwareUrl(shopwareData.name, threeLetterId) +
                      (threeLetterId === 'vct' ? '' : '.html'))
            );
        };

        const historyFilters = computed(() => {
            return JSON.stringify(lazyParams.value);
        });

        const onRowSelect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowSelectAll = (selection: {
            data: Array<any>;
            originalEvent: any;
        }) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, ...selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowUnselect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = mergedSelection.value.filter(
                (item) => item.id !== selection.data.id
            );
        };

        const onRowUnselectAll = () => {
            mergedSelection.value = mergedSelection.value.filter((item) => {
                return !products.value.some((pr: any) => item.id === pr.id);
            });
        };

        const clearSelection = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
        };

        const bulkMenuToggle = (event: any) => {
            bulkMenu.value.toggle(event);
        };

        return {
            loading,
            loadLazyData,
            totalRecords,
            products,
            filters,
            selectedProducts,
            mergedSelection,
            selectedProductsInShopware,
            addBulkItems,
            expandedRows,
            dt,
            matchModesNumeric,
            matchModeWithEmptyFilter: [
                {label: 'Starts with', value: FilterMatchMode.STARTS_WITH},
                {label: 'Contains', value: FilterMatchMode.CONTAINS},
                {label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS},
                {
                    label: 'Ends with',
                    value: FilterMatchMode.ENDS_WITH
                },
                {label: 'Equals', value: FilterMatchMode.EQUALS},
                {
                    label: 'Not equals',
                    value: FilterMatchMode.NOT_EQUALS
                },
                {
                    label: 'Ends with',
                    value: FilterMatchMode.ENDS_WITH
                },
                {
                    label: 'Is Empty',
                    value: 'isEmpty'
                },
                {
                    label: 'Is Not empty',
                    value: 'isNotEmpty'
                }
            ],
            onFilter,
            onPage,
            onSort,
            onStateRestore,
            closeAddUsingSelectedMainProducts,
            expandRow,
            isEmptyKeydownCalldown: (model: any, callb: any) => {
                if (model.value.trim() === '=') {
                    model.matchMode = 'isEmpty';
                } else if (model.value.trim() === '*') {
                    model.matchMode = 'isNotEmpty';
                }
                callb();
            },
            editPermissionAvailable,
            stripTagsAndTruncate,
            onCloseBulkBadgesCategoriesDialog,
            closeDialog,
            showCommentsColumn,
            resolvedShopwareUrl,
            isValueEqualCaseInsensitive,
            historyFilters,
            applyFilters,
            clearFilters,
            onRowSelect,
            onRowUnselect,
            onRowSelectAll,
            onRowUnselectAll,
            clearSelection,
            dialogs,
            bulkMenu,
            bulkMenuToggle,
            resolvedPlatform,
            secondPlatform
        };
    }
};
