import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Weclapp from './api/weclapp/weclapp.vue';
import TicketTemplatesList from './ticket-templates/list.vue';
import Panel from 'primevue/panel';
import {computed} from 'vue';
import store from '@/store';

export default {
    components: {
        TabPanel,
        TabView,
        Weclapp,
        TicketTemplatesList,
        Panel
    },
    setup() {
        const ticketTabVisible = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions &&
                user.permissions.indexOf('weclapp-tickets-edit') !== -1
            );
        });

        return {ticketTabVisible};
    }
};
