<loading v-model:active="savingInProgress" />
<Panel>
    <template #header>
        <div class="flex justify-content-between w-full">
            <div class="font-bold">Weclapp</div>
            <div v-if="selectedWeclappCustomer">
                <i
                    v-if="!isEdit"
                    class="pi pi-pencil"
                    @click="isEdit = true;"
                    style="cursor: pointer"
                ></i>
                <div v-else>
                    <i
                        class="pi pi-check text-green-600"
                        style="cursor: pointer"
                        @click="handleSubmit(!v$.$invalid)"
                    ></i>
                    <i
                        class="pi pi-times-circle text-red-600 ml-3"
                        style="cursor: pointer"
                        @click="resetForm"
                    ></i>
                </div>
            </div>
        </div>
    </template>
    <div
        class="text-center w-full"
        v-if="!selectedWeclappCustomer && (potentialMatches || []).length > 0"
    >
        {{$t('messages.pleaseSelectOneOfTheCustomers')}}
    </div>
    <div v-if="(potentialMatches || []).length < 1" class="text-center w-full">
        {{ $t('messages.noWeclappCustomerCouldBeFound') }}
    </div>

    <template v-if="selectedWeclappCustomer">
        <div class="grid mb-3">
            <div class="col">{{$t('labels.customerNumber')}}</div>
            <div class="col">
                <template v-if="shopData?.customer?.customerNumber">
                    <div v-if="!resolvedWeclappCustomerNumber">
                        <small
                            >(wird bei Auftragserstellung aktualisiert)</small
                        >
                    </div>
                    <div
                        v-else
                        :class="{'text-green-400' : resolvedWeclappCustomerNumber.includes(shopData?.customer?.customerNumber)}"
                    >
                        {{resolvedWeclappCustomerNumber}}
                        <small
                            v-if="!resolvedWeclappCustomerNumber.includes(shopData?.customer?.customerNumber)"
                            >(wird bei Auftragserstellung erweitert)</small
                        >
                    </div>
                </template>
            </div>
        </div>
        <div class="grid" :class="{'mb-3': !isEdit}">
            <div class="col">{{$t('labels.email')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.email && !selectedWeclappCustomer?.email"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.email, selectedWeclappCustomer?.email)"
                >
                    {{selectedWeclappCustomer?.email}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.email.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.email.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.email, v$.email.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.email.$model = shopwareFormBaseData?.email"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.email.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid" :class="{'mb-3': !isEdit}">
            <div class="col">USt-ID</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.vatNumber && !selectedWeclappCustomer?.taxId && !selectedWeclappCustomer?.vatIdentificationNumber"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.vatNumber, selectedWeclappCustomer?.taxId || selectedWeclappCustomer?.vatIdentificationNumber)"
                >
                    {{selectedWeclappCustomer?.taxId ||
                    selectedWeclappCustomer?.vatIdentificationNumber}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.taxId.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.taxId.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.vatNumber, v$.taxId.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.taxId.$model = shopwareFormBaseData?.vatNumber"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.taxId.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>

        <div class="grid mt-3 mb-3">
            <div class="col font-bold">{{$t('labels.address')}}</div>
            <div class="col">
                <p-dropdown
                    class="w-full"
                    @change="resetForm"
                    v-model="selectedAddressId"
                    optionValue="id"
                    :options="selectedWeclappCustomer?.addresses"
                    v-if="!isEdit && (selectedWeclappCustomer?.addresses || []).length > 1"
                >
                    <template #value="slotProps">
                        <div
                            v-if="slotProps.value"
                            class="flex align-items-center"
                        >
                            <div>
                                {{ selectedWeclappAddress?.street1 }}<span
                                    v-if="selectedWeclappAddress?.street2"
                                >
                                    {{ selectedWeclappAddress?.street2 }}</span
                                >, {{selectedWeclappAddress?.zipcode}},
                                {{selectedWeclappAddress?.city}},
                                {{resolveCountryFromCode(selectedWeclappAddress?.countryCode)}}
                            </div>
                            <i
                                v-if="selectedWeclappAddress?.primeAddress"
                                class="pi pi-home ml-2"
                            ></i>
                            <i
                                v-if="selectedWeclappAddress?.invoiceAddress"
                                class="pi pi-calculator ml-2"
                            ></i>
                            <i
                                v-if="selectedWeclappAddress?.deliveryAddress"
                                class="pi pi-truck ml-2"
                            ></i>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <div>
                                {{ slotProps.option.street1 }}<span
                                    v-if="slotProps.option.street2"
                                >
                                    {{ slotProps.option.street2 }}</span
                                >, {{slotProps.option.zipcode}},
                                {{slotProps.option.city}},
                                {{resolveCountryFromCode(slotProps.option.countryCode)}}
                            </div>
                            <i
                                v-if="slotProps.option.primeAddress"
                                class="pi pi-home ml-2"
                            ></i>
                            <i
                                v-if="slotProps.option.invoiceAddress"
                                class="pi pi-calculator ml-2"
                            ></i>
                            <i
                                v-if="slotProps.option.deliveryAddress"
                                class="pi pi-truck ml-2"
                            ></i>
                        </div>
                    </template>
                </p-dropdown>
                <div v-else-if="selectedWeclappAddress">
                    <i
                        v-if="selectedWeclappAddress?.primeAddress"
                        class="pi pi-home ml-2"
                    ></i>
                    <i
                        v-if="selectedWeclappAddress?.invoiceAddress"
                        class="pi pi-calculator ml-2"
                    ></i>
                    <i
                        v-if="selectedWeclappAddress?.deliveryAddress"
                        class="pi pi-truck ml-2"
                    ></i>
                </div>
            </div>
        </div>
        <div
            class="grid"
            :class="{'mb-1': !isEdit, 'mt-5': isEdit && !shopData?.hasSameShippingAddress && shopData?.shippingAddress}"
        >
            <div class="col">{{$t('labels.company')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.company && !selectedWeclappCustomer?.company && !selectedWeclappAddress?.company"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.company, selectedWeclappAddress?.company || selectedWeclappCustomer?.company)"
                >
                    {{selectedWeclappAddress?.company ||
                    selectedWeclappCustomer?.company}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.company.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.company.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.company, v$.company.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.company.$model = shopwareFormBaseData?.company"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.company.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid" :class="{'mb-1': !isEdit}">
            <div class="col">{{$t('labels.company2')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.department && ((selectedWeclappAddress?.company && !selectedWeclappAddress?.company2) || (!selectedWeclappAddress?.company && !selectedWeclappCustomer?.company2))"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.department, selectedWeclappAddress?.company ?
                    selectedWeclappAddress?.company2 :
                    selectedWeclappCustomer?.company2)"
                >
                    {{selectedWeclappAddress?.company ?
                    selectedWeclappAddress?.company2 :
                    selectedWeclappCustomer?.company2}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.department.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.department.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.department, v$.department.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.department.$model = shopwareFormBaseData?.department"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.department.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid" :class="{'mb-1': !isEdit}">
            <div class="col">{{$t('labels.street')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.street && !selectedWeclappAddress?.street1"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.street, selectedWeclappAddress?.street1)"
                >
                    {{selectedWeclappAddress?.street1}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.street.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.street.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.street, v$.street.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.street.$model = shopwareFormBaseData?.street"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.street.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div
            class="grid"
            :class="{'mb-1': !isEdit}"
            v-if="isEdit || (shopwareFormBaseData?.street2 || selectedWeclappAddress?.street2)"
        >
            <div class="col">{{$t('labels.street2')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.street2 && !selectedWeclappAddress?.street2"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.street2, selectedWeclappAddress?.street2)"
                >
                    {{selectedWeclappAddress?.street2}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.street2.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.street2.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.street2, v$.street2.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.street2.$model = shopwareFormBaseData?.street2"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.street2.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid mb-1" :class="{'mb-1': !isEdit}">
            <div class="col">{{$t('labels.zipCode')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.zipcode && !selectedWeclappAddress?.zipcode"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.zipcode, selectedWeclappAddress?.zipcode)"
                >
                    {{selectedWeclappAddress?.zipcode}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.zipcode.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.zipcode.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.zipcode, v$.zipcode.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.zipcode.$model = shopwareFormBaseData?.zipcode"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.zipcode.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid" :class="{'mb-1': !isEdit}">
            <div class="col">{{$t('labels.city')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.city && !selectedWeclappAddress?.city"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.city, selectedWeclappAddress?.city)"
                >
                    {{selectedWeclappAddress?.city}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.city.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.city.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.city, v$.city.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.city.$model = shopwareFormBaseData?.city"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.city.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid mb-3">
            <div class="col">{{$t('labels.country')}}</div>
            <div
                v-if="shopwareFormBaseData?.country && !selectedWeclappAddress?.countryCode"
                class="col text-red-400"
            >
                <small>{{$t('labels.empty')}}</small>
            </div>
            <div
                v-else
                class="col"
                :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.country, selectedWeclappAddress?.countryCode)"
            >
                {{resolveCountryFromCode(selectedWeclappAddress?.countryCode)}}
            </div>
        </div>
        <div class="grid" :class="{'mb-5': !isEdit}">
            <div class="col">{{$t('labels.phoneNumber')}}</div>
            <div v-if="!isEdit" class="col">
                <div
                    v-if="shopwareFormBaseData?.phoneNumber && !selectedWeclappCustomer?.phone"
                    class="text-red-400"
                >
                    <small>{{$t('labels.empty')}}</small>
                </div>
                <div
                    v-else
                    :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.phoneNumber, selectedWeclappCustomer?.phone)"
                >
                    {{selectedWeclappCustomer?.phone}}
                </div>
            </div>
            <div v-else class="col">
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.phoneNumber.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.phoneNumber.$invalid && submitted}"
                />
                <i
                    v-if="showCopyFieldIcon(shopwareFormBaseData?.phoneNumber, v$.phoneNumber.$model)"
                    class="pi pi-wrench ml-3 text-xl"
                    style="cursor: pointer"
                    @click="v$.phoneNumber.$model = shopwareFormBaseData?.phoneNumber"
                ></i>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.phoneNumber.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid mt-5 mb-3">
            <div class="col font-bold">{{$t('labels.contactPersonWcp')}}</div>
            <div class="col">
                <p-dropdown
                    class="w-full"
                    v-model="selectedContactId"
                    optionValue="id"
                    :options="selectedWeclappCustomer?.contacts"
                    v-if="!isEdit && (selectedWeclappCustomer?.contacts || []).length > 1"
                >
                    <template #value="slotProps">
                        <div
                            v-if="slotProps.value"
                            class="flex align-items-center"
                        >
                            <div>
                                {{formatWeclappSalutation(selectedContact?.salutation)}}
                                {{ selectedContact?.firstName }} {{
                                selectedContact?.lastName }}
                            </div>
                        </div>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <div>
                                {{formatWeclappSalutation(slotProps.option.salutation)}}
                                {{ slotProps.option.firstName }} {{
                                slotProps.option.lastName }}
                            </div>
                        </div>
                    </template>
                </p-dropdown>
            </div>
        </div>
        <template v-if="selectedContact">
            <div class="grid" :class="{'mb-1': !isEdit}">
                <div class="col">{{$t('labels.salutation')}}</div>
                <div v-if="!isEdit" class="col">
                    <div
                        v-if="shopwareFormBaseData?.salutation && !selectedContact?.salutation"
                        class="text-red-400"
                    >
                        <small>{{$t('labels.empty')}}</small>
                    </div>
                    <div
                        v-else
                        :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.salutation, formatWeclappSalutation(selectedContact?.salutation))"
                    >
                        {{formatWeclappSalutation(selectedContact?.salutation)}}
                    </div>
                </div>
                <div v-else class="col">
                    <p-dropdown
                        class="w-7"
                        v-model="v$.salutation.$model"
                        optionLabel="label"
                        optionValue="value"
                        :options="[{label: 'Herr', value: 'MR'}, {label: 'Frau', value: 'MRS'}, {label: 'Familie', value: 'FAMILY'}, {label: 'Firma', value: 'COMPANY'}, {label: 'Keine Anrede', value: 'NO_SALUTATION'}]"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.salutation.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>

            <div class="grid" :class="{'mb-1': !isEdit}">
                <div class="col">{{$t('labels.firstName')}}</div>
                <div v-if="!isEdit" class="col">
                    <div
                        v-if="shopwareFormBaseData?.firstName && !selectedContact?.firstName"
                        class="text-red-400"
                    >
                        <small>{{$t('labels.empty')}}</small>
                    </div>
                    <div
                        v-else
                        :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.firstName, selectedContact?.firstName)"
                    >
                        {{selectedContact?.firstName}}
                    </div>
                </div>
                <div v-else class="col">
                    <InputText
                        autocomplete="off"
                        v-model.trim="v$.firstName.$model"
                        class="text-sm"
                        :class="{'p-invalid':v$.firstName.$invalid && submitted}"
                    />
                    <i
                        v-if="showCopyFieldIcon(shopwareFormBaseData?.firstName, v$.firstName.$model)"
                        class="pi pi-wrench ml-3 text-xl"
                        style="cursor: pointer"
                        @click="v$.firstName.$model = shopwareFormBaseData?.firstName"
                    ></i>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.firstName.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="grid" :class="{'mb-1': !isEdit}">
                <div class="col">{{$t('labels.lastName')}}</div>
                <div v-if="!isEdit" class="col">
                    <div
                        v-if="shopwareFormBaseData?.lastName && !selectedContact?.lastName"
                        class="text-red-400"
                    >
                        <small>{{$t('labels.empty')}}</small>
                    </div>
                    <div
                        v-else
                        :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.lastName, selectedContact?.lastName)"
                    >
                        {{selectedContact?.lastName}}
                    </div>
                </div>
                <div v-else class="col">
                    <InputText
                        autocomplete="off"
                        v-model.trim="v$.lastName.$model"
                        class="text-sm"
                        :class="{'p-invalid':v$.lastName.$invalid && submitted}"
                    />
                    <i
                        v-if="showCopyFieldIcon(shopwareFormBaseData?.lastName, v$.lastName.$model)"
                        class="pi pi-wrench ml-3 text-xl"
                        style="cursor: pointer"
                        @click="v$.lastName.$model = shopwareFormBaseData?.lastName"
                    ></i>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.lastName.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="grid" :class="{'mb-1': !isEdit}">
                <div class="col">{{$t('labels.email')}}</div>
                <div v-if="!isEdit" class="col">
                    <div
                        v-if="shopwareFormBaseData?.email && !selectedContact?.email"
                        class="text-red-400"
                    >
                        <small>{{$t('labels.empty')}}</small>
                    </div>
                    <div
                        v-else
                        :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.email, selectedContact?.email)"
                    >
                        {{selectedContact?.email}}
                    </div>
                </div>
                <div v-else class="col">
                    <InputText
                        autocomplete="off"
                        v-model.trim="v$.contactEmail.$model"
                        class="text-sm"
                        :class="{'p-invalid':v$.contactEmail.$invalid && submitted}"
                    />
                    <i
                        v-if="showCopyFieldIcon(shopwareFormBaseData?.email, v$.contactEmail.$model)"
                        class="pi pi-wrench ml-3 text-xl"
                        style="cursor: pointer"
                        @click="v$.contactEmail.$model = shopwareFormBaseData?.email"
                    ></i>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.contactEmail.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="grid" :class="{'mb-1': !isEdit}">
                <div class="col">{{$t('labels.phoneNumber')}}</div>
                <div v-if="!isEdit" class="col">
                    <div
                        v-if="shopwareFormBaseData?.phoneNumber && !selectedContact?.phone"
                        class="text-red-400"
                    >
                        <small>{{$t('labels.empty')}}</small>
                    </div>
                    <div
                        v-else
                        :class="resolveBackgroundColorForMatching(shopwareFormBaseData?.phoneNumber, selectedContact?.phone)"
                    >
                        {{selectedContact?.phone}}
                    </div>
                </div>
                <div v-else class="col">
                    <InputText
                        autocomplete="off"
                        v-model.trim="v$.contactPhoneNumber.$model"
                        class="text-sm"
                        :class="{'p-invalid':v$.contactPhoneNumber.$invalid && submitted}"
                    />
                    <i
                        v-if="showCopyFieldIcon(shopwareFormBaseData?.phoneNumber, v$.contactPhoneNumber.$model)"
                        class="pi pi-wrench ml-3 text-xl"
                        style="cursor: pointer"
                        @click="v$.contactPhoneNumber.$model = shopwareFormBaseData?.phoneNumber"
                    ></i>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.contactPhoneNumber.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </template>
        <template
            v-if="!isEdit && (selectedWeclappCustomer?.partyEmailAddresses || []).length > 0"
        >
            <div class="grid mt-5 mb-3">
                <div class="col font-bold">{{$t('labels.emailAddresses')}}</div>
                <div class="col">
                    <div
                        v-for="em in selectedWeclappCustomer?.partyEmailAddresses"
                    >
                        {{em}}
                    </div>
                </div>
            </div>
        </template>
        <div
            class="flex justify-content-end w-full"
            v-if="selectedWeclappCustomer && isEdit"
        >
            <div>
                <i
                    class="pi pi-check text-green-600"
                    style="cursor: pointer"
                    @click="handleSubmit(!v$.$invalid)"
                ></i>
                <i
                    class="pi pi-times-circle text-red-600 ml-3"
                    style="cursor: pointer"
                    @click="resetForm"
                ></i>
            </div>
        </div>
    </template>
</Panel>
