import {ref, watch} from 'vue';
import Button from 'primevue/button';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import SelectButton from 'primevue/selectbutton';
import {helpers, required} from '@vuelidate/validators';
import {sendUpsLabelToCustomer} from '@/services/tickets';

export default {
    emits: ['reload-ticket-details'],
    components: {
        'p-button': Button,
        SelectButton
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const ticketDetails = ref(props.ticketDetails);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = ref({
            language: null,
            office: null
        });

        const rules = {
            language: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            office: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(
            () => props.ticketDetails,
            (newValue) => {
                if (newValue) {
                    ticketDetails.value = newValue;
                }
            }
        );

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            sendUpsLabelToCustomer(
                ticketDetails.value?.ticketNumber,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-ticket-details');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            locale: i18n.global.locale,
            ticketDetails
        };
    }
};
