import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {})),
            filterConjunction: input.filterConjunction || 'and'
        }
    );

    return apiClient.get('/api/v1/purchase-orders', {params});
};

export const getSingle = (
    purchaseOrderNumber: string | string[],
    withStatistics: boolean = true
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/purchase-orders/' +
            purchaseOrderNumber +
            '?withStatistics=' +
            withStatistics
    );
};

export const update = async (
    purchaseOrderNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/purchase-orders/' + purchaseOrderNumber,
        payload
    );
};

export const removeItem = async (
    purchaseOrderNumber: string,
    itemId: string
): Promise<AxiosResponse> => {
    return apiClient.delete(
        '/api/v1/purchase-orders/' + purchaseOrderNumber + '/items/' + itemId
    );
};
