import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const updateSupplySource = (
    supplySourceId: string | string[],
    payload: any,
    platform: string | null = null
): Promise<AxiosResponse> => {
    if (platform) {
        return apiClient.put(
            '/api/v1/supply-sources/' + supplySourceId,
            payload,
            {
                params: {platform}
            }
        );
    }
    return apiClient.put('/api/v1/supply-sources/' + supplySourceId, payload);
};
