<loading v-model:active="loading" />
<Panel :header="$t('labels.tickets')">
    <div class="flex justify-content-end">
        <span class="mr-3">{{ $t("labels.allEnvironments") }}</span>
        <div>
            <InputSwitch v-model="allEnvs" @input="onAllEnvsSwitch" />
        </div>
    </div>
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="20"
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        lazy
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="tickets"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @state-restore="onStateRestore"
        v-model:expandedRows="expandedRows"
        stateStorage="local"
        stateKey="tickets-list-state-session"
    >
        <Column
            :expander="true"
            style="min-width: 4rem"
            class="tt-filter-menu-th"
            ><template #filter>
                <FilterMenu
                    parent-component-id="tickets-list"
                    :parent-filters="filters"
                    @clear-filters="clearFilters"
                    @apply-filters="applyFilters"
                /> </template
        ></Column>
        <Column
            field="ticketNumber"
            :sortable="!allEnvs"
            style="min-width: 9rem"
            :header="$t('labels.ticketNumber')"
            :filterMatchModeOptions="matchModesNumeric"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    placeholder="#"
                />
            </template>
            <template #body="{data}">
                <a
                    :href="data.url + 'webapp/view/ticketing/ticketDetail.page?entityId=' + data.id"
                    target="_blank"
                >
                    {{data.ticketNumber}}
                </a>
            </template>
        </Column>
        <Column
            v-if="allEnvs"
            field="platform"
            :header="$t('labels.environment')"
            style="min-width: 8rem"
            ><template #body="{data}">
                <div
                    v-html="getPlatformCellContent(data.platform)"
                ></div> </template
        ></Column>
        <Column style="min-width: 4rem">
            <template #body="{data}">
                <div
                    v-if="data.comments.length > 0"
                    style="cursor: pointer"
                    @click="showCommentsDialog(data)"
                >
                    <i class="pi pi-comments" style="padding-right: 5px"></i>
                    {{ data.comments.length }}
                </div>
            </template>
        </Column>
        <Column
            field="createdDate"
            dataType="date"
            :sortable="true"
            :header="$t('labels.createdAt')"
            style="min-width: 10rem"
        >
            <template #filter="{filterModel}">
                <Calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                />
            </template>
            <template #body="data">
                {{ data.data.createdDateFormatted }}
            </template>
        </Column>
        <Column
            field="subject"
            :header="$t('labels.subject')"
            :sortable="!allEnvs"
            style="min-width: 12rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.subject')"
                />
            </template>
            <template #body="{data}">
                <a
                    :href="data.url + 'webapp/view/ticketing/ticketDetail.page?entityId=' + data.id"
                    target="_blank"
                >
                    {{data.subject}}
                </a>
            </template>
        </Column>

        <Column
            :header="$t('labels.status')"
            style="min-width: 10rem"
            field="ticketStatus"
            :sortable="!allEnvs"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #body="{data}">
                <span :class="getStatusCellStyle(data.ticketStatus)"
                    >{{data.ticketStatus}}</span
                ></template
            ><template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="['Noch nicht zugewiesen', 'Bestätigt', 'Gerät in interner Reparatur', 'Zugewiesen', 'In Bearbeitung', 'Wartend', 'Warten auf Kundenreaktion', 'Gelöst', 'Kunde wartet auf Reaktion', 'Keine Lösung', 'Kunde wartet auf Gutschrift', 'Geschlossen', 'Warten auf Einsendung']"
                    placeholder="Any"
                    @change="filterCallback()"
                    class="p-column-filter text-sm"
                    showClear
                >
                </Dropdown> </template
        ></Column>
        <Column
            field="assignedUser"
            style="min-width: 10rem"
            :header="$t('labels.assignedUser')"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
            ><template #filter="{filterModel,filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="userOptions"
                    placeholder="Any"
                    optionLabel="name"
                    optionValue="id"
                    class="p-column-filter text-sm"
                    :showClear="false"
                    @change="filterCallback()"
                    :filter="true"
                >
                </Dropdown> </template
        ></Column>
        <Column
            field="customer"
            :header="$t('labels.customer')"
            style="min-width: 10rem"
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.customer')"
                />
            </template>
            <template #body="{data}">
                <div v-if="data.customer?.company">
                    {{data.customer.company}}
                </div>
                <div v-else>
                    {{data.customer?.firstName}} {{data.customer?.lastName}}
                </div>
            </template>
        </Column>
        <Column
            style="min-width: 10rem"
            field="email"
            :header="$t('labels.email')"
            :sortable="!allEnvs"
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.email')"
                /> </template
        ></Column>
        <Column
            :style="'min-width: 100px; max-width: fit-content'"
            :frozen="true"
            alignFrozen="right"
        >
            <template #body="{data}">
                <router-link
                    :to="{
                                    name: 'TicketDetails',
                                    params: {id: data.ticketNumber}
                                }"
                >
                    <i
                        class="pi pi-search-plus text-color"
                        v-tooltip.left="$t('labels.ticketDetails')"
                    ></i>
                </router-link>
            </template>
        </Column>
        <template #expansion="slotProps">
            <div
                v-if="slotProps.data.description"
                v-html="slotProps.data.description"
            ></div>
            <div v-else>No description available.</div>
        </template>
    </DataTable>
</Panel>
<PrimeDialog
    v-model:visible="commentsDialogVisible"
    :header="$t('labels.ticketNumber') + (comments?.ticketNumber || '')"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="hideCommentsDialog"
    :modal="true"
    :dismissableMask="true"
>
    <Panel v-for="comment in (comments?.comments || [])" class="small mb-3">
        <template #header>
            <div class="font-bold">
                {{ comment.author }} at {{ comment.createdDate }}
            </div>
        </template>
        <div v-html="comment.htmlComment || comment.comment" />
    </Panel>
</PrimeDialog>
