<loading v-model:active="loading" />
<Panel :header="$t('labels.users')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="20"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        ref="dt"
        dataKey="username"
        :totalRecords="totalRecords"
        :value="users"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @state-restore="onStateRestore"
        stateStorage="local"
        stateKey="user-list-state-session"
    >
        <Column
            field="displayName"
            :sortable="true"
            style="max-width: fit-content"
            :header="$t('labels.user.displayName')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.user.displayName')"
                />
            </template>
        </Column>
        <Column
            field="username"
            :sortable="true"
            style="max-width: fit-content"
            :header="$t('labels.email')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                    :placeholder="$t('labels.email')"
                />
            </template>
        </Column>
        <Column
            field="roles"
            style="max-width: fit-content"
            :header="$t('labels.roles')"
        >
            <template #body="{data}">
                <div v-for="element in data.roles.sort()">
                    <div v-if="element === 'master-admin'" class="font-bold">
                        {{element}}
                    </div>
                    <div v-else class="flex">
                        {{ element }}
                        <span
                            style="cursor: pointer"
                            v-if="editPermissionAvailable"
                            @click="onRemoveRole(element, data.username)"
                        >
                            <i
                                class="pi pi-times-circle ml-1 text-red-600"
                                style="margin-top: 2px"
                            ></i>
                        </span>
                    </div>
                </div>
            </template>
        </Column>
        <Column
            field="createdAt"
            :sortable="true"
            style="max-width: 6rem"
            :header="$t('labels.memberSince')"
        >
            <template #body="{data}">{{data.createdAtFormatted}}</template>
        </Column>
        <Column
            field="lastActivity"
            :sortable="true"
            style="max-width: 6rem"
            :header="$t('labels.user.lastActivity')"
        >
            <template #body="{data}">{{data.lastActivityFormatted}}</template>
        </Column>
        <Column
            v-if="editPermissionAvailable"
            style="min-width: 6rem"
            bodyStyle="text-align:center"
        >
            <template #body="{data}">
                <span
                    style="cursor: pointer"
                    v-if="data.roles.indexOf('master-admin') === -1"
                    @click="selectedUser = data;displayAddPermissionDialog = true"
                >
                    <i
                        class="pi pi-plus-circle ml-1 text-green-600"
                        style="margin-top: 2px"
                    ></i>
                </span>
            </template>
        </Column>
    </DataTable>
</Panel>
<AddUserPermissionDialog
    :selected-user="selectedUser"
    :display-dialog="displayAddPermissionDialog"
    @close-dialog="closeDialogListener"
/>
