<CustomerReturn
    v-if="ticketDetails"
    :ticket-details="ticketDetails"
    @reload-ticket-details="handleReloadTicketEvent"
></CustomerReturn>
<SupplierReturn
    v-if="ticketDetails"
    :ticket-details="ticketDetails"
    @reload-ticket-details="handleReloadTicketEvent"
></SupplierReturn>
<template
    v-if="ticketDetails"
    v-for="(referenceKey, translationKey) in ({
                                'receivingRepairPart': 'ticketReceivingRepairPart'
                        })"
>
    <p-fieldset
        class="mb-3"
        :legend="$t('labels.ticket.references.' +
                        translationKey)"
        :toggleable="true"
    >
        <div
            v-if="ticketDetails[referenceKey] && ticketDetails[referenceKey].length > 0"
            v-for="referenceItem in (ticketDetails[referenceKey] || [])"
            v-html="getReferenceItemLayout(referenceItem,
                                    ticketDetails.url)"
        ></div> </p-fieldset
></template>
<DeliveryNote
    :ticket-details="ticketDetails"
    v-if="ticketDetails"
    @reload-ticket-details="handleReloadTicketEvent"
></DeliveryNote>
