<LoadingPlugin v-model:active="loading" />
<DataTable
    stripedRows
    :rows="100"
    paginator
    :rowsPerPageOptions="[100, 200, 500]"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    current-page-report-template="{first} to {last} of {totalRecords}"
    :value="items"
    scrollable
    scrollHeight="calc(100vh - 25rem)"
    responsiveLayout="scroll"
    :rowClass="rowClass"
>
    <Column>
        <template #header>
            <PriceImportTemplateMenu
                v-if="items && (items.length || 0) > 0"
                :supplier-number="supplierConfiguration?.supplierNumber"
                :sheet-name="sheetName"
                :mapping-definition="state"
                :chosen-manufacturer="chosenManufacturer"
                @clear-template="clearTemplate"
                @apply-template="applyTemplate"
            />
        </template>
        <template #body="{data}">
            <div class="font-bold text-sm">
                <Tag severity="primary">{{data?.rowIndex+1}}</Tag>
            </div>
        </template>
    </Column>
    <Column
        v-for="(col,colIndex) in columns"
        bodyClass="text-center"
        style="max-width: 16rem"
    >
        <template #header>
            <div class="flex justify-content-center w-full text-sm">
                <Tag severity="success">{{col.header}}</Tag>
            </div></template
        >
        <template #body="{data}">
            <div class="flex flex-column justify-content-center text-sm">
                <div>
                    <span
                        :class="{'font-bold': data?.rowIndex === state.headerRowIndex}"
                        ><span
                            v-if="data?.rowIndex > state.headerRowIndex && ['SUPPLIER_ARTICLE_COST_PRICE', 'SUPPLIER_ARTICLE_COST_PRICE_BASE', 'SUPPLIER_ARTICLE_LIST_PRICE'].includes(state.columnMappingArray.find((item)=> item.columnIndex === colIndex)?.systemColumn)"
                            >{{formatRegularPriceColumn(data.rowData,
                            colIndex)}}</span
                        ><span v-else>{{data.rowData[colIndex] }}</span>
                    </span>

                    <span v-if="data?.rowIndex === state.headerRowIndex">
                        <i
                            class="pi pi-cog ml-2 text-green-500"
                            style="cursor: pointer"
                            @click="bulkMenuToggle($event, colIndex)"
                            aria-haspopup="true"
                            :aria-controls="'overlay_tmenu_' + col.header"
                        ></i>
                        <TieredMenu
                            ref="bulkMenu"
                            :id="'overlay_tmenu_' + col.header"
                            :model="addBulkItems"
                            popup
                    /></span>
                </div>
                <div
                    v-if="data?.rowIndex > state.headerRowIndex && !rowsToSkip.includes(data?.rowIndex) && state.columnMappingArray.find((item) =>
                        item.columnIndex === colIndex)?.systemColumn === 'SUPPLIER_DISCOUNT_GROUP'"
                >
                    <Tag class="ml-1 mt-1" severity="success"
                        >{{ getDiscountPercentageForRow(data) }} %
                    </Tag>
                </div>
            </div>

            <div
                v-if="data?.rowIndex === state.headerRowIndex"
                class="mt-2 font-bold"
            >
                <template
                    v-if="state.columnMappingArray.some((item) => item.columnIndex === colIndex)"
                >
                    <Tag severity="danger"
                        >{{$t('labels.priceImport.availableColumns.' +
                        state.columnMappingArray.find((item) => item.columnIndex
                        === colIndex).systemColumn ).toUpperCase()}}
                        <i
                            class="pi pi-trash text-xs ml-2"
                            @click="removeColumnMapping(colIndex)"
                            style="cursor: pointer"
                        ></i
                    ></Tag>
                    <Tag
                        class="ml-1 mt-1"
                        v-if="[
                        'SUPPLIER_ARTICLE_COST_PRICE',
                        'SUPPLIER_ARTICLE_COST_PRICE_BASE',
                        'SUPPLIER_ARTICLE_LIST_PRICE'
                    ].includes(state.columnMappingArray.find((item) =>
                    item.columnIndex === colIndex).systemColumn
                    )"
                        severity="warning"
                        >{{ state.columnMappingArray.find((item) =>
                        item.columnIndex === colIndex).grossAmount ? 'BRUTTO' :
                        'NETTO' }}
                    </Tag>
                    <Tag
                        class="ml-1 mt-1"
                        v-if="[
                        'SUPPLIER_ARTICLE_COST_PRICE',
                        'SUPPLIER_ARTICLE_COST_PRICE_BASE',
                        'SUPPLIER_ARTICLE_LIST_PRICE'
                    ].includes(state.columnMappingArray.find((item) =>
                    item.columnIndex === colIndex).systemColumn
                    )"
                        severity="success"
                        >{{ state.columnMappingArray.find((item) =>
                        item.columnIndex === colIndex).currency .toUpperCase()}}
                    </Tag>
                    <Tag
                        class="ml-1 mt-1"
                        v-if="costPriceStartDate && 
                    'SUPPLIER_ARTICLE_COST_PRICE' ===
                state.columnMappingArray.find((item) =>
                item.columnIndex === colIndex)?.systemColumn
                "
                        >AB {{ costPriceStartDate.toLocaleDateString("de-DE") }}
                    </Tag>
                    <Tag
                        class="ml-1 mt-1"
                        v-if="listPriceStartDate && 
                        'SUPPLIER_ARTICLE_LIST_PRICE' ===
                    state.columnMappingArray.find((item) =>
                    item.columnIndex === colIndex)?.systemColumn
                    "
                        >AB {{ listPriceStartDate.toLocaleDateString("de-DE") }}
                    </Tag>
                </template>
            </div>
        </template>
    </Column>
    <Column style="min-width: fit-content">
        <template #body="{data}">
            <div
                v-if="computedCostPriceBaseColumn?.merchantDiscount || computedDiscountGroupColumn"
                class="text-sm"
            >
                <div v-if="data?.rowIndex === state.headerRowIndex">
                    <div>
                        <span class="font-bold">{{$t('labels.ekPrice')}}</span>
                    </div>
                    <div>
                        <Tag severity="danger" class="mt-1 mr-1"
                            >{{$t('labels.priceImport.availableColumns.SUPPLIER_ARTICLE_COST_PRICE').toUpperCase()}}
                        </Tag>
                        <Tag class="mr-1" severity="warning">NETTO</Tag>
                        <Tag class="mr-1" severity="success"
                            >{{
                            computedCostPriceBaseColumn.currency.toUpperCase()}}
                        </Tag>
                        <Tag
                            class="mt-1"
                            v-if="computedCostPriceBaseColumn?.merchantDiscount"
                        >
                            {{computedCostPriceBaseColumn.merchantDiscount.name
                            ? computedCostPriceBaseColumn.merchantDiscount.name
                            + ' : ' :
                            ''}}{{computedCostPriceBaseColumn.merchantDiscount.percentage}}%
                            {{computedCostPriceBaseColumn.merchantDiscount.manufacturerName
                            ? ('(' +
                            computedCostPriceBaseColumn.merchantDiscount.manufacturerName
                            + ')') : '' }}
                        </Tag>
                        <Tag class="ml-1 mt-1" v-if="costPriceStartDate"
                            >AB {{
                            costPriceStartDate.toLocaleDateString("de-DE") }}
                        </Tag>
                    </div>
                </div>
                <span
                    v-if="state.headerRowIndex > -1 && data?.rowIndex > state.headerRowIndex"
                    >{{formatMerchantDiscountColumn(data?.rowIndex)}}</span
                >
            </div>
        </template>
    </Column>
    <Column style="min-width: 5rem">
        <template #body="{data}">
            <div class="text-sm flex justify-content-end">
                <i
                    v-if="data?.rowIndex !== state.headerRowIndex && !rowsToSkip.includes(data?.rowIndex)"
                    class="pi pi-star text-sm"
                    :class="{'text-yellow-500': data?.rowIndex > state.headerRowIndex}"
                    style="cursor: pointer"
                    @click="setHeaderRow(data?.rowIndex)"
                    v-tooltip.top="$t('labels.setAsHeader')"
                ></i>
                <template
                    v-if="state.headerRowIndex > -1 && data?.rowIndex > state.headerRowIndex"
                >
                    <i
                        v-if="rowsToSkip.includes(data?.rowIndex)"
                        class="pi pi-plus text-sm text-green-500 ml-2"
                        style="cursor: pointer"
                        @click="addRowToImport(data?.rowIndex)"
                    ></i>
                    <i
                        v-else
                        class="pi pi-trash text-sm text-red-500 ml-2"
                        style="cursor: pointer"
                        @click="removeRowFromImport(data?.rowIndex)"
                    ></i>
                </template>
            </div>
        </template>
    </Column>
</DataTable>
<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="w-full flex justify-content-between mt-2">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button severity="success" v-text="$t('buttons.next')" type="submit">
        </p-button>
    </div>
</form>
