import {computed, onMounted, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Fieldset from 'primevue/fieldset';
import Dropdown from 'primevue/dropdown';
import {useVuelidate} from '@vuelidate/core';
import {helpers, requiredIf} from '@vuelidate/validators';
import {i18n} from '@/utils/i18n';
import Editor from '@tinymce/tinymce-vue';
import {getAll} from '@/services/metadata';
import Dialog from 'primevue/dialog';
import {stripTagsAndTruncate} from '@/utils/helpers';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        duplicateCheckValues: Object,
        pairValues: Object,
        savingInProgress: Boolean
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        'p-fieldset': Fieldset,
        'p-dropdown': Dropdown,
        InputNumber,
        tiny: Editor,
        PrimeDialog: Dialog
    },
    setup(props: any, context: any) {
        const savingInProgress = ref(props.savingInProgress);

        const pairValues = ref(props.pairValues);
        const duplicateCheckValues = ref(props.duplicateCheckValues);
        const submitted = ref(false);

        onMounted(() => {
            getAll(['supplier'], false).then((data: any) => {
                const test = data.data['supplier'].map(
                    (item: {
                        id: string;
                        name: string;
                        supplierNumber: string;
                        currencyId: string;
                        currencyName: string;
                        company2: string;
                        referenceNumber: string | null;
                    }) => {
                        return {
                            label: stripTagsAndTruncate(
                                [item.name, item.company2].join(' '),
                                150,
                                '...'
                            ),
                            value: item.id,
                            currencyId: item.currencyId,
                            currencyName: item.currencyName,
                            supplierNumber: item.supplierNumber,
                            referenceNumber: item.referenceNumber
                        };
                    }
                );
                data.data['supplier'] = test;
                suppliers.value = Object.assign({}, data.data);
            });
        });

        const prePopulateForm = () => {
            state.value['sku'] =
                pairValues.value?.sku ||
                duplicateCheckValues.value?.sku ||
                state.value['sku'];

            state.value['ekPrice'] =
                pairValues.value?.ekPrice || state.value['ekPrice'];

            for (const key in state.value) {
                if (
                    Object.keys(pairValues.value).includes(key) &&
                    (!key.startsWith('ekStartDate') ||
                        pairValues.value[key] > 0)
                ) {
                    state.value[key as keyof typeof state.value] =
                        pairValues.value[key];
                }
            }
        };

        const state = ref({
            supplier: null,
            sku: null,
            ekTaxRateType: 'STANDARD',
            ekPrice: null,
            supplierCurrency: null,
            ekStartDate: new Date(),
            discount1: null,
            discount2: null,
            discountGroup: null,
            trailingDiscount: null
        });
        const suppliers = ref({
            supplier: []
        });

        const rules = computed(() => {
            const localRules: any = {};

            /*['sku', 'supplier', 'currency'].forEach((item: string) => {
                    const key = item + '-' + platform;
                    localRules[key as keyof typeof localRules] = {
                        required
                    };
                });*/

            localRules['supplier'] = {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return state.value['sku'] !== null;
                    })
                )
            };

            localRules['sku'] = {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return state.value['supplier'] !== null;
                    })
                )
            };

            localRules['supplierCurrency'] = {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return state.value['supplier'] !== null;
                    })
                )
            };

            /*localRules['ekPrice-' + platform] = {
                    required: requiredIf(() => {
                        return (
                            state.value['ekStartDate-' + platform] !== null ||
                            state.value['discount1-' + platform] !== null ||
                            state.value['discount2-' + platform] !== null
                        );
                    })
                };

                localRules['ekStartDate-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['ekPrice-' + platform] !== null;
                    })
                };

                localRules['ekTaxRateType-' + platform] = {
                    required: requiredIf(() => {
                        return (
                            state.value['ekPrice-' + platform] !== null ||
                            state.value['ekStartDate-' + platform] !== null
                        );
                    })
                };*/

            [
                'ekPrice',
                'ekStartDate',
                'ekTaxRateType',
                'discount1',
                'discount2',
                'discountGroup',
                'trailingDiscount'
            ].forEach((item: string) => {
                localRules[item as keyof typeof localRules] = {};
            });

            return localRules;
        });

        watch(
            () => props.pairValues,
            (val) => {
                if (
                    typeof val === 'object' &&
                    val !== null &&
                    Object.values(val).length > 0
                ) {
                    pairValues.value = val;
                    prePopulateForm();
                }
            }
        );

        watch(
            () => props.duplicateCheckValues,
            (val) => {
                if (
                    typeof val === 'object' &&
                    val !== null &&
                    Object.values(val).length > 0
                ) {
                    duplicateCheckValues.value = val;
                    prePopulateForm();
                }
            }
        );

        watch(
            () => props.savingInProgress,
            (val) => {
                savingInProgress.value = val;
            }
        );

        const v$ = useVuelidate(rules, state);

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 4,
                formValues: state.value
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            if (fieldName === 'supplier') {
                const supplier = suppliers.value.supplier.find(
                    (item: any) => event.value === item.value
                );
                if (supplier) {
                    temp['supplierCurrency'] = supplier.currencyName;
                }
            }

            Object.assign(state.value, temp);
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 4);
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            setDropdownValue,
            onBackButtonClicked,
            suppliers,
            locale: i18n.global.locale,
            taxRateTypeOptions: [
                'STANDARD',
                'REDUCED',
                'ZERO',
                'SLIGHTLY_REDUCED',
                'SUPER_REDUCED'
            ].map((item: string) => {
                return {
                    value: item,
                    label: i18n.global.t(
                        'labels.productAdd.taxRateTypeOptions.' + item
                    )
                };
            }),
            savingInProgress
        };
    }
};
