<div class="m-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <ScrollPanel style="height: calc(100vh - 28rem)">
            <div class="grid formgrid p-fluid mt-3 mb-3">
                <div class="col field">
                    <label
                        v-tooltip.top="customAttributesDefinition?.article_weee_relevant?.attributeDescription"
                        >{{ $t('labels.productCompliance.weeeClasification') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.weeeClasification"
                        ></InputSwitch>
                    </div>

                    <p-dropdown
                        v-model="v$.weeeClasification.$model"
                        :options="weeeOptions"
                        optionLabel="label"
                        optionValue="value"
                        :disabled="isBulkEdit && !bulkEditSwitches.weeeClasification"
                        @change="setDropdownValue('weeeClasification', $event)"
                        :class="{'p-invalid':v$.weeeClasification.$invalid && submitted}"
                        class="w-full"
                    />
                </div>
                <div class="field col">
                    <label
                        v-tooltip.top="customAttributesDefinition?.article_batteriegesetz_class?.attributeDescription"
                        >{{ $t('labels.productCompliance.battgClass') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.battgClass"
                        ></InputSwitch>
                    </div>

                    <p-dropdown
                        v-model="v$.battgClass.$model"
                        :options="battgClassOptions"
                        optionLabel="label"
                        optionValue="value"
                        :disabled="isBulkEdit && !bulkEditSwitches.battgClass"
                        @change="setDropdownValue('battgClass', $event)"
                        :class="{'p-invalid':v$.battgClass.$invalid && submitted}"
                        class="w-full"
                    />
                </div>
                <div class="col field">
                    <div>
                        <label
                            v-tooltip.top="customAttributesDefinition?.article_compliance_date_last_check?.attributeDescription"
                            >{{ $t('labels.productCompliance.lastCheckDate') }}
                        </label>
                        <div v-if="isBulkEdit">
                            <InputSwitch
                                v-model="bulkEditSwitches.lastCheckDate"
                            ></InputSwitch>
                        </div>
                    </div>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        :disabled="isBulkEdit && !bulkEditSwitches.lastCheckDate"
                        v-model="v$.lastCheckDate.$model"
                        class="full-width"
                        showButtonBar
                    />
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div
                    class="col field"
                    v-for="fieldId in ['conformityAvailable', 'safetyInstructions', 'emc', 'atex']"
                >
                    <label
                        v-tooltip.top="customAttributesDefinition?.[mapping[fieldId]]?.attributeDescription"
                        >{{ $t('labels.productCompliance.' + fieldId) }}
                    </label>
                    <app-checkbox
                        v-if="!isBulkEdit"
                        v-model="v$[fieldId].$model"
                        :binary="true"
                    />
                    <TriStateCheckbox
                        v-else
                        v-model="v$[fieldId].$model"
                    ></TriStateCheckbox>
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div class="col field">
                    <label
                        v-tooltip.top="customAttributesDefinition?.article_compliance_doc?.attributeDescription"
                        >{{ $t('labels.productCompliance.doc') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.doc"
                        ></InputSwitch>
                    </div>

                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.doc"
                        v-model="v$.doc.$model"
                        :class="{'p-invalid':v$.doc.$invalid && submitted}"
                    />
                </div>
                <div class="field col">
                    <label
                        v-tooltip.top="customAttributesDefinition?.article_compliance_additional_info?.attributeDescription"
                        >{{ $t('labels.productCompliance.additionalInformation')
                        }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.additionalInformation"
                        ></InputSwitch>
                    </div>

                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.additionalInformation"
                        v-model="v$.additionalInformation.$model"
                        :class="{'p-invalid':v$.additionalInformation.$invalid && submitted}"
                    />
                </div>
                <div class="field col">
                    <label
                        v-tooltip.top="customAttributesDefinition?.article_compliance_manufacturer_address?.attributeDescription"
                        >{{ $t('labels.productCompliance.manufacturerAddress')
                        }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.manufacturerAddress"
                        ></InputSwitch>
                    </div>

                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.manufacturerAddress"
                        v-model="v$.manufacturerAddress.$model"
                        :class="{'p-invalid':v$.manufacturerAddress.$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div
                    class="col field"
                    v-for="fieldId in ['marketReady', 'testProtocol', 'red', 'lvd', 'rohs']"
                >
                    <label
                        class="text-sm mr-3"
                        v-tooltip.top="customAttributesDefinition?.[mapping[fieldId]]?.attributeDescription"
                        >{{ $t('labels.productCompliance.' + fieldId) }}
                    </label>
                    <app-checkbox
                        v-if="!isBulkEdit"
                        v-model="v$[fieldId].$model"
                        :binary="true"
                    />
                    <TriStateCheckbox
                        v-else
                        v-model="v$[fieldId].$model"
                    ></TriStateCheckbox>
                </div>
            </div>
            <div class="grid formgrid p-fluid mb-3">
                <div class="field col">
                    <label
                        v-tooltip.top="customAttributesDefinition?.article_compliance_german_manual?.attributeDescription"
                        >{{ $t('labels.productCompliance.germanManual') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.germanManual"
                        ></InputSwitch>
                    </div>

                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.germanManual"
                        v-model="v$.germanManual.$model"
                        :class="{'p-invalid':v$.germanManual.$invalid && submitted}"
                    />
                </div>

                <div class="field col">
                    <label
                        v-tooltip.right="customAttributesDefinition?.article_german_instructions?.attributeDescription"
                        >{{ $t('labels.productCompliance.germanInstructions') }}
                    </label>
                    <div v-if="isBulkEdit">
                        <InputSwitch
                            v-model="bulkEditSwitches.germanInstructions"
                        ></InputSwitch>
                    </div>

                    <p-textarea
                        rows="4"
                        autocomplete="off"
                        :disabled="isBulkEdit && !bulkEditSwitches.germanInstructions"
                        v-model="v$.germanInstructions.$model"
                        :class="{'p-invalid':v$.germanInstructions.$invalid && submitted}"
                    />
                </div>
            </div>
        </ScrollPanel>
        <div class="flex justify-content-end" v-if="!isBulkEdit">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</div>
