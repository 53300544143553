<DataTable
    class="p-datatable-sm text-sm"
    stripedRows
    :rowClass="rowClass"
    paginator
    :rows="20"
    scrollable
    scrollHeight="calc(100vh - 23rem)"
    ref="dt"
    dataKey="id"
    v-model:filters="filters"
    filterDisplay="row"
    :totalRecords="ticketTemplates?.length || 0"
    :value="ticketTemplates"
    responsiveLayout="scroll"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows-per-page-options="[10,20,50]"
    current-page-report-template="{first} to {last} of {totalRecords}"
>
    <template #header>
        <p-button
            severity="success"
            class="text-sm"
            @click="selectedTemplate = null;showTemplateDialog = true"
        >
            + {{ $t("labels.template") }}
        </p-button>
    </template>
    <Column
        field="title"
        sortable
        style="max-width: fit-content"
        :header="$t('labels.subject')"
    >
        <template #body="{data}">
            {{data.title}}
            <div v-if="data.id && data.public" class="mt-1">
                <Tag severity="success">{{$t('labels.public')}}</Tag>
            </div>
        </template>
    </Column>
    <Column
        field="content"
        style="max-width: fit-content"
        :header="$t('labels.content')"
    >
        <template #body="{data}">
            <div v-html="data.content"></div>
        </template>
    </Column>
    <Column
        field="section"
        style="max-width: fit-content"
        header="Bereich"
        :filterMatchModeOptions="[{label: 'Equals', value: sectionFilter}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <p-dropdown
                v-model="filterModel.value"
                :options="[{'label': getSectionLabel('comments'), value: 'comments'}, {'label': getSectionLabel('retoure'), value: 'retoure'}, {'label': getSectionLabel('compensationShipment'), value: 'compensationShipment'}]"
                @change="filterCallback"
                class="p-column-filter text-sm"
                optionLabel="label"
                optionValue="value"
            />
        </template>
        <template #body="{data}">
            <span v-for="sec in data.section"
                ><Tag class="ml-1 mt-1" style="text-transform: uppercase">
                    {{getSectionLabel(sec)}}</Tag
                >
            </span>
        </template>
    </Column>
    <Column frozen alignFrozen="right" style="min-width: 4rem">
        <template #body="{data}">
            <i
                class="pi pi-pencil text-color"
                style="cursor: pointer"
                v-tooltip.top="$t('buttons.edit')"
                @click="selectedTemplate = data;showTemplateDialog = true"
            ></i>
            <i
                v-if="data.id"
                class="pi text-red-600 pi-times-circle ml-3"
                style="cursor: pointer"
                v-tooltip.top="'Löschen'"
                @click="deleteTemplateHandler(data)"
            ></i>
        </template>
    </Column>
    <!--<Column
        field="public"
        dataType="boolean"
        style="max-width: 6rem"
        :header="$t('labels.public')"
    >
        <template #body="{data}">
            <i
                v-if="data.id"
                class="pi"
                :class="{'text-green-600 pi-check-circle': data.public, 'text-red-600 pi-times-circle': !(data.public)}"
            ></i>
        </template>
    </Column>-->
</DataTable>
<AddEditTicketTemplateDialog
    :display-dialog="showTemplateDialog"
    :selected-template="selectedTemplate"
    @close-dialog="closeTemplateDialog"
></AddEditTicketTemplateDialog>
