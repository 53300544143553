import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f31307e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid"
}
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_p_tree = _resolveComponent("p-tree")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showSearchField)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AutoComplete, {
                modelValue: _ctx.searchTerm,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.searchTerm) = $event)),
                suggestions: _ctx.filteredResults,
                placeholder: _ctx.searchTermPlaceHolder,
                onComplete: _ctx.loadData,
                onItemSelect: _ctx.onTermSelected,
                optionLabel: "name",
                optionValue: "id"
              }, null, 8, ["modelValue", "suggestions", "placeholder", "onComplete", "onItemSelect"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_p_tree, {
      value: _ctx.categories,
      selectionMode: _ctx.selectionType,
      onNodeExpand: _ctx.onNodeExpand,
      onNodeSelect: _ctx.onNodeSelect,
      onNodeUnselect: _ctx.onNodeUnselect,
      selectionKeys: _ctx.selectedCategories,
      "onUpdate:selectionKeys": _cache[1] || (_cache[1] = $event => ((_ctx.selectedCategories) = $event)),
      metaKeySelection: false,
      class: "text-sm",
      expandedKeys: _ctx.expandedKeys
    }, null, 8, ["value", "selectionMode", "onNodeExpand", "onNodeSelect", "onNodeUnselect", "selectionKeys", "expandedKeys"])
  ], 64))
}