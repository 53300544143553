<loading v-model:active="loading" />
<Panel :header="$t('labels.dimensionsAcquisition')">
    <!-- Main content -->
    <template v-if="productDetails?.weclapp">
        <div class="mb-5">
            <DataTable
                class="p-datatable-sm"
                stripedRows
                :lazy="false"
                ref="dt"
                dataKey="id"
                responsiveLayout="scroll"
                :value="[productDetails]"
            >
                <Column
                    style="min-width: 9rem"
                    :header="$t('labels.articleNumber')"
                >
                    <template #body="{data}">
                        {{data.weclapp.articleNumber}}
                    </template>
                </Column>
                <Column style="min-width: 9rem" :header="$t('labels.name')">
                    <template #body="{data}"> {{data.weclapp.name}} </template>
                </Column>
                <Column style="min-width: 9rem" :header="$t('labels.ean')">
                    <template #body="{data}"> {{data.weclapp.ean}} </template>
                </Column>
            </DataTable>
        </div>
        <div class="grid p-fluid mt-5 mb-5">
            <div class="col">
                <div class="grid">
                    <div class="col">
                        <label
                            >{{ $t('labels.productHome.grossWeight') }}
                        </label>
                        <div>
                            <InputNumber
                                :locale="locale"
                                :class="{'p-invalid':submitted && v$.articleGrossWeight.$invalid}"
                                mode="decimal"
                                :min="0"
                                showButtons
                                :suffix="' kg'"
                                :maxFractionDigits="4"
                                :step="0.1"
                                v-model="v$.articleGrossWeight.$model"
                                @keyup.enter="onPastedDimension('articleGrossWeight')"
                                ref="articleWeightInputField"
                            />
                            <small
                                v-if="submitted"
                                style="display: block"
                                v-for="error in v$.articleGrossWeight.$silentErrors"
                                class="p-error"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>

                    <div class="col">
                        <label>{{ $t('labels.productHome.length') }} </label>
                        <div>
                            <InputNumber
                                :locale="locale"
                                :class="{'p-invalid':submitted && v$.articleLength.$invalid}"
                                mode="decimal"
                                :min="0"
                                showButtons
                                :maxFractionDigits="1"
                                :suffix="' cm'"
                                :step="0.1"
                                v-model="v$.articleLength.$model"
                                @keyup.enter="onPastedDimension('articleLength')"
                                ref="articleLengthInputField"
                            />
                            <small
                                v-if="submitted"
                                style="display: block"
                                v-for="error in v$.articleLength.$silentErrors"
                                class="p-error"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>

                    <div class="col">
                        <label>{{ $t('labels.productHome.width') }} </label>
                        <div>
                            <InputNumber
                                :locale="locale"
                                :class="{'p-invalid':submitted && v$.articleWidth.$invalid}"
                                mode="decimal"
                                :min="0"
                                showButtons
                                :suffix="' cm'"
                                :maxFractionDigits="1"
                                :step="0.1"
                                v-model="v$.articleWidth.$model"
                                @keyup.enter="onPastedDimension('articleWidth')"
                                ref="articleWidthInputField"
                            />
                            <small
                                v-if="submitted"
                                style="display: block"
                                v-for="error in v$.articleWidth.$silentErrors"
                                class="p-error"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>

                    <div class="col">
                        <label>{{ $t('labels.productHome.height') }} </label>
                        <div>
                            <InputNumber
                                :locale="locale"
                                :class="{'p-invalid':submitted && v$.articleHeight.$invalid}"
                                mode="decimal"
                                :min="0"
                                showButtons
                                :suffix="' cm'"
                                :maxFractionDigits="1"
                                :step="0.1"
                                v-model="v$.articleHeight.$model"
                                @keyup.enter="onPastedDimension('articleHeight')"
                                ref="articleHeightInputField"
                            />
                            <small
                                v-if="submitted"
                                style="display: block"
                                v-for="error in v$.articleHeight.$silentErrors"
                                class="p-error"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>
                </div>
                <div class="grid mt-3">
                    <div class="col field">
                        <label>{{ $t('labels.ean') }} </label>
                        <div>
                            <InputText
                                name="ean"
                                autocomplete="off"
                                v-model="v$.ean.$model"
                                :class="{'p-invalid':v$.ean.$invalid && submitted}"
                                ref="articleEanInputField"
                            />
                            <small
                                v-if="submitted"
                                style="display: block"
                                v-for="error in v$.ean.$silentErrors"
                                class="p-error"
                                :key="error"
                            >
                                {{ error.$message }}
                            </small>
                        </div>
                    </div>
                    <div class="col-1 field">
                        <label
                            >{{ $t('labels.productHome.singlePackage') }}
                        </label>
                        <div>
                            <app-checkbox
                                v-model="v$.article_single_package.$model"
                                binary
                                class="mt-2"
                            />
                        </div>
                    </div>

                    <div class="col-2 field">
                        <label
                            >{{ $t('labels.productHome.containsAerosols') }}
                        </label>
                        <div>
                            <app-checkbox
                                v-model="v$.article_flag_aerosole.$model"
                                binary
                                class="mt-2"
                            />
                        </div>
                    </div>
                    <div class="col field">
                        <label
                            >{{ $t('labels.productHome.dangerousGoods') }}
                        </label>
                        <div>
                            <app-checkbox
                                v-model="v$.article_flag_akku_groesser_100wh.$model"
                                binary
                                class="mt-2"
                            />
                        </div>
                    </div>

                    <div class="col field">
                        <label
                            >{{ $t('labels.productHome.dangerousGoodsSmall') }}
                        </label>
                        <div>
                            <app-checkbox
                                v-model="v$.article_flag_akku_kleiner_100wh.$model"
                                binary
                                class="mt-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full flex justify-content-between">
            <router-link
                v-if="originalStoragePlace && originalPath"
                :to="{
                                    name: 'ProductsWithIncompleteDimensions',
                                    query: {storagePlace: originalStoragePlace, path: originalPath.join('|')}
                                }"
            >
                <p-button severity="danger" :disabled="savingInProgress">
                    {{ $t("buttons.dimensionsAcquisitionCancel") }}
                </p-button>
            </router-link>
            <router-link
                v-else
                :to="{
                                    name: 'ProductsWithIncompleteDimensions'
                                }"
            >
                <p-button severity="danger" :disabled="savingInProgress">
                    {{ $t("buttons.dimensionsAcquisitionCancel") }}
                </p-button>
            </router-link>
            <!--<p-button
                severity="danger"
                :disabled="savingInProgress"
                @click="handleCancel"
            >
                {{ $t("buttons.dimensionsAcquisitionCancel") }}
            </p-button>-->
            <p-button
                :disabled="savingInProgress"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.dimensionsAcquisitionConfirm')"
                @click="handleSubmit(!v$.$invalid)"
            >
            </p-button>
        </div>
    </template>
</Panel>
