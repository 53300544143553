<loading v-model:active="loading" />
<Panel>
    <template #header>
        <h4>
            {{ $t('labels.ticketNumber') }}
            <a
                :href="ticketDetails?.url + 'webapp/view/ticketing/ticketDetail.page?entityId=' + ticketDetails?.id"
                target="_blank"
            >
                {{ticketDetails?.ticketNumber}}
            </a>
            <template v-if="ticketDetails?.customer">
                <span class="spacer-dot"></span>
                <a
                    :href="ticketDetails.url + 'webapp/view/party/PartyDetail.page?entityId=' + ticketDetails.partyId"
                    target="_blank"
                    >{{ ticketDetails.customer.company ??
                    ticketDetails.customer.firstName + ' ' +
                    ticketDetails.customer.lastName }}</a
                >
            </template>
            <span
                class="ticket-status-badge ml-3"
                :style="getStatusCellStyle(ticketDetails?.ticketStatus)"
                >{{ticketDetails?.ticketStatus}}</span
            >
        </h4>
    </template>
    <TabView class="tabview-custom text-sm" ref="tabview4">
        <TabPanel>
            <template #header>
                <i
                    v-if="ticketDetails"
                    :style="getPriorityCellStyle(ticketDetails.ticketPriority)"
                    class="pi pi-circle-fill mr-2"
                ></i>
                <span>{{ $t('labels.description') }}</span>
            </template>
            <DescriptionTab
                :ticket-details="ticketDetails"
                @reload-ticket-details="loadTicketDetails"
            />
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-comments mr-2"></i>
                <span
                    >{{ $t("labels.ticket.comments") }}
                    <span v-if="ticketDetails?.comments"
                        >({{ticketDetails.comments.length}})</span
                    ></span
                >
            </template>
            <CommentsTab :ticket-details="ticketDetails"> </CommentsTab>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-images mr-2"></i>
                <span>{{ $t("labels.images") }}</span>
            </template>
            <ImagesTab :ticket-details="ticketDetails" />
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-envelope mr-2"></i>
                <span
                    >{{ $t("labels.ticket.emailArchive") }}
                    <span v-if="ticketDetails?.archivedEmails"
                        >({{ticketDetails.archivedEmails.length}})</span
                    ></span
                >
            </template>
            <ArchivedEmailsTab
                :ticket-details="ticketDetails"
                @reload-ticket-details="loadTicketDetails"
            >
            </ArchivedEmailsTab>
        </TabPanel>
        <TabPanel v-if="showServiceTab">
            <template #header>
                <i class="pi pi-wrench mr-2"></i>
                <span>{{ $t("labels.ticket.serviceDetails") }}</span>
            </template>
            <ServiceTab :ticket-details="ticketDetails"></ServiceTab>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-sitemap mr-2"></i>
                <span>{{ $t("labels.ticket.referenceDetails") }}</span>
            </template>
            <ReferenceDetailsTab
                :ticket-details="ticketDetails"
                @reload-ticket-details="loadTicketDetails"
            ></ReferenceDetailsTab>
        </TabPanel>
        <TabPanel>
            <template #header>
                <i class="pi pi-wrench mr-2"></i>
                <span>{{ $t("labels.ticket.repairDetails") }}</span>
            </template>
            <RepairDetailsTab
                :ticket-details="ticketDetails"
                @reload-ticket-details="loadTicketDetails"
            ></RepairDetailsTab>
        </TabPanel>
        <TabPanel
            :disabled="!ticketDetails?.partyId || (ticketDetails?.customAttributes && ticketDetails.customAttributes.ticket_inbound_tracking !== null && ticketDetails.customAttributes.ticket_inbound_tracking.trim() !== '')"
        >
            <template #header>
                <i class="fa-brands fa-ups mr-2 font-bold"></i>
                <span>Returns Label versenden</span>
            </template>
            <SendReturnLabelTab
                :ticket-details="ticketDetails"
                @reload-ticket-details="loadTicketDetails"
            >
            </SendReturnLabelTab>
        </TabPanel>
    </TabView>
</Panel>
