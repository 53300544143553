import apiClient from '@/utils/axios';
import {i18n} from '@/utils/i18n';
import {AxiosResponse} from 'axios';

export const getAll = (params: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/ticket-templates', {params});
};

export const addTemplate = async (payload: any): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/ticket-templates', {...payload});
};

export const editTemplate = async (
    templateId: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/ticket-templates/' + templateId, {
        ...payload
    });
};

export const removeTemplate = async (
    templateId: string
): Promise<AxiosResponse> => {
    return apiClient.delete('/api/v1/ticket-templates/' + templateId);
};

export interface ITicketTemplate {
    key: string;
    title: string;
    description: string;
    content: string;
    section: string[];
}

export const defaultTicketTemplates: Array<ITicketTemplate> = [
    {
        key: 'articleArrived',
        title: i18n.global.t(
            'labels.ticket.templates.comments.articleArrivedTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.articleArrived',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'articleArrivedEnglish',
        title: i18n.global.t(
            'labels.ticket.templates.comments.articleArrivedEnglishTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.articleArrivedEnglish',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'retoureArrived',
        title: i18n.global.t(
            'labels.ticket.templates.comments.retoureArrivedTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.retoureArrived',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'retoureArrivedEnglish',
        title: i18n.global.t(
            'labels.ticket.templates.comments.retoureArrivedEnglishTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.retoureArrivedEnglish',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'supplierReturnCreated',
        title: i18n.global.t(
            'labels.ticket.templates.comments.supplierReturnCreatedTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.supplierReturnCreated',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'supplierReturnCreatedEnglish',
        title: i18n.global.t(
            'labels.ticket.templates.comments.supplierReturnCreatedEnglishTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.supplierReturnCreatedEnglish',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'customerCompensationCreated',
        title: i18n.global.t(
            'labels.ticket.templates.comments.customerCompensationCreatedTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.customerCompensationCreated',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'customerCompensationCreatedEnglish',
        title: i18n.global.t(
            'labels.ticket.templates.comments.customerCompensationCreatedEnglishTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.customerCompensationCreatedEnglish',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'repairCancellation',
        title: i18n.global.t(
            'labels.ticket.templates.comments.repairCancellationTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.repairCancellation',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'repairCancellationEnglish',
        title: i18n.global.t(
            'labels.ticket.templates.comments.repairCancellationEnglishTitle'
        ),
        description: '',
        content: i18n.global.t(
            'labels.ticket.templates.comments.repairCancellationEnglish',
            {
                ticketOwner: '{$ticketOwner}',
                ticketType: 'RMA',
                ticketNumber: '{$ticketNumber}'
            }
        ),
        section: ['comments']
    },
    {
        key: 'documentText',
        title: i18n.global.t('labels.ticket.templates.documentTextTitle'),
        description: '',
        content: i18n.global.t('labels.ticket.templates.documentText'),
        section: ['compensationShipment']
    },
    {
        key: 'documentTextEnglish',
        title: i18n.global.t(
            'labels.ticket.templates.documentTextEnglishTitle'
        ),
        description: '',
        content: i18n.global.t('labels.ticket.templates.documentTextEnglish'),
        section: ['compensationShipment']
    }
];
