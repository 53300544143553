import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import AddNewRetoure from '@/pages/tickets/retoure/add-new-retoure.vue';
import {stripTagsAndTruncate} from '@/utils/helpers';
export default {
    components: {
        AddRetoureWizard: AddNewRetoure,
        PrimeDialog: Dialog
    },
    emits: ['close-dialog'],
    props: {
        displayAddRetoureDialog: Boolean,
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const {ticketDetails} = toRefs(props);

        watch(props, (args) => {
            showDialog.value = args.displayAddRetoureDialog;
        });

        const closeResponsive = (withReload: boolean = false) => {
            context.emit('close-dialog', withReload);
        };

        const truncatedSubject = computed(() => {
            return ticketDetails.value?.subject
                ? stripTagsAndTruncate(ticketDetails.value.subject, 50, '...')
                : '';
        });

        return {
            showDialog,
            closeResponsive,
            ticketDetails,
            truncatedSubject
        };
    }
};
