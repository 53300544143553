import {computed, onMounted, onUnmounted, ref, watch} from 'vue';
import {useVuelidate} from '@vuelidate/core';
import {helpers, minValue, required} from '@vuelidate/validators';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import Checkbox from 'primevue/checkbox';
import Panel from 'primevue/panel';
import {getAll} from '@/services/products';
import {FilterMatchMode} from 'primevue/api';
import Dropdown from 'primevue/dropdown';
import {stripTagsAndTruncate} from '@/utils/helpers';
import Avatar from 'primevue/avatar';
import {upsDangerousGoodsGroundTransportStates} from '@/utils/helpers';
import {getChemicalData} from '@/services/ups';
import AutoComplete from 'primevue/autocomplete';
import eventBus from '@/event-bus/event-bus';

export default {
    emits: ['data-entered'],
    props: {
        shipment: {
            type: Object,
            default: null
        },
        hasDangerousGoods: {
            type: Boolean,
            default: false
        },
        headless: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-dropdown': Dropdown,
        InputNumber,
        InputText,
        loading: LoadingPlugin,
        Panel,
        Avatar,
        AutoComplete
    },
    setup(props: any, context: any) {
        const hasDangerousGoods = ref(props.hasDangerousGoods);
        const shipmentDetails = ref(props.shipment);
        const submitted = ref(false);
        const toast = useToast();
        const weclappProductOptions = ref([]);
        const chemicalOptions = ref([]);
        const filteredProducts = ref([]);
        const groupedShipments = ref([]);
        const isHeadless = ref(props.headless);
        const firstRowAutocompleteField = ref([]);
        const grossWeightField = ref([]);
        const lengthField = ref([]);
        const heightField = ref([]);
        const widthField = ref([]);
        const timeoutId = ref(null);

        watch(
            () => props.hasDangerousGoods,
            (val) => {
                hasDangerousGoods.value = val;
                if (
                    chemicalOptions.value.length < 1 &&
                    hasDangerousGoods.value &&
                    shipmentDetails.value?.warehouse?.name
                ) {
                    loadChemicalData();
                }
            }
        );

        watch(
            () => props.headless,
            (val) => {
                isHeadless.value = val;

                if (val) {
                    state.value.packageItems.length = 0;
                    addPackageItem(
                        (shipmentDetails.value?.shipmentItems || []).reduce(
                            (accumulator: any, currentValue: any) =>
                                accumulator +
                                    parseFloat(
                                        currentValue.article
                                            ?.articleGrossWeight || '0.0'
                                    ) *
                                        currentValue.quantity || 1,
                            0
                        )
                    );
                }
            }
        );

        watch(
            () => props.shipment,
            (val) => {
                shipmentDetails.value = val;
                if (
                    chemicalOptions.value.length < 1 &&
                    hasDangerousGoods.value &&
                    shipmentDetails.value?.warehouse?.name
                ) {
                    loadChemicalData();
                }

                if (shipmentDetails.value?.shipmentItems) {
                    state.value.packageItems.length = 0;
                    addPackageItem(
                        (shipmentDetails.value?.shipmentItems || []).reduce(
                            (accumulator: any, currentValue: any) =>
                                accumulator +
                                    parseFloat(
                                        currentValue.article
                                            ?.articleGrossWeight || '0.0'
                                    ) *
                                        currentValue.quantity || 1,
                            0
                        )
                    );
                }
            },
            {deep: true}
        );

        onMounted(() => {
            eventBus.on('group-shipment-toggled', (value: any) => {
                groupedShipments.value = [...value];

                if (state.value.packageItems.length === 1) {
                    state.value.packageItems[0].grossWeight =
                        recalculateFirstPackageGrossWeight();
                }
            });

            eventBus.on('trigger-shipment-packages-form-submit', () => {
                handleSubmit(!v$.value.$invalid);
            });

            eventBus.on(
                'trigger-adding-package-from-product-dimensions',
                (value: any) => {
                    if (!value || state.value.packageItems.length < 1) {
                        return;
                    }

                    const index = state.value.packageItems.length - 1;

                    Object.assign(state.value.packageItems[index], value);
                }
            );

            loadProducts();
            if (
                chemicalOptions.value.length < 1 &&
                hasDangerousGoods.value &&
                shipmentDetails.value?.warehouse?.name
            ) {
                loadChemicalData();
            }
            if (
                (state.value?.packageItems || []).length < 1 &&
                (shipmentDetails.value?.shipmentItems || isHeadless.value)
            ) {
                addPackageItem(
                    (shipmentDetails.value?.shipmentItems || []).reduce(
                        (accumulator: any, currentValue: any) =>
                            accumulator +
                                parseFloat(
                                    currentValue.article?.articleGrossWeight ||
                                        '0.0'
                                ) *
                                    currentValue.quantity || 1,
                        0
                    )
                );
            }
        });

        onUnmounted(() => {
            eventBus.off('trigger-shipment-packages-form-submit');
            eventBus.off('group-shipment-toggled');
        });

        const setFocusOnFirstAutocomplete = () => {
            if ((firstRowAutocompleteField.value || []).length < 1) return;
            const index =
                state.value.packageItems.length > 0
                    ? state.value.packageItems.length - 1
                    : null;

            if (index === null) {
                return;
            }

            const inputField: HTMLElement = firstRowAutocompleteField.value[
                index
            ].$el as HTMLElement;

            if (inputField?.querySelector('input')) {
                inputField.querySelector('input').focus();
            }
        };

        const setFocusOnDimensionField = (
            index: number,
            identifier: string | null = null
        ) => {
            if ((grossWeightField.value || []).length < 1) return;

            let inputField: HTMLElement | null = null;

            switch (identifier) {
                case 'grossWeight':
                    inputField = lengthField.value[index].$el as HTMLElement;
                    break;
                case 'length':
                    inputField = widthField.value[index].$el as HTMLElement;
                    break;
                case 'width':
                    inputField = heightField.value[index].$el as HTMLElement;
                    break;
                case 'height':
                case 'netWeight':
                    break;
                default:
                    inputField = grossWeightField.value[index]
                        .$el as HTMLElement;
            }

            if (inputField?.querySelector('input')) {
                inputField.querySelector('input').focus();
                if (identifier === null) {
                    inputField.querySelector('input').select();
                }
            }
        };

        const recalculateFirstPackageGrossWeight = () => {
            let endGrossWeight =
                (state.value.packageItems[0].weclappProduct
                    ?.articleGrossWeight || 0) +
                (shipmentDetails.value?.shipmentItems || []).reduce(
                    (accumulator: any, currentValue: any) =>
                        accumulator +
                            parseFloat(
                                currentValue.article?.articleGrossWeight ||
                                    '0.0'
                            ) *
                                currentValue.quantity || 1,
                    0
                );

            (groupedShipments.value || []).forEach((gr: any) => {
                endGrossWeight += (gr.shipmentItems || []).reduce(
                    (accumulator: any, currentValue: any) =>
                        accumulator +
                            parseFloat(
                                currentValue.article?.articleGrossWeight ||
                                    '0.0'
                            ) *
                                currentValue.quantity || 1,
                    0
                );
            });

            return endGrossWeight;
        };

        const loadProducts = () => {
            getAll(
                {
                    first: 0,
                    rows: 100,
                    columns: [
                        'id',
                        'articleNumber',
                        'name',
                        'articleHeight',
                        'articleLength',
                        'articleWidth',
                        'articleGrossWeight',
                        'articleNetWeight',
                        'manufacturerPartNumber'
                    ],
                    filters: {
                        name: {
                            value: 'Karton',
                            matchMode: FilterMatchMode.CONTAINS
                        },
                        articleType: {
                            value: 'BASIC',
                            matchMode: FilterMatchMode.EQUALS
                        }
                    }
                },
                true
            )
                .then((data) => {
                    if (data.data?.items) {
                        weclappProductOptions.value = data.data.items;
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const searchProducts = (event: any, index: number) => {
            if ((event?.query || '').trim() === '') {
                filteredProducts.value = [...weclappProductOptions.value];
                return;
            }

            let weclappProduct = (weclappProductOptions.value || []).find(
                (item: any) =>
                    item.articleNumber === (event?.query || '').trim() ||
                    item.manufacturerPartNumber === (event?.query || '').trim()
            );

            if (
                !weclappProduct &&
                (weclappProductOptions.value || []).filter(
                    (item: any) =>
                        item.articleNumber.includes(event?.query || '') ||
                        item.name.includes(event?.query || '')
                ).length === 1
            ) {
                weclappProduct = (weclappProductOptions.value || []).filter(
                    (item: any) =>
                        item.articleNumber.includes(event?.query || '') ||
                        item.name.includes(event?.query || '')
                )[0];
            }

            if (weclappProduct) {
                state.value.packageItems[index].weclappProduct = weclappProduct;

                if (state.value.packageItems.length === 1) {
                    state.value.packageItems[0].grossWeight =
                        recalculateFirstPackageGrossWeight();
                }

                state.value.packageItems[index].height =
                    weclappProduct?.articleHeight
                        ? weclappProduct?.articleHeight * 100
                        : null;

                state.value.packageItems[index].length =
                    weclappProduct?.articleLength
                        ? weclappProduct?.articleLength * 100
                        : null;

                state.value.packageItems[index].width =
                    weclappProduct?.articleWidth
                        ? weclappProduct?.articleWidth * 100
                        : null;
                return;
            }

            filteredProducts.value = (weclappProductOptions.value || []).filter(
                (item: any) =>
                    item.articleNumber.includes(event?.query || '') ||
                    item.name.includes(event?.query || '')
            );
        };

        const state = ref({
            packageItems: []
        });

        const rules = {
            packageItems: {
                $each: helpers.forEach({
                    weclappProduct: {},
                    singlePackageArticleNumber: {},
                    grossWeight: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        ),
                        minValue: helpers.withMessage(
                            i18n.global.t('messages.valueCantBeLessThan', {
                                value: 0.1
                            }),
                            minValue(0.1)
                        )
                    },
                    length: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    height: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    width: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    dangerousGoods: {},
                    netWeight: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            (value: any, record: any) => {
                                return !!value || !record?.dangerousGoods;
                            }
                        )
                    },
                    chemicalId: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            (value: any, record: any) => {
                                return !!value || !record?.dangerousGoods;
                            }
                        )
                    }
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            if (
                !state.value.packageItems ||
                state.value.packageItems.length < 1
            ) {
                toast.error(
                    i18n.global.t('messages.pleaseAddAtLeastOneRetourePosition')
                );
                return;
            }

            context.emit(
                'data-entered',
                state.value.packageItems.map((packageItem: any) => {
                    return {
                        articleNumber:
                            packageItem?.weclappProduct?.articleNumber,
                        grossWeight: packageItem?.grossWeight,
                        length: packageItem?.length,
                        height: packageItem?.height,
                        width: packageItem?.width,
                        dangerousGoods: packageItem?.dangerousGoods,
                        netWeight: packageItem?.netWeight,
                        chemicalId: packageItem?.chemicalId,
                        singlePackageArticleNumber:
                            packageItem?.singlePackageArticleNumber
                    };
                })
            );
        };

        const addPackageItem = (grossWeight: number | null = null) => {
            state.value.packageItems.push({
                weclappProduct: null,
                singlePackageArticleNumber: null,
                grossWeight,
                length: null,
                height: null,
                width: null,
                dangerousGoods: false,
                netWeight: null,
                chemicalId: null
            });

            setTimeout(setFocusOnFirstAutocomplete, 100);
        };
        const removePackageItem = (index: number) => {
            state.value.packageItems.splice(index, 1);

            setTimeout(setFocusOnFirstAutocomplete, 100);
        };

        const totalWeight = computed(() => {
            return (state?.value?.packageItems || []).reduce(
                (accumulator: any, currentValue: any) =>
                    accumulator + parseFloat(currentValue.grossWeight),
                0
            );
        });

        const onProductChange = (event: any, index: number) => {
            if (
                !event?.value?.articleNumber ||
                !state.value?.packageItems ||
                !state.value.packageItems[index] ||
                !(weclappProductOptions.value || []).some(
                    (item) => item.articleNumber === event.value.articleNumber
                )
            ) {
                return;
            }

            if (state.value.packageItems.length === 1) {
                state.value.packageItems[0].grossWeight =
                    recalculateFirstPackageGrossWeight();
            }

            state.value.packageItems[index].height = event.value?.articleHeight
                ? event.value?.articleHeight * 100
                : null;

            state.value.packageItems[index].length = event.value?.articleLength
                ? event.value?.articleLength * 100
                : null;

            state.value.packageItems[index].width = event.value?.articleWidth
                ? event.value?.articleWidth * 100
                : null;

            setTimeout(() => setFocusOnDimensionField(index), 100);
        };

        const dangerousGoodsSuitableRecipientAddress = computed(() => {
            return (
                shipmentDetails.value?.recipientAddress?.countryCode &&
                upsDangerousGoodsGroundTransportStates.indexOf(
                    shipmentDetails.value?.recipientAddress?.countryCode
                ) !== -1 /*&&
                'DE' !== shipmentDetails.value?.recipientAddress?.countryCode*/
            );
        });

        const loadChemicalData = () => {
            ['UN3480', 'UN3481'].forEach((chemicalId: string) => {
                getChemicalData(
                    chemicalId,
                    shipmentDetails.value.warehouse.name
                )
                    .then((data) => {
                        if (
                            !chemicalOptions.value.some(
                                (item: any) => item.idNumber === chemicalId
                            )
                        ) {
                            chemicalOptions.value.push(data.data);

                            chemicalOptions.value.sort((a, b) =>
                                a.idNumber.localeCompare(b.idNumber)
                            );
                        }
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            });
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            addPackageItem,
            removePackageItem,
            locale: i18n.global.locale,
            onProductChange,
            filteredProducts,
            stripTagsAndTruncate: stripTagsAndTruncate,
            formatter: new Intl.NumberFormat(i18n.global.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),

            hasDangerousGoods,
            totalWeight,
            dangerousGoodsSuitableRecipientAddress,
            chemicalOptions,
            searchProducts,
            firstRowAutocompleteField,
            grossWeightField,
            lengthField,
            heightField,
            widthField,
            onPastedDimension: (index: number, fieldName: string) => {
                if (timeoutId.value) {
                    clearTimeout(timeoutId.value);
                }
                timeoutId.value = setTimeout(
                    () => setFocusOnDimensionField(index, fieldName),
                    1000
                );
            }
        };
    }
};
