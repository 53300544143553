import LoadingPlugin from 'vue-loading-overlay';
import OrderList from 'primevue/orderlist';
import PrimeButton from 'primevue/button';
import Image from 'primevue/image';
import Toolbar from 'primevue/toolbar';
import AddDialog from '@/components/dialog/product-images/add-image.vue';
import UseAsCoverDialog from '@/components/dialog/product-images/use-as-cover.vue';
import DeleteDialog from '@/components/dialog/product-images/delete-image.vue';
import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {addFromUrl, reorder} from '@/services/product-images';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';

import ScrollPanel from 'primevue/scrollpanel';
import store from '@/store';
import {useConfirm} from 'primevue/useconfirm';

export default {
    emits: ['reload-product'],
    components: {
        OrderList,
        'p-button': PrimeButton,
        'p-image': Image,
        AddDialog,
        DeleteDialog,
        UseAsCoverDialog,
        Toolbar,
        loading: LoadingPlugin,
        ScrollPanel
    },
    props: {
        shopwareDetails: Object,
        secondShopwareDetails: Object,
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const images = ref([]);
        const shopwareDetails = ref(null);
        const secondShopwareDetails = ref(null);
        const selectedImages = ref([]);
        const displayUseAsCoverDialog = ref(false);
        const displayDeleteDialog = ref(false);
        const toast = useToast();
        const selection = ref();
        const loading = ref(false);
        const {platform} = toRefs(props);
        const confirm = useConfirm();

        onMounted(() => {
            shopwareDetails.value = Object.assign(
                {},
                props.shopwareDetails || {}
            );
            secondShopwareDetails.value = props.secondShopwareDetails
                ? Object.assign({}, props.secondShopwareDetails)
                : null;
            images.value = [].concat(shopwareDetails.value?.images || []);
        });

        const showUseAsCoverDialog = (data: Array<any>) => {
            selectedImages.value = data;
            displayUseAsCoverDialog.value = true;
        };

        const showDeleteDialog = (data: Array<any>) => {
            selectedImages.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayUseAsCoverDialog.value = false;
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
                selection.value = null;
            }
        };

        const imagesReordered = computed(() => {
            return (
                (shopwareDetails.value?.images || [])
                    .filter((item: any) => item)
                    .map((u: any) => u.id.trim())
                    .join('|') !==
                (images?.value || [])
                    .filter((item: any) => item)
                    .map((u: any) => u.id.trim())
                    .join('|')
            );
        });

        const reorderImages = () => {
            loading.value = true;
            reorder(
                shopwareDetails.value?.productNumber,
                images.value,
                platform.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    closeDialogListener(true);
                })
                .catch((error) => {
                    toast.error(error.message);
                    closeDialogListener();
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        watch(props, () => {
            shopwareDetails.value = Object.assign({}, props.shopwareDetails);
            secondShopwareDetails.value = props.secondShopwareDetails
                ? Object.assign({}, props.secondShopwareDetails)
                : null;
            images.value = [].concat(props.shopwareDetails?.images || []);
        });

        const showMultiDeleteButton = computed(() => {
            return selection.value && selection.value.length > 1;
        });

        const showDeleteAllButton = computed(() => {
            return images.value && images.value.length > 0;
        });

        const copyToClipboard = (data: any, event: any) => {
            navigator.clipboard.writeText(data);
            event.stopPropagation();
            toast.success(i18n.global.t('labels.copied'), {
                timeout: 500
            });
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        return {
            images,
            shopwareDetails,
            secondShopwareDetails,
            selectedImages,
            displayUseAsCoverDialog,
            displayDeleteDialog,
            showUseAsCoverDialog,
            showDeleteDialog,
            closeDialogListener,
            reorderImages,
            selection,
            showMultiDeleteButton,
            showDeleteAllButton,
            copyToClipboard,
            loading,
            imagesReordered,
            platform,
            handleCopyingToOtherPlatform: (args: any) => {
                let platformForCopying: string | null = null;
                switch (platform.value || resolvedPlatform.value?.value) {
                    case 'teltec-demo':
                        platformForCopying = 'videodata-demo';
                        break;
                    case 'teltec':
                        platformForCopying = 'videodata';
                        break;
                    case 'videodata-demo':
                        platformForCopying = 'teltec-demo';
                        break;
                    case 'videodata':
                        platformForCopying = 'teltec';
                        break;
                }

                confirm.require({
                    message: i18n.global.t(
                        'messages.copyToOherShopConfirmation',
                        {
                            item: args
                                .map(
                                    (u: any) =>
                                        u?.fileName + '.' + u?.fileExtension
                                )
                                .join(', ')
                        }
                    ),
                    header: i18n.global.t('messages.pleaseConfirm'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: i18n.global.t('labels.yes'),
                    rejectLabel: i18n.global.t('labels.no'),
                    accept: async () => {
                        loading.value = true;
                        try {
                            for (const element of args || []) {
                                await addFromUrl(
                                    shopwareDetails.value?.productNumber,
                                    (element.title || '').startsWith(
                                        shopwareDetails.value?.productNumber
                                    )
                                        ? element.title
                                        : shopwareDetails.value?.productNumber,
                                    element.url,
                                    platformForCopying
                                );
                            }
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            closeDialogListener(true);
                        } catch (error: any) {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        } finally {
                            loading.value = false;
                        }
                    }
                });
            }
        };
    }
};
