<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label> {{ $t('labels.productHome.creator') }} </label>
            <InputText
                :disabled="true"
                autocomplete="off"
                v-model="v$.articleCreator.$model"
                :class="{'p-invalid':v$.articleCreator.$invalid && submitted}"
            />
        </div>
        <div class="col field">
            <label>{{ $t('labels.productHome.productType') }} </label>

            <p-dropdown
                v-model="v$.productType.$model"
                :options="productTypeOptions"
                optionLabel="label"
                optionValue="value"
                @change="setDropdownValue('productType', $event)"
                :class="{'p-invalid':v$.productType.$invalid && submitted}"
                :showClear="true"
            >
            </p-dropdown>
            <small
                v-if="(v$.productType.$invalid && submitted) || v$.productType.$pending.$response"
                class="p-error"
                >{{v$.productType.required.$message.replace('Value',
                $t('labels.productHome.productType'))}}</small
            >
        </div>
    </div>
    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label>{{ $t('labels.articleName') }} </label>

            <InputText
                autocomplete="off"
                v-model="v$.name.$model"
                :class="{'p-invalid':v$.name.$invalid && submitted}"
            />
            <small
                v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response"
                class="p-error"
                >{{v$.name.required.$message.replace('Value',
                $t('labels.articleName'))}}</small
            >
        </div>
        <div class="col field">
            <label>{{ $t('labels.productAdd.mpn') }} </label>

            <InputText
                autocomplete="off"
                v-model="v$.manufacturerPartNumber.$model"
                :class="{'p-invalid':v$.manufacturerPartNumber.$invalid && submitted}"
            />
        </div>
    </div>
    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label>{{ $t('labels.productHome.currency') }} </label>

            <p-dropdown
                :filter="true"
                v-model="v$.currency.$model"
                :options="currencyOptions"
                optionLabel="label"
                optionValue="value"
                @change="setDropdownValue('currency', $event)"
                :class="{'p-invalid':v$.currency.$invalid && submitted}"
                :showClear="true"
            >
            </p-dropdown>
            <small
                v-if="(v$.currency.$invalid && submitted) || v$.currency.$pending.$response"
                class="p-error"
                >{{v$.currency.required.$message.replace('Value',
                $t('labels.productHome.currency'))}}</small
            >
        </div>
        <div class="col field">
            <label>{{ $t('labels.ean') }} </label>

            <InputText
                autocomplete="off"
                v-model="v$.ean.$model"
                :class="{'p-invalid':v$.ean.$invalid && submitted}"
            />
        </div>
    </div>
    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label>{{ $t('labels.productHome.unit') }} </label>

            <p-dropdown
                v-model="v$.unit.$model"
                :options="unitOptions"
                optionLabel="label"
                optionValue="value"
                @change="setDropdownValue('unit', $event)"
                :class="{'p-invalid':v$.unit.$invalid && submitted}"
                :showClear="true"
            >
            </p-dropdown>
            <small
                v-if="(v$.unit.$invalid && submitted) || v$.unit.$pending.$response"
                class="p-error"
                >{{v$.unit.required.$message.replace('Value',
                $t('labels.productHome.unit'))}}</small
            >
        </div>
        <div class="col field">
            <label
                v-tooltip.top="customAttributesDefinition?.article_memo?.attributeDescription"
            >
                {{ $t('labels.productHome.memo') }}
            </label>

            <InputText
                autocomplete="off"
                v-model="v$.internalInfo.$model"
                :class="{'p-invalid':v$.internalInfo.$invalid && submitted}"
            />
        </div>
    </div>
    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label>{{ $t('labels.productAdd.taxRateType') }} </label>

            <p-dropdown
                v-model="v$.taxRateType.$model"
                :options="taxRateTypeOptions"
                optionLabel="label"
                optionValue="value"
                @change="setDropdownValue('taxRateType', $event)"
                :showClear="true"
                :class="{'p-invalid':v$.taxRateType.$invalid && submitted}"
            >
            </p-dropdown>
            <small
                v-if="(v$.taxRateType.$invalid && submitted) || v$.taxRateType.$pending.$response"
                class="p-error"
                >{{v$.taxRateType.required.$message.replace('Value',
                $t('labels.productAdd.taxRateType'))}}</small
            >
        </div>
        <div class="col field">
            <label> {{ $t('labels.productAdd.uvpPrice') }} </label>

            <InputNumber
                mode="decimal"
                :locale="locale"
                :maxFractionDigits="2"
                autocomplete="off"
                v-model="v$.uvpPrice.$model"
                :class="{'p-invalid':v$.uvpPrice.$invalid && submitted}"
            />
            <small
                v-if="(v$.uvpPrice.$invalid && submitted) || v$.uvpPrice.$pending.$response"
                class="p-error"
                >{{v$.uvpPrice.required.$message.replace('The value',
                $t('labels.productAdd.uvpPrice'))}}</small
            >
        </div>
    </div>
    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label>{{ $t('labels.productHome.sellFromDate') }} </label>

            <p-calendar
                :placeholder="$t('labels.productHome.sellFromDate')"
                autocomplete="off"
                dateFormat="dd.mm.yy"
                v-model="v$.sellFromDate.$model"
                :class="{'tt-form-input': true, 'p-invalid':v$.sellFromDate.$invalid && submitted}"
            />
        </div>
        <div class="col field">
            <label> {{ $t('labels.productAdd.uvpStartDate') }} </label>

            <p-calendar
                autocomplete="off"
                dateFormat="dd.mm.yy"
                v-model="v$.uvpStartDate.$model"
                :class="{'tt-form-input': true, 'p-invalid':v$.uvpStartDate.$invalid && submitted}"
            />
            <small
                v-if="(v$.uvpStartDate.$invalid && submitted) || v$.uvpStartDate.$pending.$response"
                class="p-error"
                >{{v$.uvpStartDate.required.$message.replace('The value',
                $t('labels.productAdd.uvpStartDate'))}}</small
            >
        </div>
    </div>
    <div class="formgrid grid p-fluid">
        <div class="col field">
            <label
                v-tooltip.top="customAttributesDefinition?.article_weee_relevant?.attributeDescription"
                >{{ $t('labels.productHome.weeeClasification') }}
            </label>

            <p-dropdown
                v-model="v$.articleWeee.$model"
                :options="weeeOptions"
                optionLabel="label"
                optionValue="value"
                @change="setDropdownValue('articleWeee', $event)"
                :class="{'p-invalid':v$.articleWeee.$invalid && submitted}"
                :showClear="true"
            >
            </p-dropdown>
        </div>
        <div class="col field">
            <label
                v-tooltip.top="customAttributesDefinition?.article_garantiezeit?.attributeDescription"
                >{{ $t('labels.productHome.warrantyTime') }}
            </label>

            <p-dropdown
                v-model="v$.articleWarrantyTime.$model"
                :options="warrantyTimeOptions"
                optionLabel="label"
                optionValue="value"
                @change="setDropdownValue('articleWarrantyTime', $event)"
                :class="{'p-invalid':v$.articleWarrantyTime.$invalid && submitted}"
                showClear
            >
            </p-dropdown>
        </div>
    </div>
    <div class="formgrid grid p-fluid">
        <div class="col field mt-2">
            <label
                v-tooltip.top="customAttributesDefinition?.article_ueberlaenge?.attributeDescription"
            >
                {{ $t('labels.productHome.oversized') }}
            </label>

            <p-checkbox
                v-model="v$.articleOversized.$model"
                binary
                class="ml-2 mb-1"
            />
        </div>
        <div class="col field mt-2">
            <label
                v-tooltip.top="customAttributesDefinition?.article_sperrgut?.attributeDescription"
            >
                {{ $t('labels.productHome.bulkGoods') }}
            </label>

            <p-checkbox
                v-model="v$.articleBulkGoods.$model"
                binary
                class="ml-2 mb-1"
            />
        </div>
        <div class="field col mt-2">
            <label
                v-tooltip.top="customAttributesDefinition?.article_batteriegesetz_relevant?.attributeDescription"
                >{{ $t('labels.productHome.battg') }}
            </label>

            <p-checkbox
                v-model="v$.articleBattg.$model"
                binary
                class="ml-2 mb-1"
            />
        </div>
        <div class="col field mt-2">
            <label
                v-tooltip.top="customAttributesDefinition?.article_sell_out?.attributeDescription"
            >
                {{ $t('labels.productHome.sellOut') }}
            </label>

            <p-checkbox
                v-model="v$.articleSellOut.$model"
                binary
                class="ml-2 mb-1"
            />
        </div>
        <div class="col-3 field mt-2">
            <label
                v-tooltip.top="customAttributesDefinition?.article_flag_akku_groesser_100wh?.attributeDescription"
            >
                {{ $t('labels.productHome.dangerousGoods') }}
            </label>

            <p-checkbox
                v-model="v$.articleDangerousGoods.$model"
                binary
                class="ml-2 mb-1"
            />
        </div>
    </div>
    <div class="formgrid grid p-fluid" v-if="tagsPermissionAvailable">
        <div class="col field">
            <label> {{ $t('labels.tags') }} </label>
            <Chips
                v-model="v$.tags.$model"
                :allowDuplicate="false"
                :placeholder="$t('labels.metatagsSeparatorInfo')"
                separator=","
            />
        </div>
    </div>
    <div
        class="w-full mt-3 flex"
        :class="{'justify-content-between' : !hideBackButton, 'justify-content-end' : hideBackButton }"
    >
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
            v-if="!hideBackButton"
        >
        </p-button>

        <p-button severity="success" type="submit" v-text="$t('buttons.next')">
        </p-button>
    </div>
</form>
