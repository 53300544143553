import {computed, ref} from 'vue';
import Dropdown from 'primevue/dropdown';
import AutoComplete from 'primevue/autocomplete';
import {useVuelidate} from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {helpers, required} from '@vuelidate/validators';
import {getAll as getAllProducts, getSingle} from '@/services/products';
import {
    ekPriceCalculation,
    uvpPriceCalculation,
    vkPriceCalculation
} from '@/utils/helpers';
import Button from 'primevue/button';
import {i18n} from '@/utils/i18n';
import {FilterMatchMode} from 'primevue/api';
import LoadingPlugin from 'vue-loading-overlay';
import store from '@/store';

export default {
    emits: ['environments-selected'],
    components: {
        'p-dropdown': Dropdown,
        'p-button': Button,
        AutoComplete,
        loading: LoadingPlugin
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const savingInProgress = ref(false);
        const toast = useToast();
        const filteredProducts = ref();
        const loading = ref(false);

        const state = ref({
            productSource: null,
            productType: null
        });

        if (history?.state?.originalProduct) {
            const {name, articleNumber} = JSON.parse(
                history?.state?.originalProduct
            );

            state.value.productSource = {
                label: '(' + articleNumber + ') ' + name,
                value: articleNumber
            };
        }

        const rules = {
            productSource: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    (value: any) => {
                        return !value || value?.value;
                    }
                )
            },
            productType: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        const searchProducts = (event: any) => {
            getAllProducts({
                first: 0,
                rows: 20,
                columns: ['id', 'articleNumber', 'name'],
                filters: {
                    name: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    articleNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filterConjunction: 'or'
            })
                .then((data) => {
                    if (data.data?.items) {
                        filteredProducts.value = data.data.items.map(
                            (item: {
                                id: string;
                                articleNumber: string;
                                name: string;
                            }) => {
                                return {
                                    label:
                                        '(' +
                                        item.articleNumber +
                                        ') ' +
                                        item.name,
                                    value: item.articleNumber
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const showNextButton = computed(() => {
            return !v$.value.$invalid;
        });

        const resolveDescription = (originalDescription: string) => {
            switch (state.value.productType) {
                case 'OpenBox':
                    return vdh.value
                        ? originalDescription +
                              '\n<p>Gerät ist neuwertig, nur die OVP geöffnet.</p>'
                        : '<p><span>++TT|Openbox++</span>&nbsp;</p>\n<p><span>Gerät ist neuwertig, nur die OVP ist geöffnet.</span>&nbsp;</p>\n' +
                              originalDescription +
                              '\n<p><span>Garantie</span><span>&nbsp;</span>&nbsp;</p>\n<p><span>Es gelten die verbleibenden Garantiezeiten des Herstellers.</span><span>&nbsp;</span>&nbsp;</p>\n<p>&nbsp;</p>';
                case 'Used-3-Stars':
                    return vdh.value
                        ? originalDescription +
                              '\n<p>Ware mit Gebrauchsspuren, Abbildung weicht vom Zustand des Artikels ab.</p>'
                        : '<p>++TT|Used++&nbsp;&nbsp;</p><p>(3 Sterne ★★★):&nbsp;&nbsp;</p><p>Geräte weisen nur geringe Gebrauchsspuren auf. Fast wie neuwertig.&nbsp; &nbsp;</p><p>Alle Funktionen sind gegeben.&nbsp;&nbsp;</p>\n' +
                              originalDescription +
                              '<p>Garantie&nbsp; &nbsp;</p><p>Es besteht eine 2 Wochen Übernahmegarantie.&nbsp; &nbsp;</p><p>Für dieses Gerät können Sie exklusiv unsere TT|used protect Gebrauchtgeräteversicherung abschließen.&nbsp; &nbsp;</p><p>Diese beinhaltet neben Risiken wie Diebstahl oder Feuer auch die Absicherung vor inneren Betriebsschäden.&nbsp; &nbsp;</p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a></p>';
                case 'Used-2-Stars':
                    return vdh.value
                        ? originalDescription +
                              '\n<p>Ware mit Gebrauchsspuren, Abbildung weicht vom Zustand des Artikels ab.</p>'
                        : '<p>++TT|Used++&nbsp;&nbsp;</p><p>(2 Sterne ★★):&nbsp;&nbsp;</p><p>Geräte weisen, dem Alter des Artikels entsprechenden mäßigen Verschleiß auf.</p>\n' +
                              originalDescription +
                              '<p>Garantie&nbsp; &nbsp;</p><p>Es besteht eine 2 Wochen Übernahmegarantie.&nbsp; &nbsp;</p><p>Für dieses Gerät können Sie exklusiv unsere TT|used protect Gebrauchtgeräteversicherung abschließen.&nbsp; &nbsp;</p><p>Diese beinhaltet neben Risiken wie Diebstahl oder Feuer auch die Absicherung vor inneren Betriebsschäden.&nbsp; &nbsp;</p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a></p>';
                case 'Used-1-Star':
                    return vdh.value
                        ? originalDescription +
                              '\n<p>Ware mit Gebrauchsspuren, Abbildung weicht vom Zustand des Artikels ab.</p>'
                        : '<p>++TT|Used++&nbsp;&nbsp;</p><p>(1 Sterne ★):&nbsp;&nbsp;</p><p>&nbsp;</p><p>Geräte befinden sich in einem gebrauchten Zustand mit überdurchschnittlichen Abnutzungserscheinungen, sind jedoch voll funktionsfähig.&nbsp; &nbsp;</p>\n' +
                              originalDescription +
                              '<p>Garantie&nbsp; &nbsp;</p><p>Es besteht eine 2 Wochen Übernahmegarantie.&nbsp; &nbsp;</p><p>Für dieses Gerät können Sie exklusiv unsere TT|used protect Gebrauchtgeräteversicherung abschließen.&nbsp; &nbsp;</p><p>Diese beinhaltet neben Risiken wie Diebstahl oder Feuer auch die Absicherung vor inneren Betriebsschäden.&nbsp; &nbsp;</p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a></p>';

                case 'ExDemo-Grade-A':
                    return vdh.value
                        ? originalDescription +
                              '\n<p>Demoware in neuwertigem Zustand mit voller Herstellergarantie und Originalverpackung.</p>'
                        : '<p><span><span>++</span><span>TT|Demo</span><span>++</span></span><span>&nbsp;</span></p><p><span><span>(Grade A): </span></span><span>&nbsp;</span></p><p><span><span>OVP ist vorhanden. Gerät war im Showroom oder beim Kunden extern als Demo im Einsatz. </span></span><span>&nbsp;</span></p><p><span><span>Das Gerät weist leichte Gebrauchsspuren auf. Das Geräte Set ist vollständig.</span></span><span>&nbsp;</span></p>' +
                              originalDescription +
                              '<p><span><span>Garantie</span></span><span>&nbsp;</span></p><p><span><span>Es gelten die verbleibenden Garantiezeiten des Herstellers (muss zum Zeitpunkt des Verkaufs bewertet werden), mindestens aber 2 Wochen Übernahmegarantie.&nbsp;</span></span><span>&nbsp;</span></p><p><span><span>Für dieses Gerät können Sie exklusiv unseren TT|Vollkasko-Schutz abschließen.&nbsp;</span></span><span>&nbsp;</span></p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a><span>&nbsp;</span></p>';
                case 'ExDemo-Grade-B':
                    return vdh.value
                        ? originalDescription +
                              '\n<p>Demoware in neuwertigem Zustand mit voller Herstellergarantie und Originalverpackung.</p>'
                        : '<p><span><span>++</span><span>TT|Demo</span><span>++</span></span><span>&nbsp;</span></p><p><span><span>(Grade B): </span></span><span>&nbsp;</span></p><p><span><span>OVP ist eventuell nicht mehr vorhanden. Gerät war im Showroom, auf Messen oder als Leihgerät beim Kunden extern im Einsatz.  </span></span><span>&nbsp;</span></p><p><span><span>Das Gerät weist Gebrauchsspuren auf.</span></span><span>&nbsp;</span></p>' +
                              originalDescription +
                              '<p><span><span>Garantie</span></span><span>&nbsp;</span></p><p><span><span>Es gelten die verbleibenden Garantiezeiten des Herstellers (muss zum Zeitpunkt des Verkaufs bewertet werden), mindestens aber 2 Wochen Übernahmegarantie.&nbsp;</span></span><span>&nbsp;</span></p><p><span><span>Für dieses Gerät können Sie exklusiv unseren TT|Vollkasko-Schutz abschließen.&nbsp;</span></span><span>&nbsp;</span></p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a><span>&nbsp;</span></p>';

                default:
                    return originalDescription;
            }
        };

        const resolveWebshop = (originalDescription: string) => {
            switch (state.value.productType) {
                case 'OpenBox':
                    return vdh.value
                        ? '<p>Zustand: Gerät ist neuwertig, nur die Originalverpackung wurde geöffnet.</p>\n' +
                              originalDescription +
                              '\n<p>Garantie:<br/><br/>Es gelten die verbleibenden Garantiezeiten des Hersteller</p>'
                        : '<h2><span>++TT|Openbox++</span>&nbsp;</h2>\n<p><span>Gerät ist neuwertig, nur die OVP ist geöffnet.</span>&nbsp;</p>\n' +
                              originalDescription +
                              '\n<p><span>Garantie</span><span>&nbsp;</span>&nbsp;</p>\n<p><span>Es gelten die verbleibenden Garantiezeiten des Herstellers.</span><span>&nbsp;</span>&nbsp;</p>\n<p>&nbsp;</p>';
                case 'Used-3-Stars':
                    return vdh.value
                        ? '<p>Zustand: (3 Sterne ★★★)<br/><br/>Gerät weist nur geringe Gebrauchsspuren auf. Fast wie neuwertig. Alle Funktionen sind gegeben.</p>\n' +
                              originalDescription +
                              '\n<p>Garantie:<br/><br/>Es besteht eine 2 Wochen Übernahmegarantie.</p>'
                        : '<h2><span>++TT|Used++</span><span>&nbsp;</span>&nbsp;</h2>\n<p>(3 Sterne ★★★):&nbsp;&nbsp;</p><p>Geräte weisen nur geringe Gebrauchsspuren auf. Fast wie neuwertig.&nbsp; &nbsp;</p><p>Alle Funktionen sind gegeben.&nbsp;&nbsp;</p>\n' +
                              originalDescription +
                              '<p>Garantie&nbsp; &nbsp;</p><p>Es besteht eine 2 Wochen Übernahmegarantie.&nbsp; &nbsp;</p><p>Für dieses Gerät können Sie exklusiv unsere TT|used protect Gebrauchtgeräteversicherung abschließen.&nbsp; &nbsp;</p><p>Diese beinhaltet neben Risiken wie Diebstahl oder Feuer auch die Absicherung vor inneren Betriebsschäden.&nbsp; &nbsp;</p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a></p>';
                case 'Used-2-Stars':
                    return vdh.value
                        ? '<p>Zustand: (2 Sterne ★★)<br/><br/>Gerät weist, dem Alter des Artikels entsprechenden, mäßigen Verschleiß auf.</p>\n' +
                              originalDescription +
                              '\n<p>Garantie:<br/><br/>Es besteht eine 2 Wochen Übernahmegarantie.</p>'
                        : '<h2><span>++TT|Used++</span><span>&nbsp;</span>&nbsp;</h2>\n<p>(2 Sterne ★★):&nbsp;&nbsp;</p><p>Geräte weisen, dem Alter des Artikels entsprechenden mäßigen Verschleiß auf.</p>\n' +
                              originalDescription +
                              '<p>Garantie&nbsp; &nbsp;</p><p>Es besteht eine 2 Wochen Übernahmegarantie.&nbsp; &nbsp;</p><p>Für dieses Gerät können Sie exklusiv unsere TT|used protect Gebrauchtgeräteversicherung abschließen.&nbsp; &nbsp;</p><p>Diese beinhaltet neben Risiken wie Diebstahl oder Feuer auch die Absicherung vor inneren Betriebsschäden.&nbsp; &nbsp;</p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a></p>';
                case 'Used-1-Star':
                    return vdh.value
                        ? '<p>Zustand: (1 Stern ★)<br/><br/>Gerät befindet sich in einem gebrauchten Zustand mit überdurchschnittlichen Abnutzungserscheinungen, ist jedoch voll funktionsfähig.</p>\n' +
                              originalDescription +
                              '\n<p>Garantie:<br/><br/>Es besteht eine 2 Wochen Übernahmegarantie.</p>'
                        : '<h2><span>++TT|Used++</span><span>&nbsp;</span>&nbsp;</h2>\n<p>(1 Sterne ★):&nbsp;&nbsp;</p><p>&nbsp;</p><p>Geräte befinden sich in einem gebrauchten Zustand mit überdurchschnittlichen Abnutzungserscheinungen, sind jedoch voll funktionsfähig.&nbsp; &nbsp;</p>\n' +
                              originalDescription +
                              '<p>Garantie&nbsp; &nbsp;</p><p>Es besteht eine 2 Wochen Übernahmegarantie.&nbsp; &nbsp;</p><p>Für dieses Gerät können Sie exklusiv unsere TT|used protect Gebrauchtgeräteversicherung abschließen.&nbsp; &nbsp;</p><p>Diese beinhaltet neben Risiken wie Diebstahl oder Feuer auch die Absicherung vor inneren Betriebsschäden.&nbsp; &nbsp;</p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a></p>';
                case 'ExDemo-Grade-A':
                    return vdh.value
                        ? '<p>Zustand: (Grade A)<br/><br/>OVP ist vorhanden. Gerät war im Showroom oder beim Kunden extern als Demo im Einsatz. Das Gerät weist leichte Gebrauchsspuren auf. Das Geräte Set ist vollständig.</p>\n' +
                              originalDescription +
                              '\n<p>Garantie:<br/><br/>Es gelten die verbleibenden Garantiezeiten des Herstellers (muss zum Zeitpunkt des Verkaufs bewertet werden), mindestens aber 2 Wochen Übernahmegarantie.</p>'
                        : '<h2><span><span>++</span><span>TT|Demo</span><span>++</span></span><span>&nbsp;</span></h2><p><span><span>(Grade A): </span></span><span>&nbsp;</span></p><p><span><span>OVP ist vorhanden. Gerät war im Showroom oder beim Kunden extern als Demo im Einsatz. </span></span><span>&nbsp;</span></p><p><span><span>Das Gerät weist leichte Gebrauchsspuren auf. Das Geräte Set ist vollständig.</span></span><span>&nbsp;</span></p>' +
                              originalDescription +
                              '<p><span><span>Garantie</span></span><span>&nbsp;</span></p><p><span><span>Es gelten die verbleibenden Garantiezeiten des Herstellers (muss zum Zeitpunkt des Verkaufs bewertet werden), mindestens aber 2 Wochen Übernahmegarantie.&nbsp;</span></span><span>&nbsp;</span></p><p><span><span>Für dieses Gerät können Sie exklusiv unseren TT|Vollkasko-Schutz abschließen.&nbsp;</span></span><span>&nbsp;</span></p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a><span>&nbsp;</span></p>';
                case 'ExDemo-Grade-B':
                    return vdh.value
                        ? '<p>Zustand: (Grade B)<br/><br/>OVP ist eventuell nicht mehr vorhanden. Gerät war im Showroom, auf Messen oder als Leihgerät beim Kunden extern im Einsatz.<br/>Das Gerät weist Gebrauchsspuren auf.</p>\n' +
                              originalDescription +
                              '\n<p>Garantie:<br/><br/>Es gelten die verbleibenden Garantiezeiten des Herstellers (muss zum Zeitpunkt des Verkaufs bewertet werden), mindestens aber 2 Wochen Übernahmegarantie.</p>'
                        : '<h2><span><span>++</span><span>TT|Demo</span><span>++</span></span><span>&nbsp;</span></h2><p><span><span>(Grade B): </span></span><span>&nbsp;</span></p><p><span><span>OVP ist eventuell nicht mehr vorhanden. Gerät war im Showroom, auf Messen oder als Leihgerät beim Kunden extern im Einsatz.  </span></span><span>&nbsp;</span></p><p><span><span>Das Gerät weist Gebrauchsspuren auf.</span></span><span>&nbsp;</span></p>' +
                              originalDescription +
                              '<p><span><span>Garantie</span></span><span>&nbsp;</span></p><p><span><span>Es gelten die verbleibenden Garantiezeiten des Herstellers (muss zum Zeitpunkt des Verkaufs bewertet werden), mindestens aber 2 Wochen Übernahmegarantie.&nbsp;</span></span><span>&nbsp;</span></p><p><span><span>Für dieses Gerät können Sie exklusiv unseren TT|Vollkasko-Schutz abschließen.&nbsp;</span></span><span>&nbsp;</span></p><p><a href="https://www.teltec.de/teltec-schutzprogramm" rel="nofollow"><span><span>https://www.teltec.de/teltec-schutzprogramm</span></span></a><span>&nbsp;</span></p>';

                default:
                    return originalDescription;
            }
        };

        const resolveName = (originalName: string) => {
            let suffix = '';
            switch (state.value.productType) {
                case 'OpenBox':
                    suffix = ' - Opb';
                    break;
                case 'Used-3-Stars':
                case 'Used-2-Stars':
                case 'Used-1-Star':
                    suffix = ' - Used';
                    break;
                case 'DEMO-POOL':
                    suffix = ' - Demo';
                    break;
                case 'ExDemo-Grade-A':
                case 'ExDemo-Grade-B':
                    suffix = ' - ExDemo';
                    break;
                case 'KOMMISSIONSWARE':
                    suffix = ' - Loaner';
                    break;
            }

            return originalName + suffix;
        };

        const resolveMpn = (originalMpn: string) => {
            let suffix = '';
            switch (state.value.productType) {
                case 'OpenBox':
                    suffix = '.opb';
                    break;
                case 'Used-3-Stars':
                case 'Used-2-Stars':
                case 'Used-1-Star':
                    suffix = '.used';
                    break;
                case 'DEMO-POOL':
                    suffix = '.demo';
                    break;
                case 'ExDemo-Grade-A':
                case 'ExDemo-Grade-B':
                    suffix = '.exdemo';
                    break;
                case 'KOMMISSIONSWARE':
                    suffix = '.loa';
                    break;
            }

            return originalMpn + suffix;
        };

        const resolveArticleCategoryId = () => {
            switch (state.value.productType) {
                case 'OpenBox':
                    return '38545340';
                case 'Used-3-Stars':
                case 'Used-2-Stars':
                case 'Used-1-Star':
                    return '38545401';
                case 'ExDemo-Grade-A':
                case 'ExDemo-Grade-B':
                    return '38545420';
                default:
                    return '2672220';
            }
        };

        const platform = computed(() => {
            return store.getters['auth/platform'];
        });

        const vdh = computed(() => {
            return (
                ['videodata', 'videodata-demo'].indexOf(platform?.value) !== -1
            );
        });

        const handleProceedToAddingClick = () => {
            let pairValues = {};
            if (!state.value?.productSource?.value) {
                context.emit('environments-selected', {
                    selectedEnvironments: [platform?.value],
                    pairValues
                });

                return;
            }
            loading.value = true;
            getSingle(state.value.productSource.value)
                .then((data) => {
                    if (data?.data?.weclapp) {
                        pairValues = Object.assign(data.data.weclapp, {
                            sku:
                                data.data.weclapp.articleSupplySources &&
                                data.data.weclapp.articleSupplySources.length >
                                    0
                                    ? data.data.weclapp.articleSupplySources[0]
                                          .articleNumber
                                    : null,
                            supplierNumber:
                                data.data.weclapp.articleSupplySources &&
                                data.data.weclapp.articleSupplySources.length >
                                    0
                                    ? data.data.weclapp.articleSupplySources[0]
                                          .supplierNumber
                                    : null,
                            supplySources:
                                data.data.weclapp.articleSupplySources || [],
                            ekPrice: ekPriceCalculation(
                                data.data.weclapp,
                                false
                            ),
                            uvpPrice: uvpPriceCalculation(
                                data.data.weclapp,
                                false
                            ),
                            vkPrice: vkPriceCalculation(
                                data.data.weclapp,
                                false
                            ),
                            productSource: state.value.productSource.value,
                            productUsedType: state.value.productType,
                            name: data.data.weclapp?.name
                                ? resolveName(data.data.weclapp.name)
                                : null,
                            description: resolveDescription(
                                data.data.weclapp?.description || ''
                            ),
                            webshopText: resolveWebshop(
                                data.data.shopware?.description ||
                                    data.data.weclapp?.description ||
                                    ''
                            ),
                            ean: null,
                            manufacturerPartNumber: data.data.weclapp
                                ?.manufacturerPartNumber
                                ? resolveMpn(
                                      data.data.weclapp.manufacturerPartNumber
                                  )
                                : null,
                            matchCode: data.data.weclapp?.matchCode
                                ? resolveName(data.data.weclapp.matchCode)
                                : null,
                            customAttributes: Object.assign(
                                data.data.weclapp?.customAttributes,
                                {
                                    SN_AUTO_GENERATE: false,
                                    article_garantiezeit:
                                        [
                                            'KOMMISSIONSWARE',
                                            'DEMO-POOL'
                                        ].indexOf(state.value.productType) !==
                                        -1
                                            ? null
                                            : state.value.productType ===
                                              'OpenBox'
                                            ? '1 Jahr'
                                            : '14 Tage',
                                    article_sell_out:
                                        [
                                            'OpenBox',
                                            'Used-3-Stars',
                                            'Used-2-Stars',
                                            'Used-1-Star',
                                            'ExDemo-Grade-A',
                                            'ExDemo-Grade-B'
                                        ].indexOf(state.value.productType) !==
                                        -1
                                            ? true
                                            : null
                                }
                            ),
                            procurementLeadDays:
                                ['KOMMISSIONSWARE', 'DEMO-POOL'].indexOf(
                                    state.value.productType
                                ) !== -1
                                    ? null
                                    : 1,
                            minimumStockQuantity: 0,
                            targetStockQuantity: 0,
                            articleCategoryId: vdh.value
                                ? data.data.weclapp?.articleCategoryId
                                : resolveArticleCategoryId(),
                            shopwareData: data.data.shopware
                        });
                        context.emit('environments-selected', {
                            selectedEnvironments: [platform?.value],
                            pairValues
                        });
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            state,
            v$,
            submitted,
            savingInProgress,
            showNextButton,
            handleProceedToAddingClick,
            loading,
            filteredProducts,
            searchProducts,
            platform
        };
    }
};
