import {computed, ref, toRefs, watch} from 'vue';
import {useVuelidate} from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {useToast} from 'vue-toastification';
import {getSingle} from '@/services/sales-orders';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import AddSerialNumber from '@/components/dialog/tickets/add-serial-number.vue';
import {i18n} from '@/utils/i18n';

import {getAll as getAllProducts} from '@/services/products';
import {getAll as getAllWarhouseStockMovements} from '@/services/warhouse-stock-movements';
import {FilterMatchMode} from 'primevue/api';
import {ekPriceCalculation, stripTagsAndTruncate} from '@/utils/helpers';

import LoadingPlugin from 'vue-loading-overlay';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        selectedOrderNumber: String,
        selectedWarehouseId: String,
        ticketDetails: Object,
        savingInProgress: Boolean
    },
    components: {
        'p-button': Button,
        InputNumber,
        AutoComplete,
        AddSerialNumber,
        LoadingPlugin
    },
    setup(props: any, context: any) {
        const {ticketDetails, savingInProgress} = toRefs(props);
        const orderNumber = ref(null);
        const selectedWarehouseId = ref(null);
        const submitted = ref(false);
        const filteredProducts = ref();
        const toast = useToast();
        const showAddSerialNumberDialog = ref(false);
        const clickedRowIndex = ref(null);
        const clickedRowArticleId = ref(null);
        const salesOrderItemIds = ref([]);
        const loading = ref(false);

        watch(props, async (args) => {
            if (
                args.selectedWarehouseId !== null &&
                args.selectedWarehouseId !== selectedWarehouseId.value
            ) {
                selectedWarehouseId.value = args.selectedWarehouseId;
            }

            if (args.selectedOrderNumber !== orderNumber.value) {
                orderNumber.value = args.selectedOrderNumber;
                state.value = Object.assign({}, formInitialState.value);
                if (!orderNumber.value) {
                    return;
                }
                loading.value = true;
                const order = await getSingle(orderNumber.value);

                if (
                    !order.data.orderItems ||
                    order.data.orderItems.length < 1
                ) {
                    loading.value = false;
                    return;
                }

                salesOrderItemIds.value = order.data.orderItems.map(
                    (item: any) => item.id
                );

                const warehouseStockMovements =
                    await getAllWarhouseStockMovements({
                        first: 0,
                        rows: 20,
                        filters: {
                            salesOrderItemId: {
                                value: salesOrderItemIds.value,
                                matchMode: FilterMatchMode.IN
                            }
                        }
                    });

                order.data.orderItems.forEach((item: any) => {
                    if (
                        !ticketDetails.value?.isReturnTicket &&
                        ticketDetails.value?.ticketArticle?.articleNumber &&
                        ticketDetails.value.ticketArticle.articleNumber !==
                            item.article?.articleNumber
                    ) {
                        return true;
                    }

                    const foundStockMovement =
                        warehouseStockMovements.data?.items?.find(
                            (warehouseStockMovement: any) =>
                                warehouseStockMovement.salesOrderItemId ===
                                item.id
                        );

                    state.value.retoureItems.push({
                        orderItem: item,
                        article: item.article,
                        articleNumber: item.article?.articleNumber,
                        shipmentQuantity: 0,
                        selectedSerialNumbers: [],
                        evaluationPrice: foundStockMovement
                            ? foundStockMovement.valuationPrice
                            : ekPriceCalculation(item.article, false),
                        serialNumberRequired:
                            item.article?.serialNumberRequired || false
                    });
                });

                loading.value = false;
            }
        });

        const searchProducts = (event: any) => {
            getAllProducts({
                first: 0,
                rows: 20,
                columns: [
                    'id',
                    'articleNumber',
                    'name',
                    'serialNumberRequired'
                ],
                filters: {
                    name: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    articleNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filterConjunction: 'or'
            })
                .then((data) => {
                    if (data.data?.items) {
                        filteredProducts.value = data.data.items.map(
                            (item: {
                                id: string;
                                articleNumber: string;
                                name: string;
                            }) => {
                                return {
                                    label:
                                        '(' +
                                        item.articleNumber +
                                        ') ' +
                                        item.name,
                                    value: item.articleNumber
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const openAddSerialNumberDialog = (rowIndex: number, item: any) => {
            clickedRowIndex.value = rowIndex;
            if (item.orderItem) {
                clickedRowArticleId.value = item.article.id;
            } else {
                clickedRowArticleId.value = null;
            }
            showAddSerialNumberDialog.value = true;
        };

        const formInitialState = computed(() => {
            return {
                retoureItems: orderNumber.value
                    ? []
                    : [
                          {
                              orderItem: null,
                              selectedSerialNumbers: [],
                              article: null,
                              articleNumber: null,
                              shipmentQuantity: null,
                              evaluationPrice: null,
                              serialNumberRequired: false
                          }
                      ]
            };
        });

        const state = ref({
            retoureItems: [
                {
                    orderItem: null,
                    selectedSerialNumbers: [],
                    article: null,
                    articleNumber: null,
                    shipmentQuantity: null,
                    evaluationPrice: null,
                    serialNumberRequired: false
                }
            ]
        });

        const rules = {
            retoureItems: {
                $each: helpers.forEach({
                    articleNumber: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    article: {
                        active: helpers.withMessage(
                            i18n.global.t('messages.productIsInactive'),
                            (value: any) => {
                                return !!value.active;
                            }
                        ),
                        availableInSale: helpers.withMessage(
                            i18n.global.t(
                                'messages.availableForSaleCheckboxNotSet'
                            ),
                            (value: any) => {
                                return !!value.availableInSale;
                            }
                        ),
                        showOnDeliveryNote: helpers.withMessage(
                            i18n.global.t(
                                'messages.showOnDeliveryNoteCheckboxNotSet'
                            ),
                            (value: any) => {
                                return !!value.showOnDeliveryNote;
                            }
                        )
                    },
                    shipmentQuantity: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        ),
                        greaterThanZero: helpers.withMessage(
                            i18n.global.t('messages.valueCannotBeZero'),
                            (value: any) => {
                                return value === null || value > 0;
                            }
                        )
                    },
                    evaluationPrice: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    }
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            if (
                !state.value.retoureItems ||
                state.value.retoureItems.length < 1
            ) {
                toast.error(
                    i18n.global.t('messages.pleaseAddAtLeastOneRetourePosition')
                );
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 2,
                formValues: {
                    items: state.value.retoureItems.map((retoureItem: any) => {
                        return {
                            orderItemId: retoureItem.orderItem?.id || null,
                            selectedSerialNumbers:
                                retoureItem.selectedSerialNumbers,
                            articleNumber:
                                retoureItem.articleNumber.value ||
                                retoureItem.articleNumber,
                            shipmentQuantity: retoureItem.shipmentQuantity,
                            evaluationPrice: retoureItem.evaluationPrice
                        };
                    })
                }
            });
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 1);
        };

        const addRetoureItem = () => {
            state.value.retoureItems.push({
                orderItem: null,
                article: null,
                selectedSerialNumbers: [],
                articleNumber: null,
                shipmentQuantity: null,
                evaluationPrice: null,
                serialNumberRequired: false
            });
        };
        const removeRetoureItem = (index: number) => {
            state.value.retoureItems.splice(index, 1);
        };

        const removeSerialNumberItem = (
            index: number,
            serialNumber: string
        ) => {
            const serialNumbers = state.value.retoureItems[
                index
            ].selectedSerialNumbers.filter(
                (item: any) => item !== serialNumber
            );

            state.value.retoureItems[index] = Object.assign(
                state.value.retoureItems[index],
                {
                    selectedSerialNumbers: serialNumbers,
                    shipmentQuantity: serialNumbers.length
                }
            );
        };

        const closeDialogListener = (event: any) => {
            showAddSerialNumberDialog.value = false;

            if (event.rowIndex === null) {
                return;
            }

            state.value.retoureItems[event.rowIndex] = Object.assign(
                state.value.retoureItems[event.rowIndex],
                {
                    selectedSerialNumbers: event.selectedItems.map(
                        (item: any) => item.serialNumber
                    ),
                    shipmentQuantity: event.selectedItems.length
                }
            );
        };

        const dropdownSelect = async (index: number, event: any) => {
            const product = await getAllProducts({
                first: 0,
                rows: 1,
                columns: [
                    'id',
                    'articleNumber',
                    'name',
                    'description',
                    'serialNumberRequired'
                ],
                filters: {
                    articleNumber: {
                        value: event?.value?.value || '',
                        matchMode: FilterMatchMode.EQUALS
                    }
                }
            });

            state.value.retoureItems[index] = Object.assign(
                state.value.retoureItems[index],
                {
                    serialNumberRequired:
                        product.data.items[0].serialNumberRequired,
                    article: product.data.items[0],
                    shipmentQuantity: 0
                }
            );
        };

        const addSerialNumberDialogFilters = computed(() => {
            return {
                salesOrderItemId: {
                    value: salesOrderItemIds.value,
                    matchMode: FilterMatchMode.IN
                },
                articleId: {
                    value: clickedRowArticleId.value,
                    matchMode: FilterMatchMode.EQUALS
                }
            };
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            onBackButtonClicked,
            orderNumber,
            addRetoureItem,
            removeRetoureItem,
            searchProducts,
            locale: i18n.global.locale,
            filteredProducts,
            showAddSerialNumberDialog,
            clickedRowIndex,
            clickedRowArticleId,
            openAddSerialNumberDialog,
            closeDialogListener,
            removeSerialNumberItem,
            dropdownSelect,
            stripTagsAndTruncate: stripTagsAndTruncate,
            savingInProgress,
            addSerialNumberDialogFilters,
            loading
        };
    }
};
