<div class="mb-5">
    <p-button severity="success" @click="onAddRMARequestButtonClick">
        + {{ $t("labels.ticket.rmaRequest") }}
    </p-button>
</div>
<Panel v-for="email in (ticketDetails?.archivedEmails || [])" class="mb-3">
    <template #header>
        <div class="font-bold">
            {{ email.fromAddress }} {{ $t("labels.at") }} {{ email.createdDate
            }}
        </div>
    </template>
    <div>
        <label>{{ $t('labels.mail.toAddress') }} </label>
        <span class="ml-3"> {{email.toAddresses?.join(';')}}</span>
    </div>
    <div v-if="email.ccAddresses && email.ccAddresses.length > 0">
        <label>{{ $t('labels.mail.ccAddress') }} </label>
        <span class="ml-3"> {{email.ccAddresses?.join(';')}}</span>
    </div>
    <div>
        <label>{{ $t('labels.mail.subject') }} </label>
        <span class="ml-3"> {{email.subject}}</span>
    </div>
    <div class="mt-2" v-html="email.body"></div>
</Panel>
<AddTicketEmailDialog
    v-if="ticketDetails"
    :display-dialog="showAddEmailDialog"
    :ticket-details="ticketDetails"
    :predefined-values="{subject: computedEmailSubject, fromAddress: loggedInUser, toAddress: ticketDetails?.email, body: computedEmailBody}"
    @close-dialog="closeAddTicketEmailDialog"
></AddTicketEmailDialog>
