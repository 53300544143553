import {onMounted, onUnmounted, ref, watch} from 'vue';
import {useVuelidate} from '@vuelidate/core';
import {helpers, maxLength, requiredIf} from '@vuelidate/validators';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import Checkbox from 'primevue/checkbox';
import Panel from 'primevue/panel';
import Dropdown from 'primevue/dropdown';
import {stripTagsAndTruncate} from '@/utils/helpers';
import Avatar from 'primevue/avatar';
import AutoComplete from 'primevue/autocomplete';
import eventBus from '@/event-bus/event-bus';

export default {
    emits: ['data-entered'],
    props: {
        shippingCarrierType: {
            type: String,
            default: null
        },
        toggleable: {
            type: Boolean,
            default: false
        },
        collapsed: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-dropdown': Dropdown,
        InputNumber,
        InputText,
        loading: LoadingPlugin,
        Panel,
        Avatar,
        AutoComplete
    },
    setup(props: any, context: any) {
        const shippingCarrierType = ref(props.shippingCarrierType);
        const toggleable = ref(props.toggleable);
        const collapsed = ref(props.collapsed);
        const submitted = ref(false);

        watch(
            () => props.toggleable,
            (val) => {
                toggleable.value = val;
            }
        );

        watch(
            () => props.collapsed,
            (val) => {
                collapsed.value = val;
            }
        );

        watch(
            () => props.shippingCarrierType,
            (val) => {
                shippingCarrierType.value = val;
            },
            {deep: true}
        );

        onMounted(() => {
            eventBus.on('trigger-external-carrier-form-submit', () => {
                handleSubmit(!v$.value.$invalid);
            });
        });

        onUnmounted(() => {
            eventBus.off('trigger-external-carrier-form-submit');
        });

        const state = ref({
            deliveryCosts: 0,
            upsAccountNumber: null,
            upsSignature: false,
            upsAccessPointId: null,
            upsShipmentDescription: 'Broadcast Equipment',
            upsPackageDescription: 'Broadcasttechnik'
        });

        const rules = {
            deliveryCosts: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return shippingCarrierType.value === 'ups';
                    })
                )
            },
            upsAccountNumber: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            state.value.deliveryCosts === 1 ||
                            state.value.deliveryCosts === 2
                        );
                    })
                )
            },
            upsSignature: {},
            upsAccessPointId: {},
            upsShipmentDescription: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return shippingCarrierType.value === 'ups';
                    })
                ),
                maxLength: helpers.withMessage(
                    i18n.global.t('messages.valueCantBeLongerThan', {
                        number: 50
                    }),
                    maxLength(50)
                )
            },
            upsPackageDescription: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return shippingCarrierType.value === 'ups';
                    })
                ),
                maxLength: helpers.withMessage(
                    i18n.global.t('messages.valueCantBeLongerThan', {
                        number: 35
                    }),
                    maxLength(35)
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit(
                'data-entered',
                shippingCarrierType.value === 'ups'
                    ? state.value
                    : {upsSignature: state.value.upsSignature}
            );
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            locale: i18n.global.locale,
            stripTagsAndTruncate: stripTagsAndTruncate,
            formatter: new Intl.NumberFormat(i18n.global.locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            shippingCarrierType,
            toggleable,
            collapsed
        };
    }
};
