import Fieldset from 'primevue/fieldset';
import {computed, ref, toRefs} from 'vue';
import {getReferenceItemLayout} from '@/services/tickets';
import AddTicketCommentDialog from '@/components/dialog/tickets/add-ticket-comment.vue';
import BookTicketShipment from '@/components/dialog/tickets/book-ticket-shipment.vue';
import SendDocumentToRemotePrinter from '@/components/dialog/documents/send-document-to-remote-printer.vue';
import store from '@/store';
import {i18n} from '@/utils/i18n';

export default {
    emits: ['reload-ticket-details'],
    components: {
        'p-fieldset': Fieldset,
        AddTicketCommentDialog,
        BookTicketShipment,
        SendDocumentToRemotePrinter
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);
        const showAddCommentDialog = ref(false);
        const showBookTicketShipmentDialog = ref(false);
        const displaySendToRemotePrinterDialog = ref(false);
        const selectedShipment = ref(null);
        const commentDefaultValue = ref(null);
        const selectedDocument = ref(null);

        const isRepairTicket = computed(
            () => ticketDetails.value?.isRepairTicket || false
        );

        const isReturnTicket = computed(
            () => ticketDetails.value?.isReturnTicket || false
        );

        const showAddNewRetoureButton = computed(() => {
            return (
                ticketDetails.value &&
                ticketDetails.value.assignedUser &&
                (isRepairTicket.value || isReturnTicket.value)
            );
        });

        const closeAddTicketCommentDialog = (event: any) => {
            showAddCommentDialog.value = false;
            if (event) {
                context.emit('reload-ticket-details');
            }
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('weclapp-tickets-edit') !== -1;
        });

        const onCreateCompensationShipmentClick = async (shipment: any) => {
            selectedShipment.value = shipment;
            showBookTicketShipmentDialog.value = true;
        };

        const closeBookTicketShipmentDialog = (event: any) => {
            showBookTicketShipmentDialog.value = false;

            if (event?.needsRefresh) {
                context.emit('reload-ticket-details');
            }

            if (event?.success && isRepairTicket.value) {
                commentDefaultValue.value = i18n.global.t(
                    'labels.ticket.templates.comments.customerCompensationCreated',
                    {
                        ticketOwner:
                            ticketDetails.value?.customer?.company ||
                            ticketDetails.value?.customer?.firstName +
                                ' ' +
                                ticketDetails.value?.customer?.lastName,
                        ticketType: 'RMA',
                        ticketNumber: ticketDetails.value?.ticketNumber
                    }
                );
                showAddCommentDialog.value = true;
            }
        };

        const closePrintDialogListener = () => {
            displaySendToRemotePrinterDialog.value = false;
        };

        const printDocument = (document: any) => {
            selectedDocument.value = document;
            displaySendToRemotePrinterDialog.value = true;
        };

        return {
            ticketDetails,
            getReferenceItemLayout: getReferenceItemLayout,
            closeAddTicketCommentDialog,
            onCreateCompensationShipmentClick,
            showAddCommentDialog,
            showBookTicketShipmentDialog,
            closeBookTicketShipmentDialog,
            isReturnTicket,
            showAddNewRetoureButton,
            selectedShipment,
            commentDefaultValue,
            editPermissionAvailable,
            displaySendToRemotePrinterDialog,
            closePrintDialogListener,
            printDocument,
            selectedDocument
        };
    }
};
