<PrimeDialog
    :header="$t('labels.shipment.shippingCarrierAdditionalData', {carrierName: (mainShipment?.shippingCarrierType || 'ups').toUpperCase()})"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '960px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleWeightSubmit">
        <div class="w-full">
            <ExternalCarrierForm
                :shipping-carrier-type="mainShipment?.shippingCarrierType"
                @data-entered="onExternalCarrierDataEntered"
            >
            </ExternalCarrierForm>
        </div>
        <div class="w-full">
            <PackageDimensionsForm
                :shipment="mainShipment"
                :hasDangerousGoods="showUpsDangerousGoodsButton"
                @data-entered="onPackageDimensionsEntered"
            ></PackageDimensionsForm>
        </div>
        <div class="flex justify-content-between mt-4">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>

            <p-button severity="success" type="submit">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
