import {computed, ref, toRefs, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import {useToast} from 'vue-toastification';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';
import {bulkProcessBadges, bulkProcessCategories} from '@/services/products';
import {i18n} from '@/utils/i18n';
import BulkProductAction from './bulk-product-action.vue';
import Panel from 'primevue/panel';
import BadgesList from '@/components/grid/product-badges/list.vue';
import CategoriesTree from '@/components/grid/product-categories/tree.vue';

export default {
    emits: ['close-dialog'],
    components: {
        TabPanel,
        ProductList,
        LoadingPlugin,
        DataTable,
        Column,
        'p-checkbox': Checkbox,
        BulkProductAction,
        Panel,
        BadgesList,
        CategoriesTree
    },
    props: {
        products: Array,
        productFilters: Object,
        productTotalRecords: Number,
        displayDialog: Boolean,
        isRemove: Boolean,
        isFilterRelated: Boolean
    },
    setup(props: any, context: any) {
        const {products, productFilters, productTotalRecords} = toRefs(props);
        const showDialog = ref(false);
        const isRemove = ref(false);
        const isFilterRelated = ref(false);
        const selectedBadges = ref([]);
        const loading = ref(false);

        const toast = useToast();

        const selectedCategories = ref(null);

        const selectedCategoriesLabels = ref([]);

        const categories = ref([]);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            isRemove.value = args.isRemove || false;
            isFilterRelated.value = args.isFilterRelated || false;
        });

        const closeResponsive = (forceResfresh: boolean = false) => {
            selectedBadges.value.length = 0;
            selectedCategories.value = null;
            selectedCategoriesLabels.value = [];
            context.emit('close-dialog', forceResfresh);
        };

        const summaryTabVisible = computed(() => {
            return (
                (selectedBadges.value && selectedBadges.value.length > 0) ||
                (selectedCategories.value &&
                    Object.keys(selectedCategories.value).length > 0)
            );
        });

        const onBadgesSelectionChange = (input: any) => {
            selectedBadges.value = input || [];
        };

        const onCategoriesSelectionChange = (categories: any, labels: any) => {
            selectedCategories.value = categories || {};
            selectedCategoriesLabels.value = labels || [];
        };

        const confirmClicked = (startTime: string | null = null) => {
            loading.value = true;

            const resolvedPromisesArray: Array<any> = [];

            if (selectedBadges.value && selectedBadges.value.length > 0) {
                resolvedPromisesArray.push(
                    Promise.resolve(
                        bulkProcessBadges(
                            products.value,
                            productFilters.value,
                            selectedBadges.value,
                            isRemove.value,
                            isFilterRelated.value,
                            startTime
                        )
                    )
                );
            }

            if (
                selectedCategories.value &&
                Object.keys(selectedCategories.value).length > 0
            ) {
                resolvedPromisesArray.push(
                    Promise.resolve(
                        bulkProcessCategories(
                            products.value,
                            productFilters.value,
                            selectedCategories.value
                                ? Object.keys(selectedCategories.value)
                                : [],
                            isRemove.value,
                            isFilterRelated.value,
                            startTime
                        )
                    )
                );
            }
            if (resolvedPromisesArray.length < 1) {
                toast.warning(i18n.global.t('messages.noChangesDetected'));
                return;
            }
            loading.value = true;
            Promise.allSettled(resolvedPromisesArray)
                .then((res: any) => {
                    if (resolvedPromisesArray.length === 2) {
                        if (res[0].status === 'fulfilled') {
                            toast.success(
                                i18n.global.t('labels.badges') +
                                    ': ' +
                                    i18n.global.t(
                                        isFilterRelated.value || startTime
                                            ? 'messages.cronJobExecutionScheduled'
                                            : 'messages.changesSavedSuccessfully'
                                    )
                            );
                        } else {
                            toast.error(
                                i18n.global.t('labels.badges') +
                                    ': ' +
                                    (res[0].reason?.error ||
                                        res[0].reason?.message)
                            );
                        }

                        if (res[1].status === 'fulfilled') {
                            toast.success(
                                i18n.global.t('labels.categories') +
                                    ': ' +
                                    i18n.global.t(
                                        isFilterRelated.value || startTime
                                            ? 'messages.cronJobExecutionScheduled'
                                            : 'messages.changesSavedSuccessfully'
                                    )
                            );
                        } else {
                            toast.error(
                                i18n.global.t('labels.categories') +
                                    ': ' +
                                    (res[1].reason?.error ||
                                        res[1].reason?.message)
                            );
                        }
                    } else {
                        if (res[0].status === 'fulfilled') {
                            toast.success(
                                i18n.global.t(
                                    selectedBadges.value &&
                                        selectedBadges.value.length > 0
                                        ? 'labels.badges'
                                        : 'labels.categories'
                                ) +
                                    ': ' +
                                    i18n.global.t(
                                        isFilterRelated.value || startTime
                                            ? 'messages.cronJobExecutionScheduled'
                                            : 'messages.changesSavedSuccessfully'
                                    )
                            );
                        } else {
                            toast.error(
                                i18n.global.t(
                                    selectedBadges.value &&
                                        selectedBadges.value.length > 0
                                        ? 'labels.badges'
                                        : 'labels.categories'
                                ) +
                                    ': ' +
                                    (res[0].reason?.error ||
                                        res[0].reason?.message)
                            );
                        }
                    }
                    closeResponsive(true);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            products,
            productTotalRecords,
            isFilterRelated,
            showDialog,
            loading,
            selectedBadges,
            closeResponsive,
            confirmClicked,
            summaryTabVisible,
            onBadgesSelectionChange,
            categories,
            selectedCategories,
            selectedCategoriesLabels,
            onCategoriesSelectionChange,
            isRemove
        };
    }
};
