import {computed, onMounted, ref, watch} from 'vue';
import {i18n} from '@/utils/i18n';
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import countries from 'i18n-iso-countries';
import {helpers, required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {editSingleOrder, getAllSalutations} from '@/services/shopware';
import store from '@/store';
import {useRoute} from 'vue-router';
import {useConfirm} from 'primevue/useconfirm';
import {useToast} from 'vue-toastification';
import LoadingPlugin from 'vue-loading-overlay';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import InputSwitch from 'primevue/inputswitch';
import OverlayPanel from 'primevue/overlaypanel';
import {checkVatNumber} from '@/services/vies';
import {euStates} from '@/utils/helpers';

export default {
    emits: ['shop-data-saved', 'shopware-base-data-computed'],
    components: {
        Panel,
        InputText,
        InputSwitch,
        'p-button': Button,
        'p-dropdown': Dropdown,
        loading: LoadingPlugin,
        Tag,
        OverlayPanel
    },
    props: {
        shopData: Object,
        weclappFormBaseData: Object
    },
    setup(props: any, context: any) {
        const shopData = ref(null);
        const weclappFormBaseData = ref(null);

        const isEdit = ref(false);
        const submitted = ref(false);
        const savingInProgress = ref(false);
        const selectedAddressType = ref(null);

        const route = useRoute();
        const confirm = useConfirm();
        const toast = useToast();

        const op = ref();
        const vatNumberData = ref(null);
        const salutationOptions = ref([]);

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) =>
                    item.value ===
                    (route.query?.platform || store.getters['auth/platform'])
            );
        });

        onMounted(() => {
            getAllSalutations(
                route.query?.platform || store.getters['auth/platform']
            )
                .then((data: any) => {
                    salutationOptions.value = data.data || [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        });

        const state = ref({
            email: null,
            salutation: null,
            firstName: null,
            lastName: null,
            vatNumber: null,
            company: null,
            department: null,
            street: null,
            street2: null,
            zipcode: null,
            city: null,
            country: null,
            phoneNumber: null,
            saveDataInCustomer: true
        });

        const rules = {
            email: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            salutation: {},
            firstName: {},
            lastName: {},
            vatNumber: {},
            company: {},
            department: {},
            street: {},
            street2: {},
            zipcode: {},
            city: {},
            country: {},
            phoneNumber: {},
            saveDataInCustomer: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            if (args.shopData?.number !== shopData.value?.number) {
                shopData.value = args.shopData;
                selectedAddressType.value = 'billing';

                resetForm();
            }

            if (args.weclappFormBaseData) {
                weclappFormBaseData.value = args.weclappFormBaseData;
            }
        });

        const resolveCountryFromCode = (countryCode: string) => {
            return (
                countries.getName(countryCode, i18n.global.locale) ||
                countries.getName(countryCode, 'en')
            );
        };

        const selectedShopAddress = computed(() => {
            switch (selectedAddressType.value) {
                case 'billing':
                    return shopData.value?.billingAddress;
                case 'shipping':
                    return shopData.value?.shippingAddress;
                default:
                    return null;
            }
        });

        const resolveSelectShopAddressPropertyValue = (
            propertyName: string
        ) => {
            switch (selectedAddressType.value) {
                case 'billing':
                    return shopData.value?.billingAddress?.[propertyName];
                case 'shipping':
                    return shopData.value?.shippingAddress?.[propertyName];
                default:
                    return null;
            }
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            if (!v$.value.$anyDirty) {
                isEdit.value = false;
                return;
            }

            confirm.require({
                message: i18n.global.t('messages.saveChangesConfirmation'),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    const payload = Object.keys(state.value)
                        .filter(function (stateKey: string) {
                            return (
                                stateKey === 'saveDataInCustomer' ||
                                (v$.value[stateKey] &&
                                    v$.value[stateKey].$dirty)
                            );
                        })
                        .reduce(function (obj2: any, key: string) {
                            obj2[key as keyof typeof obj2] =
                                state.value[key as keyof typeof state.value];
                            return obj2;
                        }, {});
                    savingInProgress.value = true;
                    editSingleOrder(
                        shopData.value?.number,
                        {...payload, addressType: selectedAddressType.value},
                        resolvedPlatform.value?.value
                    )
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            context.emit('shop-data-saved');
                            shopData.value = Object.assign(shopData.value, {
                                number: null
                            });
                            isEdit.value = false;
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            savingInProgress.value = false;
                        });
                }
            });
        };

        const formBaseData = computed(() => {
            const formData = {
                email: shopData.value.customer?.email,
                salutation: selectedShopAddress.value?.salutation
                    ? salutationOptions.value.find(
                          (item) =>
                              item.displayName ===
                              selectedShopAddress.value?.salutation
                      )?.id
                    : null,
                firstName: selectedShopAddress.value?.firstName,
                lastName: selectedShopAddress.value?.lastName,
                vatNumber: shopData.value.customer?.vatNumber,
                company: selectedShopAddress.value?.company,
                department: selectedShopAddress.value?.department,
                street: selectedShopAddress.value?.street,
                street2: selectedShopAddress.value?.additionalAddressLine1,
                zipcode: selectedShopAddress.value?.zipcode,
                city: selectedShopAddress.value?.city,
                country: selectedShopAddress.value?.country,
                phoneNumber: selectedShopAddress.value?.phoneNumber,
                saveDataInCustomer: true
            };
            context.emit('shopware-base-data-computed', {
                ...formData,
                salutation: selectedShopAddress.value?.salutation
            });
            return formData;
        });

        const resetForm = () => {
            state.value = Object.assign({}, formBaseData.value);
            isEdit.value = false;
        };

        const checkVatNumberCountryResolved = computed(() => {
            if (!shopData.value?.customer?.vatNumber) {
                return null;
            }

            const countries = euStates
                .filter((state: string) => {
                    return state !== 'DE' && state !== 'GR';
                })
                .concat(['EL', 'XI']);

            const resolved = countries.find((country: string) =>
                shopData.value.customer.vatNumber.startsWith(country)
            );

            return resolved || null;
        });

        const checkVatNumberVatResolved = computed(() => {
            if (!checkVatNumberCountryResolved.value) {
                return null;
            }

            return shopData.value.customer.vatNumber.substring(
                checkVatNumberCountryResolved.value.length
            );
        });

        return {
            shopData,
            weclappFormBaseData,
            isEdit,
            resolveCountryFromCode,
            checkVatNumberCountryResolved,
            checkVatNumberVatResolved,
            state,
            v$,
            handleSubmit,
            submitted,
            resetForm,
            savingInProgress,
            selectedAddressType,
            selectedShopAddress,
            vatNumberData,
            resolveSelectShopAddressPropertyValue,
            showCopyFieldIcon: (
                term1: string | null,
                term2: string | null
            ): boolean => {
                if ((term1 || '').trim() === '') {
                    return false;
                }
                return term1.trim() !== (term2 || '').trim();
            },
            op,
            toggleOverlay: (event: any) => {
                if (!vatNumberData.value && checkVatNumberVatResolved.value) {
                    checkVatNumber(
                        checkVatNumberCountryResolved.value,
                        checkVatNumberVatResolved.value
                    )
                        .then((vatData: any) => {
                            vatNumberData.value = vatData.data;
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        });
                }
                op.value.toggle(event);
            },
            copyToClipboard: (data: any, event: any) => {
                navigator.clipboard.writeText(data);
                event.stopPropagation();
                toast.success(i18n.global.t('labels.copied'), {
                    timeout: 500
                });
            },
            salutationOptions
        };
    }
};
