import Fieldset from 'primevue/fieldset';
import {ref, watch} from 'vue';
import {getReferenceItemLayout} from '@/services/tickets';
import PaidRepair from './paid-repair/paid-repair.vue';

export default {
    emits: ['reload-ticket-details'],
    components: {
        'p-fieldset': Fieldset,
        PaidRepair
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const ticketDetails = ref(props.ticketDetails);

        watch(
            () => props.ticketDetails,
            (newValue) => {
                if (newValue) {
                    ticketDetails.value = newValue;
                }
            }
        );

        const handleReloadTicketEvent = () => {
            context.emit('reload-ticket-details');
        };

        return {
            ticketDetails,
            handleReloadTicketEvent,
            getReferenceItemLayout: getReferenceItemLayout
        };
    }
};
