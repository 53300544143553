import {computed, onMounted, ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {i18n} from '@/utils/i18n';
import countries from 'i18n-iso-countries';
import Divider from 'primevue/divider';
import InputSwitch from 'primevue/inputswitch';
import {getAll} from '@/services/metadata';
import {useToast} from 'vue-toastification';
import Dropdown from 'primevue/dropdown';

export default {
    emits: ['choice-confirmed'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-divider': Divider,
        'p-dropdown': Dropdown,
        InputSwitch
    },
    props: {
        displayConfirmationDialog: Boolean,
        shopData: Object,
        selectedWeclappCustomer: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const shopData = ref(null);
        const selectedWeclappCustomer = ref(null);
        const skipSavingContactDataInBillingAddress = ref(false);
        const skipSavingContactDataInShippingAddress = ref(false);

        const sectorOptions = ref([]);
        const selectedSectorId = ref(null);
        const toast = useToast();

        onMounted(() => {
            getAll(['sector'], false)
                .then((data: any) => {
                    sectorOptions.value = data.data?.sector || [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        });

        watch(props, (args) => {
            showDialog.value = args.displayConfirmationDialog;
            shopData.value = args.shopData;
            selectedWeclappCustomer.value = args.selectedWeclappCustomer;
        });

        const handleAnswer = (confirmed: boolean) => {
            showDialog.value = false;
            context.emit(
                'choice-confirmed',
                confirmed,
                confirmed
                    ? {
                          skipSavingContactDataInBillingAddress:
                              skipSavingContactDataInBillingAddress.value,
                          skipSavingContactDataInShippingAddress:
                              skipSavingContactDataInShippingAddress.value,
                          selectedSectorId: selectedSectorId.value
                      }
                    : null
            );
        };

        const resolveCountryFromCode = (countryCode: string) => {
            return (
                countries.getName(countryCode, i18n.global.locale) ||
                countries.getName(countryCode, 'en')
            );
        };

        const disableConfirmButton = computed(() => {
            return !selectedWeclappCustomer.value && !selectedSectorId.value;
        });

        return {
            showDialog,
            shopData,
            handleAnswer,
            resolveCountryFromCode,
            skipSavingContactDataInBillingAddress,
            skipSavingContactDataInShippingAddress,
            disableConfirmButton,
            selectedWeclappCustomer,
            sectorOptions,
            selectedSectorId
        };
    }
};
