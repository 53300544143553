<loading v-model:active="savingInProgress" />

<TabView v-model:activeIndex="activeTabIndex">
    <TabPanel
        :header="$t('labels.ticket.retoure.details')"
        :disabled="!step1Enabled"
    >
        <GeneralStep
            :ticket-details="ticketDetails"
            @back-button-clicked="onBackButtonClicked"
            @next-button-clicked="onNextButtonClicked"
        ></GeneralStep>
    </TabPanel>
    <TabPanel
        :header="$t('labels.ticket.retoure.items.items')"
        :disabled="!step2Enabled"
    >
        <BookedInWarehouseStep
            :selected-order-number="selectedOrderNumber"
            :selected-warehouse-id="selectedWarehouseId"
            :ticket-details="ticketDetails"
            :savingInProgress="savingInProgress"
            @back-button-clicked="onBackButtonClicked"
            @next-button-clicked="onNextButtonClicked"
        >
        </BookedInWarehouseStep>
    </TabPanel>
</TabView>
