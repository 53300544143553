import {computed, onMounted, ref, watch} from 'vue';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';
import {getAll} from '@/services/product-badges';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Checkbox from 'primevue/checkbox';

import store from '@/store';

export default {
    emits: ['selection-complete'],
    components: {
        LoadingPlugin,
        DataTable,
        Column,
        'p-checkbox': Checkbox
    },
    props: {},
    setup(props: any, context: any) {
        const selectedBadges = ref([]);
        const loading = ref(false);

        const toast = useToast();

        const badges = ref([]);

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        watch(selectedBadges, () => {
            context.emit('selection-complete', selectedBadges.value);
        });

        onMounted(() => {
            loadBadges();
        });

        const loadBadges = () => {
            loading.value = true;
            getAll()
                .then((data) => {
                    badges.value = data.data.sort((a: any, b: any) => {
                        if (
                            ['vdd', 'vdl'].indexOf(
                                importPlatform.value?.threeLetterId
                            ) === -1
                        ) {
                            return a.name.localeCompare(b.name);
                        }
                        if (
                            a.name.indexOf('€') !== -1 &&
                            b.name.indexOf('€') === -1
                        )
                            return 1;

                        if (
                            a.name.indexOf('€') === -1 &&
                            b.name.indexOf('€') !== -1
                        )
                            return -1;
                        if (
                            a.name.indexOf('€') !== -1 &&
                            b.name.indexOf('€') !== -1 &&
                            parseInt(a.name.match(/\d/g).join(''), 10) !==
                                parseInt(b.name.match(/\d/g).join(''), 10)
                        ) {
                            return parseInt(a.name.match(/\d/g).join(''), 10) >
                                parseInt(b.name.match(/\d/g).join(''), 10)
                                ? 1
                                : -1;
                        }

                        return a.name.localeCompare(b.name);
                    });
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        return {
            badges,
            loading,
            selectedBadges
        };
    }
};
