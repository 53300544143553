import {computed, onMounted, ref} from 'vue';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import {i18n} from '@/utils/i18n';
import store from '@/store';
import {getAll as getAllProducts} from '@/services/products';
import {
    ekPriceCalculation,
    stripTagsAndTruncate,
    uvpPriceCalculation
} from '@/utils/helpers';
import InputText from 'primevue/inputtext';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {FilterMatchMode} from 'primevue/api';
import Button from 'primevue/button';
import {getAll} from '@/services/metadata';
import MultiSelect from 'primevue/multiselect';
import {helpers, requiredIf} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import ScrollPanel from 'primevue/scrollpanel';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import {updateSupplySource} from '@/services/supply-sources';

export default {
    components: {
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-dialog': Dialog,
        'p-button': Button,
        'p-multiselect': MultiSelect,
        'p-dropdown': Dropdown,
        'p-checkbox': Checkbox,
        Panel,
        InputText,
        InputIcon,
        IconField,
        TriStateCheckbox,
        ScrollPanel
    },
    setup() {
        const loading = ref(false);
        const toast = useToast();
        const singleEditInProgress = ref(null);
        const manufacturerOptions = ref([]);
        const allSuppliers = ref([]);
        const submitted = ref(false);

        const totalRecords = ref(0);
        const products = ref([]);

        const lazyParams: any = ref({});

        const editingRows = ref([]);

        const dt = ref();

        onMounted(() => {
            lazyParams.value = {
                first: 0,
                rows: 1000,
                sortField: 'articleNumber',
                sortOrder: 1,
                columns: [
                    'articleNumber',
                    'name',
                    'manufacturerName',
                    'sku',
                    'supplierNumber',
                    'supplier',
                    'primarySupplySourceId'
                ],
                filters: {}
            };

            getAll(['manufacturer'], false)
                .then((data: any) => {
                    manufacturerOptions.value = data.data?.manufacturer || [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });

            getAll(['supplier'], false).then((data: any) => {
                allSuppliers.value = (data.data['supplier'] || [])
                    .map(
                        (item: {
                            name: string;
                            supplierNumber: string;
                            company2: string;
                        }) => {
                            return {
                                label:
                                    stripTagsAndTruncate(
                                        [item.name, item.company2].join(' '),
                                        150,
                                        '...'
                                    ) +
                                    ' (' +
                                    item.supplierNumber +
                                    ') ',
                                value: item.supplierNumber,
                                name: item.name
                            };
                        }
                    )
                    .sort((a: any, b: any) => {
                        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }

                        return nameA > nameB ? 1 : 0;
                    });
            });
        });

        const state = ref({
            articleNumber: null,
            supplier: null,
            manufacturer: null,
            eol: null
        });

        const rules = {
            articleNumber: {
                required: helpers.withMessage(
                    i18n.global.t('messages.skuOrSupplierMustBeSet'),
                    requiredIf(() => {
                        return state.value.supplier === null;
                    })
                )
            },
            supplier: {
                required: helpers.withMessage(
                    i18n.global.t('messages.skuOrSupplierMustBeSet'),
                    requiredIf(() => {
                        return state.value.articleNumber === null;
                    })
                )
            },
            manufacturer: {},
            eol: {}
        };

        const v$ = useVuelidate(rules, state);

        const handleSearch = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                toast.error(v$.value.$silentErrors[0].$message);
                return;
            }
            products.value.length = 0;

            const hasComma = (state.value.articleNumber || '').includes(',');

            lazyParams.value.filters = {
                sku: {
                    value: hasComma
                        ? state.value.articleNumber
                              .split(',')
                              .map((item: string) => item.trim())
                        : state.value?.articleNumber,
                    matchMode: hasComma
                        ? FilterMatchMode.IN
                        : FilterMatchMode.CONTAINS
                },
                manufacturerId: {
                    value: state.value?.manufacturer,
                    matchMode: FilterMatchMode.IN
                },
                supplierNumber: {
                    value: state.value?.supplier,
                    matchMode: FilterMatchMode.EQUALS
                },
                eolTag: {
                    value: state.value?.eol,
                    matchMode: FilterMatchMode.EQUALS
                }
            };
            loadLazyData();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const loadLazyData = () => {
            loading.value = true;
            getAllProducts(lazyParams.value, true)
                .then((data) => {
                    totalRecords.value = Math.min(data.data.total, 1000);
                    if (data.data.total > 1000) {
                        toast.warning(
                            'Mehr als 1000 Ergebnisse - erwägen Sie die Verwendung spezifischerer Suchbegriffe'
                        );
                    }
                    products.value = (data.data.items || []).map(
                        (item: any) => {
                            let supplySource = (
                                item.articleSupplySources || []
                            ).find(
                                (ss: any) =>
                                    ss.id === item.primarySupplySourceId
                            );

                            if (
                                !supplySource &&
                                (item.articleSupplySources || []).length === 1
                            ) {
                                supplySource = item.articleSupplySources[0];
                            }

                            return {
                                ...item,
                                articleNumberNameCombined:
                                    item.articleNumber + item.name,
                                supplySourceId: supplySource?.id,
                                supplySourceArticleNumber:
                                    supplySource.articleNumber,
                                supplySourceName: supplySource.name,
                                supplierId: supplySource?.supplier?.id,
                                supplierNumber:
                                    supplySource?.supplier?.supplierNumber,
                                supplierName: supplySource?.supplier?.name,
                                supplierNumberNameCombined:
                                    supplySource?.supplier?.supplierNumber +
                                    supplySource?.supplier?.name,
                                eolTag:
                                    (item.systemTags || []).indexOf(
                                        'TTC-PRODUCT-PRICE-IMPORT-EOL'
                                    ) !== -1
                            };
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const filters = ref({
            articleNumberNameCombined: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            supplySourceName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            manufacturerName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            supplySourceArticleNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            supplierNumberNameCombined: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            eolTag: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        });

        const onRowEditComplete = (event: {data: any; newData: any}) => {
            const {
                supplySourceArticleNumber,
                supplySourceId,
                supplySourceName
            } = event.newData;

            if (
                supplySourceArticleNumber ===
                    event.data?.supplySourceArticleNumber &&
                supplySourceName === event.data?.supplySourceName
            ) {
                toast.warning(i18n.global.t('messages.noChangesDetected'));
                return;
            }
            loading.value = true;
            updateSupplySource(supplySourceId, {
                articleNumber: supplySourceArticleNumber,
                name: supplySourceName
            })
                .then(() => {
                    loadLazyData();
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    loading.value = false;
                });
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        return {
            v$,
            loading,
            dt,
            onRowEditComplete,
            locale: i18n.global.locale,
            ekPriceCalculation,
            handleSearch,
            singleEditInProgress,
            filters,
            submitted,
            manufacturerOptions,
            allSuppliers,
            uvpPriceCalculation,
            loadLazyData,
            totalRecords,
            products,
            matchModesNumeric,
            stripTagsAndTruncate,
            resolvedPlatform,
            editingRows
        };
    }
};
