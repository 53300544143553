import {computed, ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {getAll} from '@/services/shipments';
import PackageDimensionsForm from '@/components/shipment/package-dimensions/package-dimensions-form.vue';
import ExternalCarrierForm from '@/components/shipment/external-carrier-data/external-carrier-form.vue';
import {FilterMatchMode} from 'primevue/api';
import eventBus from '@/event-bus/event-bus';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        loading: LoadingPlugin,
        PackageDimensionsForm,
        ExternalCarrierForm
    },
    props: {
        shipment: Object,
        groupedShipments: Array,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const groupedShipments = ref([]);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);

        const state = ref({
            externalCarrierData: null,
            packageItems: []
        });

        const rules = {
            externalCarrierData: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            packageItems: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    (value: any) => {
                        return (value || []).length > 0;
                    }
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            if (!showDialog.value) {
                return;
            }
            loadShipmentDetails(
                [args.shipment || null]
                    .concat(args.groupedShipments || [])
                    .filter((it: any) => it)
                    .map((it: any) => it.shipmentNumber)
            );
        });

        const loadShipmentDetails = async (shipmentNumbers: Array<string>) => {
            getAll({
                rows: 1000,
                columns: ['shipmentItems'],
                filters: Object.assign({
                    shipmentNumber: {
                        value: shipmentNumbers,
                        matchMode: FilterMatchMode.IN
                    }
                })
            })
                .then((data: any) => {
                    groupedShipments.value = data.data?.items || [];
                    if (groupedShipments.value.length > 1) {
                        setTimeout(() => {
                            eventBus.emit(
                                'group-shipment-toggled',
                                groupedShipments.value.filter(
                                    (item: any) =>
                                        item.shipmentNumber !==
                                        mainShipment.value.shipmentNumber
                                )
                            );
                        }, 100);
                    }
                })
                .catch((error: any) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                externalCarrierData: null,
                packageItems: []
            };
            context.emit('close-dialog');
        };

        const handleSubmit = async () => {
            submitted.value = true;

            const isFormCorrect = await v$.value.$validate();
            if (!isFormCorrect) {
                return;
            }

            context.emit(
                'close-dialog',
                Object.assign(
                    {
                        workflow: 'announce-at-shipping-carrier',
                        groupedShipments: groupedShipments.value
                            .filter(
                                (item: any) =>
                                    item.shipmentNumber !==
                                    mainShipment.value.shipmentNumber
                            )
                            .map((item: any) => item.shipmentNumber)
                    },
                    state.value
                )
            );
            submitted.value = false;
            state.value = {
                externalCarrierData: null,
                packageItems: []
            };
            savingInProgress.value = false;
            showDialog.value = false;
        };

        const mainShipment = computed(() => {
            if (groupedShipments.value && groupedShipments.value.length > 0) {
                return groupedShipments.value[0];
            }

            return null;
        });

        const groupedShipmentItems = computed(() => {
            let result: Array<any> = [];
            let counter = 0;

            (groupedShipments.value || []).forEach((groupedShipment: any) => {
                (groupedShipment?.shipmentItems || []).forEach(
                    (shipmentItem: any) => {
                        shipmentItem.shipmentNumber =
                            groupedShipment.shipmentNumber;
                        shipmentItem.positionNumber = (++counter).toString();
                        result = result.concat(shipmentItem);
                    }
                );
            });

            return result;
        });

        const showUpsDangerousGoodsButton = computed(() => {
            return (
                mainShipment.value?.shippingCarrierType === 'ups' &&
                [
                    'Wiesbaden',
                    'Hamburg',
                    'Berlin',
                    'Hamburg Obenhaupt',
                    'VDH'
                ].includes(mainShipment.value?.warehouse?.name) &&
                groupedShipmentItems.value.some((item): any => {
                    return item.article_flag_akku_groesser_100wh;
                })
            );
        });

        return {
            showDialog,
            onCancelClick,
            state,
            v$,
            submitted,
            savingInProgress,
            locale: i18n.global.locale,
            mainShipment,
            showUpsDangerousGoodsButton,
            onPackageDimensionsEntered: (data: any) => {
                state.value.packageItems = [...(data || [])];
            },
            onExternalCarrierDataEntered: (data: any) => {
                state.value.externalCarrierData = {...data};
            },
            handleWeightSubmit: () => {
                eventBus.emit('trigger-shipment-packages-form-submit');
                eventBus.emit('trigger-external-carrier-form-submit');
                setTimeout(() => {
                    handleSubmit();
                }, 100);
            }
        };
    }
};
