import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16edd3b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "text-gray-600"
}
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { style: {"text-align":"center"} }

export function render(_ctx, _cache) {
  const _component_BadgesList = _resolveComponent("BadgesList")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_CategoriesTree = _resolveComponent("CategoriesTree")
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BulkProductAction = _resolveComponent("BulkProductAction")

  return (_openBlock(), _createBlock(_component_BulkProductAction, {
    onCloseDialog: _ctx.closeResponsive,
    onConfirmClicked: _ctx.confirmClicked,
    products: _ctx.products,
    productTotalRecords: _ctx.productTotalRecords,
    displayDialog: _ctx.showDialog,
    isFilterRelated: _ctx.isFilterRelated,
    summaryTabVisible: _ctx.summaryTabVisible,
    loading: _ctx.loading,
    dialogHeader: _ctx.isRemove ? _ctx.$t('labels.removeBadgesAndOrCategories') : _ctx.$t('labels.addBadgesAndOrCategories'),
    canPostponeExecution: ""
  }, {
    "tab-panel-middle": _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.badges')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BadgesList, { onSelectionComplete: _ctx.onBadgesSelectionChange }, null, 8, ["onSelectionComplete"])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.categories')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CategoriesTree, {
            "is-remove": _ctx.isRemove,
            onSelectionComplete: _ctx.onCategoriesSelectionChange
          }, null, 8, ["is-remove", "onSelectionComplete"])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-header": _withCtx(() => [
      (_ctx.selectedBadges && _ctx.selectedBadges.length > 0)
        ? (_openBlock(), _createBlock(_component_Panel, {
            key: 0,
            class: "mb-3"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('labels.badges')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                class: "p-datatable-sm text-sm",
                stripedRows: "",
                paginator: _ctx.selectedBadges && _ctx.selectedBadges.length > 20,
                rows: 20,
                value: _ctx.selectedBadges,
                responsiveLayout: "scroll",
                "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                "rows-per-page-options": [10,20,50,100,200],
                "current-page-report-template": "{first} to {last} of {totalRecords}"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    field: "name",
                    style: {"min-width":"12rem"}
                  }, {
                    body: _withCtx(({data}) => [
                      (data.active)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.name), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data.name), 1))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["paginator", "value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.selectedCategoriesLabels && _ctx.selectedCategoriesLabels.length > 0)
        ? (_openBlock(), _createBlock(_component_Panel, {
            key: 1,
            class: "mb-3"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('labels.categories')), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                class: "p-datatable-sm text-sm",
                stripedRows: "",
                paginator: _ctx.selectedCategoriesLabels && _ctx.selectedCategoriesLabels.length > 20,
                rows: 20,
                lazy: true,
                value: _ctx.selectedCategoriesLabels,
                responsiveLayout: "scroll",
                "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                "rows-per-page-options": [10,20,50,100,200],
                "current-page-report-template": "{first} to {last} of {totalRecords}"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, { style: {"min-width":"12rem"} }, {
                    body: _withCtx(({data}) => [
                      _createTextVNode(_toDisplayString(data.label), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["paginator", "value"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    "summary-panel-middle": _withCtx(() => [
      _createElementVNode("h1", _hoisted_5, [
        (_ctx.isRemove)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'ban']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'ban']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'ban']
              })
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              }),
              _createVNode(_component_font_awesome_icon, {
                style: {"margin-left":"30px"},
                icon: ['fas', 'angle-double-down']
              })
            ], 64))
      ])
    ]),
    _: 1
  }, 8, ["onCloseDialog", "onConfirmClicked", "products", "productTotalRecords", "displayDialog", "isFilterRelated", "summaryTabVisible", "loading", "dialogHeader"]))
}