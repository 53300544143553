import {computed, onMounted, ref, watch} from 'vue';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';
import {getAll, search} from '@/services/product-categories';
import Tree from 'primevue/tree';
import AutoComplete from 'primevue/autocomplete';
import Tag from 'primevue/tag';
import {i18n} from '@/utils/i18n';
import store from '@/store';

export default {
    emits: ['selection-complete'],
    components: {
        LoadingPlugin,
        'p-tree': Tree,
        Tag,
        AutoComplete
    },
    props: {
        isRemove: {
            type: Boolean,
            default: false
        },
        showSearchField: {
            type: Boolean,
            default: true
        },
        selectionType: {
            type: String,
            default: 'multiple'
        },
        markInactiveCategories: {
            type: Boolean,
            default: true
        },
        enableParentCategorySelection: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any, context: any) {
        const selectedCategories = ref({});
        const loading = ref(false);
        const isRemove = ref(props.isRemove);
        const showSearchField = ref(props.showSearchField);
        const selectionType = ref(props.selectionType);
        const markInactiveCategories = ref(props.markInactiveCategories);
        const enableParentCategorySelection = ref(
            props.enableParentCategorySelection
        );
        const selectedCategoriesLabels = ref([]);

        const toast = useToast();

        const categories = ref([]);
        const expandedKeys = ref({});

        const searchTerm = ref(null);
        const searchAncestors = ref([]);

        const searchTermPlaceHolder = ref(
            i18n.global.t('labels.enterSearchTerm')
        );

        onMounted(() => {
            loadCategories();
        });

        watch(
            () => props.isRemove,
            (val) => {
                isRemove.value = val;
            }
        );

        watch(
            () => props.showSearchField,
            (val) => {
                showSearchField.value = val;
            }
        );
        watch(
            () => props.selectionType,
            (val) => {
                selectionType.value = val;
            }
        );

        watch(
            () => props.markInactiveCategories,
            (val) => {
                markInactiveCategories.value = val;
            }
        );

        watch(
            () => props.enableParentCategorySelection,
            (val) => {
                enableParentCategorySelection.value = val;
            }
        );

        watch(selectedCategories, () => {
            context.emit(
                'selection-complete',
                selectedCategories.value,
                selectedCategoriesLabels.value
            );
        });

        const loadCategories = () => {
            getAll()
                .then((data) => {
                    categories.value = data.data.map(
                        (item: {
                            id: string;
                            name: string;
                            breadcrumb: any;
                            active: boolean;
                            childCount?: number;
                        }) => {
                            return {
                                key: item.id,
                                label: item.name,
                                leaf: item.childCount === 0,
                                selectable:
                                    isRemove.value ||
                                    item.childCount === 0 ||
                                    enableParentCategorySelection.value,
                                style:
                                    item.active || !markInactiveCategories.value
                                        ? 'color: var(--text-color);'
                                        : 'color: var(--gray-200);',
                                bc: item.breadcrumb?.length < 1
                            };
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const onNodeExpand = (node: any) => {
            if (!node.children) {
                expandNode(node);
            }
        };

        const expandNode = (node: any, onLoaded: any = null) => {
            getAll(node.key)
                .then((data) => {
                    node.children = data.data.map(
                        (item: {
                            id: string;
                            name: string;
                            breadcrumb: any;
                            active: boolean;
                            childCount?: number;
                        }) => {
                            return {
                                key: item.id,
                                label: item.name,
                                leaf: item.childCount === 0,
                                selectable:
                                    isRemove.value ||
                                    item.childCount === 0 ||
                                    enableParentCategorySelection.value,

                                style:
                                    item.active || !markInactiveCategories.value
                                        ? 'color: var(--text-color);'
                                        : 'color: var(--gray-200);',
                                bc: item.breadcrumb?.length < 1
                            };
                        }
                    );

                    if (node.children.length > 0) {
                        addToExpandedKeys(node.key);
                    }

                    if (onLoaded) {
                        onLoaded(node);
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const onNodeSelect = (node: any) => {
            selectedCategoriesLabels.value.push(node);
        };
        const onNodeUnselect = (node: any) => {
            selectedCategoriesLabels.value =
                selectedCategoriesLabels.value.filter(
                    (e) => e.label !== node.label
                );
        };

        const filteredResults = ref([]);

        const resolvedPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const loadData = () => {
            if (!searchTerm.value) {
                toast.error('Please enter search term!');
                return;
            }
            search(searchTerm.value, resolvedPlatform.value?.value)
                .then((data) => {
                    filteredResults.value = data.data.map((item: any) => {
                        return {
                            id: item.ancestors
                                .map((ancestor: any) => ancestor.id)
                                .join('|'),
                            name: item.ancestors
                                .map((ancestor: any) => ancestor.name)
                                .join(' -> ')
                        };
                    });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const onTermSelected = (event: any) => {
            searchAncestors.value = event.value.id.split('|');
            searchTermPlaceHolder.value = event.value.name;
            searchTerm.value = null;
            if (
                (categories.value || []).length === 1 &&
                (categories.value[0].children || []).length === 0
            ) {
                expandNode(categories.value[0], () => {
                    addToExpandedKeysOnSearch({key: searchAncestors.value[0]});
                });
            } else {
                addToExpandedKeysOnSearch({key: searchAncestors.value[0]});
            }
        };

        const expandNodeOnSearch = (nodeKey: any) => {
            const existingTreeNode = findNodeByKey(
                nodeKey,
                categories.value[0]
            );

            if (!existingTreeNode) {
                return;
            }

            if (
                !Object.keys(expandedKeys.value || {}).some(
                    (exp) => exp === nodeKey
                )
            ) {
                getAll(nodeKey, resolvedPlatform.value?.value)
                    .then((data) => {
                        const nodeChildren = data.data.map(
                            (item: {
                                id: string;
                                name: string;
                                breadcrumb: any;
                                active: boolean;
                                childCount?: number;
                            }) => {
                                return {
                                    key: item.id,
                                    label: item.name,
                                    leaf: item.childCount === 0,
                                    selectable:
                                        (item.active &&
                                            item.childCount === 0) ||
                                        enableParentCategorySelection.value ||
                                        Object.keys(
                                            selectedCategories.value
                                        ).indexOf(item.id) !== -1,
                                    style:
                                        item.active ||
                                        !markInactiveCategories.value
                                            ? 'color: var(--text-color);'
                                            : 'color: var(--gray-200);',
                                    bc: item.breadcrumb?.length < 1
                                };
                            }
                        );

                        handleSelection(nodeKey, nodeChildren);
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            } else {
                handleSelection(nodeKey, existingTreeNode.children || []);
            }
        };

        const addToExpandedKeysOnSearch = (node: {key: string}) => {
            if (searchAncestors.value.indexOf(node.key) !== -1) {
                expandNodeOnSearch(node.key);
            }
        };

        const findNodeByKey = (
            key: string,
            currentNode: {key: string; children?: Array<any>}
        ) => {
            if (currentNode.key === key) {
                return currentNode;
            }

            if (currentNode.children && currentNode.children.length > 0) {
                for (const item in currentNode.children) {
                    const result: any = findNodeByKey(
                        key,
                        currentNode.children[item]
                    );
                    if (result) {
                        return result;
                    }
                }
            }
        };

        const addToExpandedKeys = (nodeKey: string) => {
            const expandedNode: any = {};
            expandedNode[nodeKey] = true;

            expandedKeys.value = {
                ...Object.assign(expandedKeys.value, expandedNode)
            };
        };

        const handleSelection = (nodeKey: any, nodeChildren: any) => {
            const existingTreeNode = findNodeByKey(
                nodeKey,
                categories.value[0]
            );

            if (existingTreeNode) {
                if (nodeChildren.length > 0) {
                    if (
                        !Object.keys(expandedKeys.value || {}).some(
                            (exp) => exp === nodeKey
                        )
                    ) {
                        existingTreeNode.styleClass = 'animate-search';
                    }
                } else {
                    if (
                        Object.keys(selectedCategories.value).indexOf(
                            nodeKey
                        ) === -1
                    ) {
                        const selectedCategory: any = {};
                        selectedCategory[nodeKey] = true;

                        selectedCategories.value = Object.assign(
                            selectedCategories.value,
                            selectedCategory
                        );

                        onNodeSelect(existingTreeNode);
                        context.emit(
                            'selection-complete',
                            selectedCategories.value,
                            selectedCategoriesLabels.value
                        );
                    }
                }
            }

            if (
                existingTreeNode &&
                (!existingTreeNode.children ||
                    existingTreeNode.children.length !== nodeChildren.length)
            ) {
                existingTreeNode.children = [...nodeChildren];
            }

            if (nodeChildren.length > 0) {
                addToExpandedKeys(nodeKey);
            }
            if (nodeChildren.length > 0) {
                nodeChildren.forEach((child: any) => {
                    addToExpandedKeysOnSearch(child);
                });
            }
        };

        return {
            categories,
            loading,
            selectedCategories,
            onNodeExpand,
            onNodeSelect,
            onNodeUnselect,
            searchTerm,
            searchTermPlaceHolder,
            selectedCategoriesLabels,
            loadData,
            onTermSelected,
            filteredResults,
            expandedKeys,
            showSearchField,
            selectionType
        };
    }
};
