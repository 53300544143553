import Fieldset from 'primevue/fieldset';
import {computed, ref, toRefs} from 'vue';
import Button from 'primevue/button';
import {createLabel, getReferenceItemLayout} from '@/services/tickets';
import AddRetoureWizard from '@/components/dialog/tickets/add-retoure-wizard.vue';
import AddTicketCommentDialog from '@/components/dialog/tickets/add-ticket-comment.vue';
import CreateSupplierReturnDialog from '@/components/dialog/tickets/create-supplier-return.vue';
import CreateCompensatonShipmentDialog from '@/components/dialog/tickets/create-compensaton-shipment.vue';
import SendDocumentToRemotePrinter from '@/components/dialog/documents/send-document-to-remote-printer.vue';
import {i18n} from '@/utils/i18n';
import store from '@/store';
import {useConfirm} from 'primevue/useconfirm';
import {useToast} from 'vue-toastification';
import Tag from 'primevue/tag';

export default {
    emits: ['reload-ticket-details'],
    components: {
        'p-fieldset': Fieldset,
        'p-button': Button,
        AddRetoureWizard,
        AddTicketCommentDialog,
        CreateSupplierReturnDialog,
        CreateCompensatonShipmentDialog,
        SendDocumentToRemotePrinter,
        Tag
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);
        const showAddRetoureWizardDialog = ref(false);
        const showAddCommentDialog = ref(false);
        const showCreateSupplierReturnDialog = ref(false);
        const showCreateCompensationShipmentDialog = ref(false);
        const selectedRetoure = ref(null);
        const commentDefaultValue = ref(null);
        const displaySendToRemotePrinterDialog = ref(false);
        const selectedDocument = ref(null);

        const confirm = useConfirm();

        const toast = useToast();

        const isRepairTicket = computed(
            () => ticketDetails.value?.isRepairTicket || false
        );

        const isReturnTicket = computed(
            () => ticketDetails.value?.isReturnTicket || false
        );

        const showAddNewRetoureButton = computed(() => {
            return (
                ticketDetails.value &&
                ticketDetails.value.assignedUser &&
                (isRepairTicket.value || isReturnTicket.value)
            );
        });

        const onAddNewRetoureClick = () => {
            showAddRetoureWizardDialog.value = true;
        };

        const closeAddNewRetoureDialog = (event: any) => {
            showAddRetoureWizardDialog.value = false;

            if (event) {
                context.emit('reload-ticket-details');
                commentDefaultValue.value = i18n.global.t(
                    isReturnTicket.value
                        ? 'labels.ticket.templates.comments.retoureArrived'
                        : 'labels.ticket.templates.comments.articleArrived',
                    {
                        ticketOwner:
                            ticketDetails.value?.customer?.company ||
                            ticketDetails.value?.customer?.firstName +
                                ' ' +
                                ticketDetails.value?.customer?.lastName,
                        ticketType: 'RMA',
                        ticketNumber: ticketDetails.value?.ticketNumber
                    }
                );
                showAddCommentDialog.value = true;
            }
        };

        const closeAddTicketCommentDialog = (event: any) => {
            showAddCommentDialog.value = false;
            if (event) {
                context.emit('reload-ticket-details');
            }
        };

        const closeCreateSupplierReturnDialog = (event: any) => {
            showCreateSupplierReturnDialog.value = false;
            if (event) {
                context.emit('reload-ticket-details');
                //showAddCommentDialog.value = true;
                //commentDefaultValue.value = 'foo';
            }
        };

        const closeCreateCompensationShipmentDialog = (event: any) => {
            showCreateCompensationShipmentDialog.value = false;
            if (event) {
                context.emit('reload-ticket-details');
                //showAddCommentDialog.value = true;
                //commentDefaultValue.value = 'foo';
            }
        };

        const onCreateSupplierReturnClick = async (retoure: any) => {
            selectedRetoure.value = retoure;
            showCreateSupplierReturnDialog.value = true;
        };

        const onCreateCompensationShipmentClick = async (retoure: any) => {
            selectedRetoure.value = retoure;
            showCreateCompensationShipmentDialog.value = true;
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('weclapp-tickets-edit') !== -1;
        });

        const closePrintDialogListener = () => {
            displaySendToRemotePrinterDialog.value = false;
        };

        const createAndPrintRetoureLabel = (
            retoure: any,
            labelType: string | null = null
        ) => {
            selectedRetoure.value = retoure;

            confirm.require({
                message: i18n.global.t(
                    labelType !== 'ticket'
                        ? 'messages.createAndSendLabelToRemotePrinter'
                        : 'messages.createAndSendTicketLabelToRemotePrinter'
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    try {
                        const result = await createLabel(
                            ticketDetails.value.ticketNumber,
                            selectedRetoure.value.incomingGoodsNumber,
                            labelType
                        );

                        selectedDocument.value = {
                            id: result.data?.id,
                            name: result.data?.name
                        };

                        displaySendToRemotePrinterDialog.value = true;
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                        selectedDocument.value = null;
                    }
                }
            });
        };

        return {
            ticketDetails,
            getReferenceItemLayout: getReferenceItemLayout,
            showAddRetoureWizardDialog,
            closeAddTicketCommentDialog,
            showAddCommentDialog,
            onAddNewRetoureClick,
            closeAddNewRetoureDialog,
            isReturnTicket,
            showAddNewRetoureButton,
            onCreateSupplierReturnClick,
            showCreateSupplierReturnDialog,
            onCreateCompensationShipmentClick,
            showCreateCompensationShipmentDialog,
            closeCreateSupplierReturnDialog,
            closeCreateCompensationShipmentDialog,
            selectedRetoure,
            commentDefaultValue,
            editPermissionAvailable,
            displaySendToRemotePrinterDialog,
            closePrintDialogListener,
            selectedDocument,
            createAndPrintRetoureLabel
        };
    }
};
