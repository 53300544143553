import {computed, ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import {getAll} from '@/services/warhouse-stock-movements';
import {useToast} from 'vue-toastification';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
    components: {
        PrimeDialog: Dialog,
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-button': Button,
        InputText
    },
    emits: ['close-dialog'],
    props: {
        displayAddSerialNumberDialog: Boolean,
        rowIndex: Number,
        articleId: String,
        warehouseStockMovementFilters: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const loading = ref(false);
        const warehouseStockMovements = ref([]);
        const toast = useToast();
        const selectedItems = ref([]);
        const customSerialNumber = ref();
        const {warehouseStockMovementFilters, rowIndex, articleId} =
            toRefs(props);

        watch(props, async (args) => {
            if (
                args.displayAddSerialNumberDialog &&
                showDialog.value !== args.displayAddSerialNumberDialog
            ) {
                if (articleId.value) {
                    loadLazyData();
                }
            }
            showDialog.value = args.displayAddSerialNumberDialog;
        });

        const closeResponsive = () => {
            context.emit('close-dialog', {
                selectedItems: [],
                rowIndex: null
            });
        };

        const onConfirmButtonClicked = () => {
            showDialog.value = false;
            context.emit('close-dialog', {
                selectedItems: selectedItems.value,
                rowIndex: rowIndex.value
            });
            selectedItems.value = [];
        };

        const resetItems = () => {
            customSerialNumber.value = null;
            warehouseStockMovements.value = [];
            selectedItems.value = [];
        };

        const loadLazyData = () => {
            loading.value = true;

            getAll({
                first: 0,
                rows: 20,
                filters: warehouseStockMovementFilters.value
            })
                .then((data) => {
                    (data.data?.items || []).forEach((wsm: any) => {
                        if ((wsm.serialNumber || '').trim() !== '') {
                            if (wsm.serialNumber.includes(',')) {
                                wsm.serialNumber
                                    .split(',')
                                    .map((sn: string) => {
                                        warehouseStockMovements.value.push({
                                            serialNumber: sn.trim()
                                        });
                                    });
                            } else {
                                warehouseStockMovements.value.push({
                                    serialNumber: wsm.serialNumber.trim()
                                });
                            }
                        }
                    });
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const totalRecords = computed(() => {
            return (warehouseStockMovements.value || []).length;
        });

        const addCustomSerialNumber = () => {
            if (!customSerialNumber.value) {
                return;
            }

            warehouseStockMovements.value.push({
                serialNumber: customSerialNumber.value
            });
            selectedItems.value.push({
                serialNumber: customSerialNumber.value
            });

            customSerialNumber.value = null;
        };

        return {
            showDialog,
            closeResponsive,
            onConfirmButtonClicked,
            warehouseStockMovements,
            totalRecords,
            loading,
            selectedItems,
            resetItems,
            articleId,
            addCustomSerialNumber,
            customSerialNumber
        };
    }
};
