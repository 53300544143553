import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/system-logs', {
        params: {
            start: input.first || 0,
            end: (input.first || 0) + (input.rows || 10),
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            filters: JSON.stringify(clearEmptyFilters(input.filters || {}))
        }
    });
};

export const submitAppError = async (error: {
    message: string;
    stack: string;
}): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/system-logs', error);
};

export const getAllAccessLogs = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/access-logs');
};
