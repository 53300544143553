import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_AddRetoureWizard = _resolveComponent("AddRetoureWizard", true)
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.ticket.addNewRetoure', {ticketNumber: _ctx.ticketDetails?.ticketNumber, ticketSubject: _ctx.truncatedSubject}),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '65vw'},
    closable: false,
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AddRetoureWizard, {
        "ticket-details": _ctx.ticketDetails,
        onRetoureSaved: _cache[0] || (_cache[0] = $event => (_ctx.closeResponsive(true))),
        onRetoureCanceled: _ctx.closeResponsive
      }, null, 8, ["ticket-details", "onRetoureCanceled"])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}