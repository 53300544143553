/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import apiClient from '@/utils/axios';
import jwt_decode from 'jwt-decode';
import {getAccessToken} from 'axios-jwt';
import {IUser} from '@/interfaces/user';
import {AxiosResponse} from 'axios';

const getError = (error: any) => {
    const message = error.message || 'Failed';
    return new Error(message);
};

export const loginByAuth = async (
    azureState: string
): Promise<{access_token: string; refresh_token: string}> => {
    try {
        const authResponse = await apiClient.post('/auth', {
            azureState
        });

        return authResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const getRedirectUrl = async (): Promise<{
    state: string;
    redirectUrl: string;
}> => {
    try {
        const authResponse = await apiClient.get('/oauth2');
        return authResponse.data;
    } catch (error: any) {
        throw getError(error);
    }
};

export const registerByAuth = async (
    email: string,
    password: string,
    firstname: string,
    lastname: string
) => {
    try {
        const token = await apiClient.post('/api/v1/users', {
            username: email,
            password,
            firstname,
            lastname
        });
        return token;
    } catch (error: any) {
        throw getError(error);
    }
};

export const getProfile = async (): Promise<IUser> => {
    try {
        const token = getAccessToken();
        return await jwt_decode(token);
    } catch (error: any) {
        throw getError(error);
    }
};

export const getWeclappApiCredentials = async (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/weclapp/api-credentials');
};

export const updateWeclappApiCredentials = async (
    platform: string,
    credentials: {token: string}
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/weclapp/api-credentials',
        Object.assign({platform}, credentials)
    );
};

export const getShopwareApiCredentials = async (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/shopware/api-credentials');
};

const removeEmptyProperties = (test: any, recurse: boolean) => {
    for (const i in test) {
        if (test[i] === null || test[i] === '') {
            delete test[i];
        } else if (recurse && typeof test[i] === 'object' && test[i] !== null) {
            removeEmptyProperties(test[i], recurse);
        }
    }
};

export const updateShopwareApiCredentials = async (
    platform: string,
    credentials: {client: string; secret: string}
): Promise<AxiosResponse> => {
    removeEmptyProperties(credentials, true);
    return apiClient.put(
        '/api/v1/shopware/api-credentials',
        Object.assign({platform}, credentials)
    );
};
