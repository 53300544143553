import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getTrackingDetails = (
    trackingNumber: string
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/ups/' + trackingNumber + '/tracking');
};

export const cancelShipment = (
    shipmentNumber: string
): Promise<AxiosResponse> => {
    return apiClient.delete('/api/v1/ups/' + shipmentNumber);
};

export const getDailyReport = (
    data: any,
    identifier: string
): Promise<AxiosResponse> => {
    const params = {
        warehouse: data.warehouse,
        reportDate: data.reportDate.toISOString(),
        wholeDay: data.wholeDay,
        startTime: data.startTime ? data.startTime.toISOString() : null
    };

    return apiClient.get('/api/v1/' + identifier + '/daily-report', {
        responseType: 'arraybuffer',
        params
    });
};

export const getDangerousGoodsManifest = (
    data: any
): Promise<AxiosResponse> => {
    const params = Object.assign(
        {},
        {
            warehouse: data.warehouse,
            reportDate: data.reportDate.toISOString(),
            wholeDay: data.wholeDay,
            startTime: data.startTime ? data.startTime.toISOString() : null
        }
    );

    return apiClient.get('/api/v1/ups/dangerous-goods-manifest', {
        responseType: 'arraybuffer',
        params
    });
};

export const getChemicalData = (
    chemicalId: string,
    warehouseName: string
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/ups/' + chemicalId + '/chemical-data', {
        params: {
            regulationSet: 'ADR',
            warehouse: warehouseName
        }
    });
};
