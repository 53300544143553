import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const checkVatNumber = (
    countryCode: string,
    vatNumber: string
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/vies-vat-number-check', {
        params: {
            countryCode,
            vatNumber
        }
    });
};
