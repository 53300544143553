import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b975fbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "formgrid grid p-fluid" }
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = { class: "col field" }
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "field col" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { class: "field col" }
const _hoisted_11 = { class: "field col" }
const _hoisted_12 = { class: "field col" }
const _hoisted_13 = { class: "formgrid grid p-fluid" }
const _hoisted_14 = { class: "field col" }
const _hoisted_15 = { class: "field col" }
const _hoisted_16 = { class: "field col" }
const _hoisted_17 = { class: "field col" }
const _hoisted_18 = { class: "font-bold" }
const _hoisted_19 = { class: "grid p-fluid" }
const _hoisted_20 = { class: "col field" }
const _hoisted_21 = { class: "mr-3 font-bold" }
const _hoisted_22 = { style: {"text-align":"center"} }

export function render(_ctx, _cache) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_Divider = _resolveComponent("Divider")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_BulkProductAction = _resolveComponent("BulkProductAction")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createBlock(_component_BulkProductAction, {
    onCloseDialog: _ctx.closeResponsive,
    onConfirmClicked: _ctx.confirmClicked,
    products: _ctx.products,
    productTotalRecords: _ctx.productTotalRecords,
    displayDialog: _ctx.showDialog,
    isFilterRelated: _ctx.isFilterRelated,
    summaryTabVisible: _ctx.summaryTabVisible,
    loading: _ctx.loading,
    dialogHeader: _ctx.$t('labels.editCustomFields'),
    canPostponeExecution: ""
  }, {
    "tab-panel-middle": _withCtx(() => [
      _createVNode(_component_TabPanel, {
        header: _ctx.$t('labels.customAttributes')
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.memo')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_memo?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.bulkEditSwitches.article_memo,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.bulkEditSwitches.article_memo) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                modelValue: _ctx.v$.article_memo.$model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.article_memo.$model) = $event)),
                disabled: !_ctx.bulkEditSwitches.article_memo,
                class: _normalizeClass({'p-invalid':_ctx.v$.article_memo.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "disabled", "class"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.commentDeliveryNote')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_comment_delivery_note?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.bulkEditSwitches.article_comment_delivery_note,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.bulkEditSwitches.article_comment_delivery_note) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_p_textarea, {
                rows: "4",
                autocomplete: "off",
                modelValue: _ctx.v$.article_comment_delivery_note.$model,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.article_comment_delivery_note.$model) = $event)),
                disabled: !_ctx.bulkEditSwitches.article_comment_delivery_note,
                class: _normalizeClass({'p-invalid':_ctx.v$.article_comment_delivery_note.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "disabled", "class"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.eol')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.art_eol_datum?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.bulkEditSwitches.art_eol_datum,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.bulkEditSwitches.art_eol_datum) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_p_calendar, {
                autocomplete: "off",
                dateFormat: "dd.mm.yy",
                modelValue: _ctx.v$.art_eol_datum.$model,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.art_eol_datum.$model) = $event)),
                disabled: !_ctx.bulkEditSwitches.art_eol_datum,
                class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.art_eol_datum.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "disabled", "class"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.warrantyTime')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_garantiezeit?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.bulkEditSwitches.article_garantiezeit,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.bulkEditSwitches.article_garantiezeit) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_p_dropdown, {
                modelValue: _ctx.v$.article_garantiezeit.$model,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.article_garantiezeit.$model) = $event)),
                options: _ctx.warrantyTimeOptions,
                disabled: !_ctx.bulkEditSwitches.article_garantiezeit,
                optionLabel: "label",
                optionValue: "value",
                class: _normalizeClass({'p-invalid':_ctx.v$.article_garantiezeit.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "options", "disabled", "class"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.priceUponRequest')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.bulkEditSwitches.priceUponRequest,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.bulkEditSwitches.priceUponRequest) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _createVNode(_component_p_dropdown, {
                modelValue: _ctx.v$.priceUponRequest.$model,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.priceUponRequest.$model) = $event)),
                options: _ctx.priceUponRequestOptions,
                disabled: !_ctx.bulkEditSwitches.priceUponRequest,
                optionLabel: "label",
                optionValue: "value",
                class: _normalizeClass({'p-invalid':_ctx.v$.priceUponRequest.$invalid && _ctx.submitted})
              }, null, 8, ["modelValue", "options", "disabled", "class"])
            ])
          ]),
          _createVNode(_component_Divider, {
            align: "left",
            type: "dashed"
          }),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.serialNumberIntern')), 1),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.SN_AUTO_GENERATE.$model,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.SN_AUTO_GENERATE.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.battg')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_batteriegesetz_relevant?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_batteriegesetz_relevant.$model,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.article_batteriegesetz_relevant.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.oversized')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_ueberlaenge?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_ueberlaenge.$model,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.v$.article_ueberlaenge.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.sellOut')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_sell_out?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_sell_out.$model,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.article_sell_out.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.brandAddedValue')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_markenmehrwert?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_markenmehrwert.$model,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.v$.article_markenmehrwert.$model) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createVNode(_component_Divider, {
            align: "left",
            type: "dashed"
          }),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.bulkGoods')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_sperrgut?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_sperrgut.$model,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$.article_sperrgut.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.containsAerosols')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_flag_aerosole?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_flag_aerosole.$model,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.v$.article_flag_aerosole.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.dangerousGoods')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_flag_akku_groesser_100wh?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_flag_akku_groesser_100wh.$model,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.v$.article_flag_akku_groesser_100wh.$model) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _withDirectives((_openBlock(), _createElementBlock("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.dangerousGoodsSmall')), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.customAttributesDefinition?.article_flag_akku_kleiner_100wh?.attributeDescription,
                  void 0,
                  { bottom: true }
                ]
              ]),
              _createVNode(_component_TriStateCheckbox, {
                class: "ml-2",
                modelValue: _ctx.v$.article_flag_akku_kleiner_100wh.$model,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.v$.article_flag_akku_kleiner_100wh.$model) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["header"])
    ]),
    "summary-panel-header": _withCtx((slotProps) => [
      _createVNode(_component_Panel, { class: "mb-3" }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('labels.adjustments')), 1)
        ]),
        default: _withCtx(() => [
          (_ctx.filteredInput)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Object.entries(_ctx.filteredInput), (item) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('labels.productHome.' +
                            _ctx.fieldTranslationMapping[item[0]])), 1),
                    (item[1] === true || item[1] === false)
                      ? (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                          key: 0,
                          modelValue: item[1],
                          "onUpdate:modelValue": $event => ((item[1]) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                      : (item[0] ==='priceUponRequest')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(_ctx.priceUponRequestOptions.find((opt) => item[1]
                            === opt.value)?.label), 1)
                          ], 64))
                        : (item[0] ==='article_garantiezeit')
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createTextVNode(_toDisplayString(_ctx.warrantyTimeOptions.find((opt) => item[1] ===
                            opt.value)?.label), 1)
                            ], 64))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                              _createTextVNode(_toDisplayString(item[1] ||
                            _ctx.$t('messages.valueWillBeSetToNull').toUpperCase()), 1)
                            ], 64))
                  ])
                ]))
              }), 256))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    "summary-panel-middle": _withCtx(() => [
      _createElementVNode("h1", _hoisted_22, [
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        }),
        _createVNode(_component_font_awesome_icon, {
          style: {"margin-left":"30px"},
          icon: ['fas', 'angle-double-down']
        })
      ])
    ]),
    _: 1
  }, 8, ["onCloseDialog", "onConfirmClicked", "products", "productTotalRecords", "displayDialog", "isFilterRelated", "summaryTabVisible", "loading", "dialogHeader"]))
}