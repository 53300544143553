import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-960cbdfe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "button-container" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_Column = _resolveComponent("Column")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_AddEditDialog = _resolveComponent("AddEditDialog")
  const _component_DeleteDialog = _resolveComponent("DeleteDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm text-sm",
        stripedRows: "",
        paginator: _ctx.documents && _ctx.documents.length > 20,
        rows: 20,
        lazy: true,
        value: _ctx.documents,
        responsiveLayout: "scroll",
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        "rows-per-page-options": [10,20,50,100,200],
        "current-page-report-template": "{first} to {last} of {totalRecords}"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.file'),
            style: {"min-width":"10rem"}
          }, {
            body: _withCtx(({data}) => [
              (data.fileExtension)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.fileName) + "." + _toDisplayString(data.fileExtension), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(data.fileName), 1))
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.description'),
            style: {"min-width":"10rem"}
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.title), 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            header: _ctx.$t('labels.fileSize'),
            style: {"min-width":"8rem"}
          }, {
            body: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.fileSize) + " bytes ", 1)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, { style: {"min-width":"5rem"} }, {
            body: _withCtx(({data}) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("i", {
                  class: "pi pi-trash text-red-600 text-sm mr-2",
                  onClick: $event => (_ctx.showDeleteDialog(data))
                }, null, 8, _hoisted_5),
                _createElementVNode("i", {
                  class: "pi pi-pencil text-sm mr-2",
                  onClick: $event => (_ctx.showAddEditDialog(data))
                }, null, 8, _hoisted_6)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["paginator", "value"])
    ]),
    _createVNode(_component_AddEditDialog, {
      displayResponsive: _ctx.displayAddEditDialog,
      "product-number": _ctx.shopwareDetails?.productNumber,
      selectedDocument: _ctx.selectedDocument,
      onCloseDialog: _ctx.closeDialogListener,
      platform: _ctx.platform
    }, null, 8, ["displayResponsive", "product-number", "selectedDocument", "onCloseDialog", "platform"]),
    _createVNode(_component_DeleteDialog, {
      displayResponsive: _ctx.displayDeleteDialog,
      "product-number": _ctx.shopwareDetails?.productNumber,
      selectedDocument: _ctx.selectedDocument,
      onCloseDialog: _ctx.closeDialogListener,
      platform: _ctx.platform
    }, null, 8, ["displayResponsive", "product-number", "selectedDocument", "onCloseDialog", "platform"])
  ], 64))
}