import {computed, ref, toRefs} from 'vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import {useToast} from 'vue-toastification';
import GeneralStep from '@/components/steps/add-retoure/retoure-fields-step.vue';
import BookedInWarehouseStep from '@/components/steps/add-retoure/retoure-booked-in-warehouse-step.vue';
import store from '@/store';
import {addNewRetoure} from '@/services/tickets';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    components: {
        TabPanel,
        TabView,
        GeneralStep,
        BookedInWarehouseStep,
        loading: LoadingPlugin
    },
    emits: ['retoure-saved', 'retoure-canceled'],
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);

        const toast = useToast();
        const activeTabIndex = ref(0);
        const savingInProgress = ref(false);
        const formValues = ref(null);
        const step1Enabled = ref(true);
        const step2Enabled = ref(false);

        const onBackButtonClicked = (wizardStep: number = 1) => {
            if (wizardStep === 0) {
                context.emit('retoure-canceled');
            } else {
                activeTabIndex.value = wizardStep - 1;
            }
        };

        const selectedPlatform = store.getters['auth/platform'];

        const onNextButtonClicked = (event: {
            stepIndex: number;
            formValues: any;
        }) => {
            formValues.value = Object.assign(
                formValues.value || {},
                event.formValues
            );
            if ((event.stepIndex || 1) < 2) {
                if (event.stepIndex == 1) {
                    step2Enabled.value = true;
                }
                activeTabIndex.value = event.stepIndex;
            } else {
                savingInProgress.value = true;
                addNewRetoure(
                    formValues.value,
                    ticketDetails.value.ticketNumber
                )
                    .then((data: any) => {
                        const responseErrorKey = Object.keys(data.data).find(
                            (item: any) => {
                                return data.data.value[item]['error'];
                            }
                        );

                        if (responseErrorKey) {
                            toast.error(
                                data.data.value[responseErrorKey]['error']
                            );
                        } else {
                            context.emit('retoure-saved');
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            }
        };

        const selectedOrderNumber = computed(() => {
            return formValues.value?.orderRelated
                ? formValues.value.orderNumber
                : null;
        });

        const selectedWarehouseId = computed(() => {
            return formValues.value?.warehouseId;
        });

        return {
            onBackButtonClicked,
            onNextButtonClicked,
            activeTabIndex,
            step1Enabled,
            step2Enabled,
            selectedPlatform,
            selectedOrderNumber,
            selectedWarehouseId,
            savingInProgress,
            ticketDetails
        };
    }
};
