<div class="w-full" v-if="supplierConfiguration?.preImportNote">
    <Message :closable="false" severity="warn"
        >{{supplierConfiguration.preImportNote}}</Message
    >
</div>
<div class="w-full">
    <div v-bind="dropzone.getRootProps()" class="import-wizard-container">
        <div style="text-align: center">
            <input v-bind="dropzone.getInputProps()" />
            <p v-if="dropzone.acceptedFiles.length > 0">
                {{ dropzone.acceptedFiles[0].name }}
            </p>
            <p v-else>{{ $t("labels.importWizardInfoText") }}</p>
            <div v-if="sheetName">
                <label>{{$t('labels.priceImport.workbookName')}}:</label>
                <span class="font-bold ml-1">{{sheetName}}</span>

                <template v-if="chosenManufacturer?.name" class="ml-2"
                    >,
                    <label>{{$t('labels.manufacturer')}}:</label>
                    <span class="font-bold ml-1"
                        >{{chosenManufacturer.name}}</span
                    >
                </template>
            </div>
        </div>
    </div>
</div>

<ChooseSpreadsheetDialog
    :files="acceptFiles"
    :displayResponsive="showChooseSpreadsheetDialog"
    :chosen-supplier="supplierConfiguration?.supplierNumber"
    @close-dialog="onCloseSpreadsheetDialog"
></ChooseSpreadsheetDialog>
