<DataTable
    class="p-datatable-sm text-sm"
    stripedRows
    paginator
    scrollable
    scrollHeight="55vh"
    :rows="50"
    :value="badges"
    responsiveLayout="scroll"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows-per-page-options="[10,20,50,100,200]"
    current-page-report-template="{first} to {last} of {totalRecords}"
>
    <Column headerStyle="width: 3rem">
        <template #body="{data}">
            <p-checkbox
                v-if="data.active"
                :value="data"
                v-model="selectedBadges"
            /> </template
    ></Column>
    <Column field="name" :header="$t('labels.name')" style="min-width: 12rem">
        <template #body="{data}">
            <span v-if="data.active">{{ data.name }}</span>
            <span v-else class="text-gray-600">{{ data.name }}</span>
        </template>
    </Column>
</DataTable>
