import {ref, onMounted} from 'vue';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import {useToast} from 'vue-toastification';
import {getAllAccessLogs} from '@/services/system-logs';

export default {
    components: {
        DataTable,
        Column,
        Panel,
        loading: LoadingPlugin,
        Dropdown
    },
    setup() {
        onMounted(() => {
            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const toast = useToast();

        const accessLogs = ref([]);

        const loadLazyData = () => {
            loading.value = true;

            getAllAccessLogs()
                .then((data: any) => {
                    for (const [date, value] of Object.entries(
                        data?.data?.items || {}
                    )) {
                        for (const [menuItem, statistics] of Object.entries(
                            value || {}
                        )) {
                            accessLogs.value.push({
                                date,
                                representative: {
                                    name: menuItem,
                                    label: resolveLabel(menuItem),
                                    iconClass: resolveIconClass(menuItem)
                                },
                                hits: statistics['GET'] || 0,
                                updates: statistics['PUT'] || 0
                            });
                        }
                    }
                })
                .catch((error: any) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const resolveLabel = (menuItem: string): string => {
            switch (menuItem) {
                case 'SKU Maintenance':
                    return 'labels.skuMaintenance';
                case 'Delivery Time':
                    return 'labels.deliveryTime';
                case 'Disposition':
                    return 'labels.salesOrder.disposition';
                default:
                    return 'labels.warehouseStockBPM';
            }
        };

        const resolveIconClass = (menuItem: string): string => {
            switch (menuItem) {
                case 'SKU Maintenance':
                    return 'fa-spell-check';
                case 'Delivery Time':
                    return 'fa-business-time';
                case 'Disposition':
                    return 'fa-wave-square';
                default:
                    return 'fa-warehouse';
            }
        };

        return {
            accessLogs,
            dt,
            loading
        };
    }
};
