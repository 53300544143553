import {computed, onMounted, ref} from 'vue';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {i18n} from '@/utils/i18n';
import {DateTime} from 'luxon';
import store from '@/store';
import {useToast} from 'vue-toastification';

import Skeleton from 'primevue/skeleton';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {FilterMatchMode} from 'primevue/api';
import Button from 'primevue/button';
import {getAllOrders, getSingleOrder} from '@/services/shopware';
import InputText from 'primevue/inputtext';
import Panel from 'primevue/panel';
import Checkbox from 'primevue/checkbox';
import Divider from 'primevue/divider';
import Calendar from 'primevue/calendar';
import {getAll} from '@/services/sales-orders';
import {useConfirm} from 'primevue/useconfirm';
import {importShopOrder} from '@/services/import';

export default {
    components: {
        LoadingPlugin,
        DataTable,
        Column,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        Skeleton,
        InputIcon,
        IconField,
        TriStateCheckbox,
        InputText,
        Panel,
        Divider
    },
    setup() {
        const loading = ref(false);
        const foundOrders = ref([]);
        const filteredOrders = ref(null);
        const toast = useToast();
        const searchInTeltec = ref(true);
        const searchInVideodata = ref(true);
        const expandedRows = ref([]);
        const confirm = useConfirm();

        const dt = ref();

        onMounted(async () => {
            searchInTeltec.value = await store.getters[
                'import/shopOrdersInTeltec'
            ];

            searchInVideodata.value = await store.getters[
                'import/shopOrdersInVideodata'
            ];

            if (resolvedPlatform.value) {
                loadData();
            } else {
                setTimeout(loadData, 1000);
            }
        });

        const loadData = () => {
            loading.value = true;
            const platforms = [];
            foundOrders.value.length = 0;
            if (searchInTeltec.value) {
                platforms.push(
                    ['ttd', 'ttl'].includes(
                        resolvedPlatform.value?.threeLetterId
                    )
                        ? resolvedPlatform.value.value
                        : secondPlatform.value
                );
            }
            if (searchInVideodata.value) {
                platforms.push(
                    ['vdd', 'vdl'].includes(
                        resolvedPlatform.value?.threeLetterId
                    )
                        ? resolvedPlatform.value.value
                        : secondPlatform.value
                );
            }
            getAllOrders({
                onlyNonSynced: true,
                platforms: platforms.filter(
                    (value, index, array) => array.indexOf(value) === index
                )
            })
                .then((data: any) => {
                    foundOrders.value = (data.data.items || []).map(
                        (item: any) => {
                            return {
                                ...item,
                                paymentMethodName: item?.paymentMethod?.name,
                                orderTimeParsed: item.orderTime
                                    ? DateTime.fromSeconds(
                                          item.orderTime
                                      ).toJSDate()
                                    : null,
                                orderTimeFormatted: item.orderTime
                                    ? DateTime.fromSeconds(item.orderTime)
                                          .setLocale(i18n.global.locale)
                                          .setZone(
                                              process.env
                                                  ?.VUE_APP_DEFAULT_TIME_ZONE
                                          )
                                          .toLocaleString(
                                              DateTime.DATETIME_MED_WITH_SECONDS
                                          )
                                    : null,
                                customerSearchable: item.billingAddress
                                    ? (item.billingAddress.firstName || '') +
                                      (item.billingAddress.lastName || '') +
                                      (item.billingAddress.company || '')
                                    : null
                            };
                        }
                    );

                    if (searchInTeltec.value) {
                        foundOrders.value.forEach((foundOrder: any) => {
                            if (
                                ['teltec', 'teltec-demo'].includes(
                                    foundOrder.platform
                                )
                            ) {
                                getSingleOrder(
                                    foundOrder.number,
                                    foundOrder.platform,
                                    true
                                )
                                    .then((singleOrderData: any) => {
                                        const index =
                                            foundOrders.value.findIndex(
                                                (or) =>
                                                    or.number ===
                                                    singleOrderData?.data
                                                        ?.number
                                            );
                                        if (index !== -1) {
                                            foundOrders.value[index] =
                                                Object.assign(
                                                    foundOrders.value[index],
                                                    {
                                                        ...singleOrderData.data,
                                                        paymentMethodName:
                                                            singleOrderData.data
                                                                ?.paymentMethod
                                                                ?.name,
                                                        customerSearchable:
                                                            singleOrderData.data
                                                                .billingAddress
                                                                ? (singleOrderData
                                                                      .data
                                                                      .billingAddress
                                                                      .firstName ||
                                                                      '') +
                                                                  (singleOrderData
                                                                      .data
                                                                      .billingAddress
                                                                      .lastName ||
                                                                      '') +
                                                                  (singleOrderData
                                                                      .data
                                                                      .billingAddress
                                                                      .company ||
                                                                      '')
                                                                : null
                                                    }
                                                );
                                        }
                                    })
                                    .catch((error) => {
                                        toast.error(
                                            error.response?.data?.error ||
                                                error.message
                                        );
                                    });
                            }
                        });
                    }

                    getAll({
                        first: 0,
                        rows: foundOrders.value.length,
                        keysToSkip: ['supplier', 'documents'],
                        filters: {
                            orderNumber: {
                                value: foundOrders.value.map(
                                    (foundOrder: any) => foundOrder.number
                                ),
                                matchMode: FilterMatchMode.CONTAINS
                            }
                        }
                    }).then((orderData: any) => {
                        (foundOrders.value || []).forEach((fo: any, idx) => {
                            const resultIndex = (
                                orderData.data.items || []
                            ).findIndex(
                                (or: any) => or.orderNumber === fo?.number
                            );

                            if (resultIndex === -1) {
                                Object.assign(foundOrders.value[idx], {
                                    missingWeclappOrder: true
                                });
                            }
                        });

                        (orderData.data.items || []).forEach(
                            (weclappOrder: any) => {
                                const index = foundOrders.value.findIndex(
                                    (or) =>
                                        or.number === weclappOrder?.orderNumber
                                );
                                if (index !== -1) {
                                    foundOrders.value[index] = Object.assign(
                                        foundOrders.value[index],
                                        {
                                            availability:
                                                weclappOrder.availability,
                                            availabilityForAllWarehouses:
                                                weclappOrder.availabilityForAllWarehouses
                                        }
                                    );

                                    //foundOrders.value[index].orderLines
                                }

                                weclappOrder.orderItems.forEach(
                                    (weclappOrderItem: any) => {
                                        if (
                                            typeof foundOrders.value[index] ===
                                            'undefined'
                                        ) {
                                            return true;
                                        }
                                        const orderLineIndex =
                                            foundOrders.value[
                                                index
                                            ].orderLines.findIndex(
                                                (orl: any) =>
                                                    orl.articleNumber ===
                                                    weclappOrderItem?.articleNumber
                                            );
                                        if (orderLineIndex !== -1) {
                                            foundOrders.value[index].orderLines[
                                                orderLineIndex
                                            ] = Object.assign(
                                                foundOrders.value[index]
                                                    .orderLines[orderLineIndex],
                                                {
                                                    availability:
                                                        weclappOrderItem.availability,
                                                    availabilityForAllWarehouses:
                                                        weclappOrderItem.availabilityForAllWarehouses
                                                }
                                            );

                                            //foundOrders.value[index].orderLines
                                        }
                                    }
                                );
                            }
                        );
                    });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return 'videodata-demo';
                case 'ttl':
                    return 'videodata';
                case 'vdd':
                    return 'teltec-demo';
                default:
                    return 'teltec';
            }
        });

        const getCurrencyFormatted = (
            rawValue: number,
            currency: string = 'EUR'
        ) => {
            const formatter = new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency
            });

            return formatter.format(rawValue);
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('shop-orders-import-edit') !== -1;
        });

        const filters = ref({
            number: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            customerSearchable: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            paymentMethodName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            orderTimeParsed: {
                value: null,
                matchMode: FilterMatchMode.DATE_IS
            },
            partDelivery: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        });

        const onFilter = (event: any) => {
            if (event?.filteredValue) {
                filteredOrders.value = [].concat(event.filteredValue);
            }
        };

        const getAvailabilityTooltipText = (
            availability: string,
            globalKey: boolean = false
        ) => {
            let resolved = availability;

            switch (availability) {
                case 'NOTHING_AVAILABLE':
                    resolved = 'notAvailable';
                    break;

                case 'PARTIALLY_AVAILABLE':
                    resolved = 'partiallyAvailable';
                    break;

                case 'COMPLETELY_AVAILABLE':
                    resolved = 'completelyAvailable';
                    break;
            }

            return i18n.global.t(
                globalKey
                    ? 'labels.availabilityForAllWarehousesWithValue'
                    : 'labels.availabilityWithValue',
                {
                    availability: i18n.global.t('labels.' + resolved)
                }
            );
        };

        const handleManualRecreation = (
            orderNumber: string,
            platform: string
        ) => {
            confirm.require({
                message: i18n.global.t('messages.manualOrderRecreation', {
                    orderNumber
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    loading.value = true;
                    importShopOrder('pre-order', orderNumber, null, platform)
                        .then((data: any) => {
                            if (data.error) {
                                toast.error(data.error);
                                loading.value = false;
                            } else {
                                loadData();
                            }
                        })
                        .catch((error: any) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                            loading.value = false;
                        });
                }
            });
        };

        return {
            loading,
            dt,
            locale: i18n.global.locale,
            getCurrencyFormatted,
            foundOrders,
            filters,
            onFilter,
            editPermissionAvailable,
            searchInTeltec,
            searchInVideodata,
            loadData,
            expandedRows,
            handlePlatformClick: (isVideodata: boolean) => {
                if (isVideodata) {
                    store.dispatch(
                        'import/setShopOrdersInVideodata',
                        searchInVideodata.value
                    );
                } else {
                    store.dispatch(
                        'import/setShopOrdersInTeltec',
                        searchInTeltec.value
                    );
                }
            },
            getAvailabilityTooltipText,
            handleManualRecreation
        };
    }
};
