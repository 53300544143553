<loading v-model:active="loading" />
<Panel :header="$t('labels.shipments')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="10"
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        lazy
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="shipments"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @state-restore="onStateRestore"
        stateStorage="local"
        stateKey="shipment-list-state-session"
    >
        <Column style="min-width: 4rem" class="tt-filter-menu-th"
            ><template #filter>
                <FilterMenu
                    parent-component-id="shipment-list"
                    :parent-filters="filters"
                    @clear-filters="clearFilters"
                    @apply-filters="applyFilters"
                />
                <i
                    class="pi pi-cog"
                    v-if="groupedSelectionColumnAvailable"
                    style="padding-left: 7px; cursor: pointer"
                    @click="bulkMenuToggle"
                    aria-haspopup="true"
                    aria-controls="overlay_tmenu"
                ></i>
                <TieredMenu
                    ref="bulkMenu"
                    id="overlay_tmenu"
                    :model="bulkItems"
                    popup
                />
            </template>
            <template #body="{data}">
                <p-checkbox
                    v-if="shippingCarrierAnnouncementAvailable(data) && !groupedShipmentsSelection.some((item) => item.warehouse?.id !== data.warehouse?.id || item.recipientParty?.id !== data.recipientParty?.id || item.shippingCarrier?.id !== data.shippingCarrier?.id || item.recipientParty?.customerSalesChannel !== data.recipientParty?.customerSalesChannel)"
                    v-model="groupedShipmentsSelection"
                    :value="data"
                ></p-checkbox> </template
        ></Column>
        <Column
            field="shipmentNumber"
            :sortable="true"
            style="min-width: 8rem"
            :header="$t('labels.shipment.shipmentNumber')"
        >
            <template #body="{data}">
                <div class="flex align-content-center">
                    <a
                        :href="data.url + 'webapp/view/shipment/ShipmentDetailOutgoing.page?entityId=' + data.id"
                        target="_blank"
                        >{{ data.shipmentNumber }}</a
                    >
                    <div
                        class="sales-channel-dot w-1rem h-1rem ml-2"
                        :class="{'sales-channel-teltec': data?.salesChannel === 'NET1', 'sales-channel-videodata': data?.salesChannel === 'NET2'}"
                    ></div>
                </div>
            </template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column
            style="min-width: 9rem"
            field="recipient"
            :header="$t('labels.shipment.recipient')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
            <template #body="{data}">
                <a
                    class="text-xs"
                    :href="data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.recipientParty?.id"
                    target="_blank"
                    >{{ outputRecipientInGrid(data) }}</a
                >
            </template>
        </Column>
        <Column
            field="createdDate"
            dataType="date"
            :sortable="true"
            :header="$t('labels.createdAt')"
            style="max-width: 16rem"
        >
            <template #filter="{filterModel}">
                <Calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                />
            </template>
            <template #body="data">
                {{ new Date(data.data.createdDate).toLocaleDateString("de-DE")
                }}
            </template>
        </Column>

        <Column
            :header="$t('labels.status')"
            style="max-width: 10rem"
            field="status"
            :sortable="true"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #body="{data}">
                <span :class="getStatusCellStyle(data.status)"
                    >{{$t('labels.shipment.statusOptions.' + data.status)
                    }}</span
                ></template
            ><template #filter="{filterModel,filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    placeholder="Any"
                    :options="[{label: $t('labels.shipment.statusOptions.NEW'), value: 'NEW'}, {label: $t('labels.shipment.statusOptions.DELIVERY_NOTE_PRINTED'), value: 'DELIVERY_NOTE_PRINTED'}, {label: $t('labels.shipment.statusOptions.SHIPPED'), value: 'SHIPPED'}, {label: $t('labels.shipment.statusOptions.CANCELLED'), value: 'CANCELLED'}]"
                    optionLabel="label"
                    optionValue="value"
                    class="p-column-filter text-xs"
                    @change="filterCallback()"
                    :showClear="true"
                >
                </Dropdown> </template
        ></Column>
        <Column
            :header="$t('labels.shipment.shipmentType')"
            style="max-width: 10rem"
            field="shipmentType"
            :sortable="true"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #body="{data}">
                <span :class="getShipmentTypeCellStyle(data.shipmentType)"
                    >{{$t('labels.shipment.shipmentTypeOptions.' +
                    data.shipmentType)}}</span
                ></template
            >
            <template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="[{label: $t('labels.shipment.shipmentTypeOptions.STANDARD'), value: 'STANDARD'}, {label: $t('labels.shipment.shipmentTypeOptions.CONSIGNMENT'), value: 'CONSIGNMENT'}, {label: $t('labels.shipment.shipmentTypeOptions.CUSTOMER_COMPENSATION'), value: 'CUSTOMER_COMPENSATION'}, {label: $t('labels.shipment.shipmentTypeOptions.SUPPLIER_RETURN'), value: 'SUPPLIER_RETURN'}, {label: $t('labels.shipment.shipmentTypeOptions.CONSIGNMENT_RETURN'), value: 'CONSIGNMENT_RETURN'}, {label: $t('labels.shipment.shipmentTypeOptions.INTERNAL'), value: 'INTERNAL'}]"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Any"
                    class="p-column-filter text-xs"
                    @change="filterCallback()"
                    :showClear="true"
                >
                </Dropdown> </template
        ></Column>
        <Column
            field="salesOrder"
            :sortable="true"
            style="max-width: 10rem"
            :header="$t('labels.shipment.orderNumber')"
        >
            <template #body="{data}">
                <a
                    v-if="data.salesOrder"
                    :href="data.url + 'webapp/view/op/order/salesOrderDetail.page?entityId=' + data.salesOrder.id"
                    target="_blank"
                    >{{ data.salesOrder.orderNumber }}</a
                >
            </template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column
            field="shippingCarrier"
            :sortable="true"
            style="max-width: fit-content"
            :header="$t('labels.shipment.shippingCarrier')"
        >
            <template #body="{data}">
                {{ data.shippingCarrier?.name }}</template
            >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.shipment.shippingCarrier')"
                />
            </template>
        </Column>
        <Column
            field="warehouse"
            :sortable="true"
            style="max-width: fit-content"
            :header="$t('labels.shipment.warehouse')"
        >
            <template #body="{data}"> {{ data.warehouse?.name }}</template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.shipment.warehouse')"
                />
            </template>
        </Column>

        <Column
            field="packageTrackingNumber"
            header="Tracking #"
            style="min-width: 12rem; max-width: fit-content"
        >
            <template #body="{data}">
                <div
                    class="flex"
                    v-if="!data.packageTrackingNumber || data.packageTrackingNumber.indexOf(',') === -1"
                >
                    {{data.packageTrackingNumber}}
                    <span
                        style="cursor: pointer; display: flex"
                        v-if="data.packageTrackingNumber && upsViewPermissionAvailable && data.shippingCarrierType !== null"
                    >
                        <i
                            v-if="data.shippingCarrierType === 'ups'"
                            @click="getUpsTrackingDetails(data.packageTrackingNumber)"
                            class="pi pi-truck ml-1"
                            style="margin-top: 2px"
                            v-tooltip.top="$t('labels.shipment.shippingCarrierTrackingDetails', {carrierName: resolveShipmentCarrierType(data)})"
                        ></i>
                        <i
                            v-if="data.documents && data.documents.some((item) => {return item.name.startsWith(data.packageTrackingNumber.trim());})"
                            @click="showTrackingLabel(data.documents.find((item) => {return item.name.startsWith(data.packageTrackingNumber.trim());}))"
                            class="pi pi-instagram ml-1"
                            style="margin-top: 2px"
                            v-tooltip.top="$t('labels.shipment.showShippingCarrierLabel', {carrierName: resolveShipmentCarrierType(data)})"
                        ></i>
                        <i
                            v-if="data.documents && data.documents.some((item) => {return item.name.startsWith(data.packageTrackingNumber.trim());})"
                            @click="printUpsLabel(data, data.packageTrackingNumber)"
                            class="pi pi-print ml-1"
                            style="margin-top: 2px"
                            v-tooltip.top="$t('labels.printDocumentRemotely')"
                        ></i>
                    </span>
                </div>
                <template v-else>
                    <div
                        v-for="element in data.packageTrackingNumber.split(',')"
                    >
                        <div v-if="element.trim() !== ''" class="flex">
                            {{ element.trim() }}
                            <span
                                v-if="data.shippingCarrierType !== null"
                                style="cursor: pointer; display: flex"
                            >
                                <i
                                    @click="getUpsTrackingDetails(element.trim())"
                                    v-if="data.shippingCarrierType === 'ups'"
                                    class="pi pi-truck ml-1"
                                    style="margin-top: 2px"
                                    v-tooltip.top="$t('labels.shipment.shippingCarrierTrackingDetails', {carrierName: resolveShipmentCarrierType(data)})"
                                ></i>
                                <i
                                    v-if="data.documents && data.documents.some((item) => {return item.name.startsWith(element.trim());})"
                                    @click="showTrackingLabel(data.documents.find((item) => {return item.name.startsWith(element.trim());}))"
                                    class="pi pi-instagram ml-1"
                                    style="margin-top: 2px"
                                    v-tooltip.top="$t('labels.shipment.showShippingCarrierLabel', {carrierName:  resolveShipmentCarrierType(data)})"
                                ></i>
                                <i
                                    v-if="data.documents && data.documents.some((item) => {return item.name.startsWith(element.trim());})"
                                    @click="printUpsLabel(data, element.trim())"
                                    class="pi pi-print ml-1"
                                    style="margin-top: 2px"
                                    v-tooltip.top="$t('labels.printDocumentRemotely')"
                                ></i>
                            </span>
                        </div>
                    </div>
                </template>
            </template>
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column style="min-width: 10rem">
            <template #body="{data}">
                <div class="text-xs">
                    {{ $t('labels.shipment.weight') }} :
                    <b>{{ data.packageWeight }}</b>
                    <i
                        v-if="data?.customAttributes?.delivery_package_information"
                        @click="selectedShipment = data; showPackageInformationDialog = true;"
                        v-tooltip.top="$t('labels.shipment.packageInformation')"
                        class="pi pi-box ml-2"
                        style="cursor: pointer"
                    ></i>
                </div>
                <!--<div class="text-xs">
                    {{$t('labels.shipment.numberOfLabels')}} :
                    <b>{{ data.shippingLabelsCount }}</b>
                </div>-->
                <div class="text-xs" v-if="data?.shipmentMethod?.name">
                    <b>{{ data.shipmentMethod.name }}</b>
                    <i
                        v-if="data.isPickup"
                        @click="downloadPickupDocument(data)"
                        class="pi pi-file-pdf ml-1 text-red-600"
                        v-tooltip.top="$t('labels.downloadFile')"
                        style="cursor: pointer"
                    ></i>

                    <i
                        v-if="data.isPickup && existingPickupDocument(data)"
                        @click="printDocument(existingPickupDocument(data))"
                        v-tooltip.top="$t('labels.printSpecificDocumentRemotely', {name: existingPickupDocument(data).name})"
                        class="pi pi-print ml-1"
                        style="cursor: pointer"
                    ></i>
                </div>
            </template>
        </Column>

        <Column
            style="min-width: 60px; max-width: 'fit-content'"
            v-if="editPermissionAvailable"
        >
            <template #body="{data}">
                <div class="button-container">
                    <router-link
                        v-tooltip.left="$t('labels.shipment.shipmentMask')"
                        v-if="(data.status === 'NEW' || data.status === 'DELIVERY_NOTE_PRINTED')"
                        :to="{
                                    name: 'ShipmentMask',
                                    params: {shipmentNumber: data.shipmentNumber}
                                }"
                        ><i class="pi pi-pencil text-sm mr-2 text-color"></i
                    ></router-link>
                    <span
                        v-if="shippingCarrierAnnouncementAvailable(data)"
                        style="cursor: pointer"
                        @click="groupedShipmentsSelection.length = 0;selectedShipment = data;displayUpsDialog = true"
                    >
                        <img
                            v-if="data.shippingCarrierType !== null"
                            class="rounded"
                            :src="require('@/assets/img/'+(data.shippingCarrierType || ups)+'.png')"
                            :alt="resolveShipmentCarrierType(data)"
                            style="
                                height: 18px;
                                margin-top: -5px;
                                background-color: #ffb500;
                            "
                            v-tooltip.left="$t('labels.shipment.shippingCarrierAnnouncePackage', {carrierName: resolveShipmentCarrierType(data)})"
                        />
                    </span>
                    <i
                        v-if="upsEditPermissionAvailable && data.packageTrackingNumber && data.shippingCarrierType !== null"
                        @click="voidShipment(data.shipmentNumber, resolveShipmentCarrierType(data))"
                        class="pi pi-ban text-red-600"
                        :class="{'ml-1': (data.status === 'NEW' || data.status === 'DELIVERY_NOTE_PRINTED') || shippingCarrierAnnouncementAvailable(data)}"
                        style="margin-top: 2px; cursor: pointer"
                        v-tooltip.left="$t('labels.shipment.cancelShippingCarrierShipment', {carrierName: resolveShipmentCarrierType(data)})"
                    ></i>
                </div>
            </template>
        </Column>
        <template #expansion="slotProps">
            <div
                v-if="slotProps.data.description"
                v-html="slotProps.data.description"
            ></div>
            <div v-else>No description available.</div>
        </template>
    </DataTable>
</Panel>
<AnnounceShipmentDialog
    :shipment="selectedShipment"
    :groupedShipments="groupedShipments"
    :display-dialog="displayUpsDialog"
    @close-dialog="closeDialogListener"
/>
<SendLabelToRemotePrinter
    :display-dialog="displaySendToRemotePrinterDialog"
    @close-dialog="closePrintDialogListener"
    :shipment-number="selectedShipment?.shipmentNumber"
    :tracking-number="selectedTrackingNumber"
>
</SendLabelToRemotePrinter>
<SendDocumentToRemotePrinter
    :display-dialog="displayPickupRemotePrinterDialog"
    @close-dialog="closePrintDialogListener"
    :selected-document="selectedDocument"
>
</SendDocumentToRemotePrinter>
<p-dialog
    :header="$t('labels.result')"
    v-model:visible="showWorkflowExecutionOverviewDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '600px'}"
    modal
    :closable="false"
>
    <div v-for="item in workflowExecutionResults" class="w-full mb-3">
        <i
            v-if="!item.error"
            class="pi text-green-600 text-lg pi-check-circle mr-2"
        ></i>
        <i
            v-else="!item.error"
            class="pi text-lg text-red-600 pi-times-circle mr-2"
        ></i>
        <span class="font-semibold"
            >{{$t("labels.shipment.workflow.steps." + item?.step)}}</span
        >

        <div>
            <small v-if="item.result && typeof item.result === 'string'"
                >{{item.result}}</small
            >
        </div>
    </div>
    <template #footer>
        <div
            class="w-full flex justify-content-end"
            :class="{'justify-content-between' : workflowIdenfier==='full', 'justify-content-end' : workflowIdenfier!=='full'}"
        >
            <p-button
                v-if="false"
                severity="danger"
                @click="showWorkflowExecutionOverviewDialog = false"
            >
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button
                severity="success"
                @click="handleWorkflowResultsConfirmed"
            >
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </template>
</p-dialog>
<p-dialog
    :header="$t('labels.shipment.packageInformation')"
    v-model:visible="showPackageInformationDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '900px'}"
    modal
    @hide="selectedShipment = null;"
>
    <template v-for="(item, index) in packageInformationData" :key="index">
        <div class="grid">
            <div class="col" v-if="item.articleNumber">
                <label class="font-bold">Karton</label>
                <div>
                    <small style="display: block">
                        {{ item.article?.name }}
                    </small>
                </div>
            </div>
            <div class="col" v-else-if="item.singlePackageArticleNumber">
                <Tag>{{ $t('labels.productHome.singlePackage') }}</Tag>
            </div>
            <div class="col-2">
                <label class="font-bold"
                    >{{$t('labels.productHome.grossWeightShort')}}</label
                >
                <div>{{formatter.format(item.grossWeight)}} kg</div>
            </div>
            <div class="col-1">
                <label class="font-bold"
                    >{{$t('labels.productHome.lengthShort')}}</label
                >
                <div>{{formatter.format(item.length)}} cm</div>
            </div>
            <div class="col-1">
                <label class="font-bold"
                    >{{$t('labels.productHome.heightShort')}}</label
                >
                <div>{{formatter.format(item.height)}} cm</div>
            </div>
            <div class="col-1">
                <label class="font-bold"
                    >{{$t('labels.productHome.widthShort')}}</label
                >
                <div>{{formatter.format(item.width)}} cm</div>
            </div>

            <div class="col-2" v-if="item.dangerousGoods">
                <div>
                    <Tag>Gefahrgut</Tag>
                    <small style="display: block" v-if="item.netWeight"
                        >{{$t('labels.productHome.netWeightShort')}} : {{
                        formatter.format(item.netWeight) }} kg
                    </small>
                    <small style="display: block" v-if="item.chemicalId">
                        {{$t('labels.shipment.upsChemicalId')}}: {{
                        item.chemicalId }}
                    </small>
                </div>
            </div>
            <div v-else class="col-2">&nbsp;</div>
        </div>
    </template>
</p-dialog>
