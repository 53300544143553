import {computed, ref} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import {useVuelidate} from '@vuelidate/core';
import {useToast} from 'vue-toastification';
import {requiredIf} from '@vuelidate/validators';
import {getAllForDuplicateCheck} from '@/services/products';
import store from '@/store';

export default {
    emits: ['environments-selected'],
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const savingInProgress = ref(false);
        const showCheckResults = ref(false);
        const duplicates = ref([]);
        const toast = useToast();

        const platform = computed(() => {
            return store.getters['auth/platform'];
        });

        const state = ref({
            name: '',
            manufacturerPartNumber: '',
            sku: '',
            ean: ''
        });

        const rules = {
            name: {
                required: requiredIf(() => {
                    return (
                        state.value.manufacturerPartNumber.trim() === '' &&
                        state.value.sku.trim() === '' &&
                        state.value.ean.trim() === ''
                    );
                })
            },
            manufacturerPartNumber: {},
            sku: {},
            ean: {}
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            Object.keys(state.value).forEach(
                (k: any) =>
                    (state.value[k as keyof typeof state.value] =
                        state.value[k as keyof typeof state.value].trim())
            );

            getAllForDuplicateCheck(state.value)
                .then((data) => {
                    duplicates.value = data.data?.items || [];
                    showCheckResults.value = true;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const showNextButton = computed(() => {
            return !duplicates.value || duplicates.value.length < 1;
        });

        const handleBackClick = () => {
            showCheckResults.value = false;

            /*context.emit('environments-selected', {
                selectedEnvironments: [platform?.value],
                duplicateCheckValues: state.value,
                pairValues: {}
            });*/
        };

        const handleProceedToAddingClick = () => {
            context.emit('environments-selected', {
                selectedEnvironments: [platform?.value],
                duplicateCheckValues: state.value
            });
        };

        const findSku = (duplicates: Array<any>, submittedSku: string) => {
            return duplicates.find((item: any) => {
                return item.articleSupplySources.some(
                    (supplySource: any) =>
                        supplySource.articleNumber === submittedSku
                );
            });
        };

        const findDuplicateValue = (
            duplicates: Array<any>,
            propertyName: string
        ) => {
            return duplicates.find((item: any) => {
                if (!item[propertyName]) {
                    return false;
                }

                if (!state.value[propertyName as keyof typeof state.value]) {
                    return false;
                }
                return (
                    item[propertyName].toLowerCase() ===
                    state.value[
                        propertyName as keyof typeof state.value
                    ].toLowerCase()
                );
            });
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            showCheckResults,
            findSku,
            duplicates,
            showNextButton,
            handleBackClick,
            handleProceedToAddingClick,
            findDuplicateValue
        };
    }
};
