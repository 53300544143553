<BulkProductAction
    @close-dialog="closeResponsive"
    @confirm-clicked="confirmClicked"
    :products="products"
    :productTotalRecords="productTotalRecords"
    :displayDialog="showDialog"
    :isFilterRelated="isFilterRelated"
    :summaryTabVisible="summaryTabVisible"
    :loading="loading"
    :dialogHeader="$t('labels.editCompliance')"
    canPostponeExecution
>
    <template #tab-panel-middle>
        <TabPanel :header="$t('labels.compliance')">
            <Compliance
                @form-dirty="onFormDirty"
                :custom-attributes-definition="customAttributesDefinition"
                isBulkEdit
            ></Compliance>
        </TabPanel>
    </template>
    <template #summary-panel-header="slotProps">
        <Panel class="mb-3">
            <template #header>
                <div class="font-bold">{{ $t('labels.adjustments') }}</div>
            </template>
            <template v-if="filteredComplianceInput">
                <div
                    v-for="item in Object.entries(filteredComplianceInput)"
                    class="grid mb-1"
                >
                    <div class="col-4">
                        <label
                            >{{ $t('labels.productCompliance.' + item[0])
                            }}</label
                        >
                    </div>
                    <div class="col-8">
                        <template v-if="item[1] === true || item[1] === false">
                            <TriStateCheckbox
                                v-model="item[1]"
                            ></TriStateCheckbox>
                        </template>
                        <template v-else-if="item[1] instanceof Date"
                            >{{ item[1].toLocaleDateString(locale) ||
                            $t('messages.valueWillBeSetToNull').toUpperCase()
                            }}</template
                        >
                        <template v-else-if="item[0]==='weeeClasification'"
                            >{{ weeeOptions.find(i => i.value ===
                            item[1])?.label ||
                            $t('messages.valueWillBeSetToNull').toUpperCase()
                            }}</template
                        >
                        <template v-else-if="item[0]==='battgClass'"
                            >{{ battgClassOptions.find(i => i.value ===
                            item[1])?.label ||
                            $t('messages.valueWillBeSetToNull').toUpperCase()
                            }}</template
                        >
                        <template v-else
                            >{{item[1] ||
                            $t('messages.valueWillBeSetToNull').toUpperCase()
                            }}</template
                        >
                    </div>
                </div>
            </template>
        </Panel>
    </template>
    <template #summary-panel-middle>
        <h1 style="text-align: center">
            <font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            /><font-awesome-icon
                style="margin-left: 30px"
                :icon="['fas', 'angle-double-down']"
            />
        </h1>
    </template>
</BulkProductAction>
