import {ref, onMounted, computed} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import Calendar from 'primevue/calendar';
import TieredMenu from 'primevue/tieredmenu';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {getAll, update} from '@/services/shipments';
import store from '@/store';
import {i18n} from '@/utils/i18n';
import AnnounceShipmentDialog from '@/components/dialog/shipments/announce-shipment-at-shipping-carrier.vue';
import SendLabelToRemotePrinter from '@/components/dialog/shipments/send-label-to-remote-printer.vue';
import SendDocumentToRemotePrinter from '@/components/dialog/documents/send-document-to-remote-printer.vue';
import {cancelShipment, getTrackingDetails} from '@/services/ups';
import {getPickupDocument, getSingle} from '@/services/documents';
import FileSaver from 'file-saver';
import {useConfirm} from 'primevue/useconfirm';
import FilterMenu from '@/components/filter-menu/filter-menu.vue';
import Dialog from 'primevue/dialog';
import {getAll as getAllProducts} from '@/services/products';
import {computedAsync} from '@vueuse/core';
import Tag from 'primevue/tag';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        InputNumber,
        Dropdown,
        Panel,
        Calendar,
        loading: LoadingPlugin,
        AnnounceShipmentDialog,
        SendLabelToRemotePrinter,
        SendDocumentToRemotePrinter,
        'p-checkbox': Checkbox,
        FilterMenu,
        TieredMenu,
        'p-dialog': Dialog,
        'p-button': Button,
        Tag
    },
    setup() {
        onMounted(() => {
            if (localStorage.getItem('shipment-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filterDefintions);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'createdDate',
                sortOrder: -1,
                filters: filters.value
            };

            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);
        const totalRecords = ref(0);
        const lazyParams: any = ref({});
        const toast = useToast();
        const selectedShipment = ref(null);
        const selectedTrackingNumber = ref(null);
        const displayUpsDialog = ref(false);
        const displaySendToRemotePrinterDialog = ref(false);
        const confirm = useConfirm();
        const selectedDocument = ref(null);
        const displayPickupRemotePrinterDialog = ref(false);

        const workflowExecutionResults = ref([]);
        const showWorkflowExecutionOverviewDialog = ref(false);
        const showPackageInformationDialog = ref(false);

        const shipments = ref();

        const groupedShipmentsSelection = ref([]);

        const bulkMenu = ref(null);

        const filterDefintions: any = {
            shipmentNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            recipient: {value: null, matchMode: FilterMatchMode.CONTAINS},
            shipmentType: {value: null, matchMode: FilterMatchMode.EQUALS},
            status: {value: null, matchMode: FilterMatchMode.EQUALS},
            shippingCarrier: {value: null, matchMode: FilterMatchMode.CONTAINS},
            salesOrder: {value: null, matchMode: FilterMatchMode.CONTAINS},
            warehouse: {value: null, matchMode: FilterMatchMode.CONTAINS},
            packageTrackingNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            createdDate: {value: null, matchMode: FilterMatchMode.DATE_IS}
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const filters = ref(filterDefintions);
        const tableState = ref(null);

        const loadLazyData = () => {
            loading.value = true;
            groupedShipmentsSelection.value = [];

            getAll(Object.assign({columns: ['documents']}, lazyParams.value))
                .then((data) => {
                    totalRecords.value = data.data.total;
                    shipments.value = data.data.items;
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const getStatusCellStyle = (value: string) => {
            if (!value) {
                return '';
            }
            switch (value) {
                case 'NEW':
                    return 'text-blue-400';
                case 'DELIVERY_NOTE_PRINTED':
                    return 'text-orange-400';
                case 'SHIPPED':
                    return 'text-green-400';
                case 'CANCELLED':
                    return 'text-red-400';
            }

            return 'text-primary';
        };

        const getShipmentTypeCellStyle = (value: string) => {
            if (!value) {
                return '';
            }
            switch (value) {
                case 'STANDARD':
                    return 'text-green-400';
                case 'CONSIGNMENT':
                    return 'text-orange-400';
                case 'CUSTOMER_COMPENSATION':
                    return 'text-blue-400';
                case 'SUPPLIER_RETURN':
                    return 'text-red-400';
                case 'CONSIGNMENT_RETURN':
                    return 'text-pink-400';
                case 'INTERNAL':
                    return 'text-purple-400';
            }

            return 'text-primary';
        };

        const onPage = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = filters.value;
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;
            loadLazyData();
        };

        const outputRecipientInGrid = (data: any) => {
            let output = '';
            if (data.recipientCustomerNumber && data.recipientSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )}/${i18n.global.t('labels.shipment.recipientSupplier')} [${
                    data.recipientCustomerNumber
                }/${data.recipientSupplierNumber}]`;
            } else if (data.recipientCustomerNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientCustomer'
                )} [${data.recipientCustomerNumber}]`;
            } else if (data.recipientSupplierNumber) {
                output += `${i18n.global.t(
                    'labels.shipment.recipientSupplier'
                )} [${data.recipientSupplierNumber}]`;
            } else {
                return '';
            }

            if (data.recipientParty?.company) {
                output += ` ${data.recipientParty.company}`;
            } else if (
                data.recipientParty?.lastName &&
                data.recipientParty?.firstName
            ) {
                output += ` ${data.recipientParty.lastName}, ${data.recipientParty.firstName}`;
            }

            return output;
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('weclapp-shipments-edit') !== -1;
        });

        const upsViewPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('ups-shipments-view') !== -1;
        });

        const upsEditPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('ups-shipments-edit') !== -1;
        });

        const groupedSelectionColumnAvailable = computed(() => {
            return (
                shipments.value &&
                shipments.value.some((item: any) =>
                    shippingCarrierAnnouncementAvailable(item)
                )
            );
        });

        const packageInformationData = computedAsync(async () => {
            if (
                !selectedShipment.value?.customAttributes
                    ?.delivery_package_information
            ) {
                return null;
            }
            const parsed = JSON.parse(
                selectedShipment.value?.customAttributes
                    ?.delivery_package_information
            );

            if (!parsed.some((item: any) => item.articleNumber)) {
                return parsed;
            }

            const articleNumbers: any = [];
            parsed.forEach((item: any) => {
                articleNumbers.push(item.articleNumber || null);
            });

            loading.value = true;

            try {
                const productData = await getAllProducts(
                    {
                        first: 0,
                        rows: articleNumbers.filter((item: any) => item).length,
                        filters: {
                            articleNumber: {
                                value: articleNumbers.filter(
                                    (item: any) => item
                                ),
                                matchMode: FilterMatchMode.IN
                            }
                        }
                    },
                    true
                );

                parsed.forEach((p: any) => {
                    Object.assign(p, {
                        article: productData.data.items.find(
                            (pd: any) => pd.articleNumber === p.articleNumber
                        )
                    });
                });
            } finally {
                loading.value = false;
            }

            return parsed;
        }, null);

        const shippingCarrierAnnouncementAvailable = (
            item: any,
            carrierType: any = null
        ) => {
            const matchingCarrier: boolean = carrierType
                ? item.shippingCarrierType === carrierType
                : item.shippingCarrierType !== null;

            const basicConditions =
                upsEditPermissionAvailable.value &&
                item.status === 'SHIPPED' &&
                !item.isPickup &&
                !item.packageTrackingNumber &&
                item.shippingCarrier?.id &&
                matchingCarrier;

            if (!basicConditions) {
                return false;
            }

            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            const resolvedPlatform = platforms.find(
                (item: any) => item.value === currentPlatform
            );

            return (
                resolvedPlatform?.threeLetterId === 'ttl' ||
                resolvedPlatform?.threeLetterId === 'ttd' ||
                item.shippingCarrierType !== 'dhl'
            );
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(tableState.value?.filters || {});

            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            if (
                !stateFilters.every((val) =>
                    filtersDefinitionKeys.includes(val)
                ) ||
                tableState.value?.filters?.shipmentType?.matchMode ===
                    FilterMatchMode.CONTAINS ||
                tableState.value?.filters?.status?.matchMode ===
                    FilterMatchMode.CONTAINS
            ) {
                localStorage.removeItem('shipment-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filterDefintions);
            } else {
                filters.value = Object.assign(
                    {},
                    filterDefintions,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event.rows || dt.value.rows,
                sortField: event.sortField || 'createdDate',
                sortOrder: event.sortOrder || -1,
                filters: filters.value
            };

            loadLazyData();
        };

        const closeDialogListener = async (dataToSubmit: any) => {
            displayUpsDialog.value = false;
            showPackageInformationDialog.value = false;
            if (dataToSubmit) {
                loading.value = true;
                try {
                    const result = await update(
                        selectedShipment.value?.shipmentNumber ||
                            groupedShipments.value[0].shipmentNumber,
                        dataToSubmit
                    );

                    workflowExecutionResults.value = result.data;
                    if (
                        workflowExecutionResults.value.some(
                            (item: any) => item.error
                        )
                    ) {
                        showWorkflowExecutionOverviewDialog.value = true;
                    } else {
                        handleWorkflowResultsConfirmed();
                    }
                } catch (error: any) {
                    toast.error(error.response?.data?.error || error.message);
                } finally {
                    loading.value = false;
                }
            }
        };

        const closePrintDialogListener = () => {
            displaySendToRemotePrinterDialog.value = false;
            displayPickupRemotePrinterDialog.value = false;
        };

        const getUpsTrackingDetails = (trackingNumber: string) => {
            loading.value = true;
            getTrackingDetails(trackingNumber)
                .then((data) => {
                    if (data.data.warnings && data.data.warnings.length > 0) {
                        toast.error(
                            data.data.warnings[0].message ||
                                data.data.warnings[0].code
                        );
                    } else {
                        const date = new Date();
                        const deliveryDateItemLastItemIndex =
                            data.data.package[0].deliveryDate.length - 1;
                        date.setFullYear(
                            data.data.package[0].deliveryDate[
                                deliveryDateItemLastItemIndex
                            ].date.substring(0, 4),
                            data.data.package[0].deliveryDate[
                                deliveryDateItemLastItemIndex
                            ].date.substring(4, 6) - 1,
                            data.data.package[0].deliveryDate[
                                deliveryDateItemLastItemIndex
                            ].date.substring(6, 8)
                        );

                        let startTime = new Date(date.getTime());

                        if (data.data.package[0].deliveryTime.startTime) {
                            startTime.setHours(
                                data.data.package[0].deliveryTime.startTime.substring(
                                    0,
                                    2
                                ),
                                data.data.package[0].deliveryTime.startTime.substring(
                                    2,
                                    4
                                ),
                                data.data.package[0].deliveryTime.startTime.substring(
                                    4,
                                    6
                                )
                            );
                        } else {
                            startTime = null;
                        }

                        let endTime = new Date(date.getTime());
                        if (data.data.package[0].deliveryTime.endTime) {
                            endTime.setHours(
                                data.data.package[0].deliveryTime.endTime.substring(
                                    0,
                                    2
                                ),
                                data.data.package[0].deliveryTime.endTime.substring(
                                    2,
                                    4
                                ),
                                data.data.package[0].deliveryTime.endTime.substring(
                                    4,
                                    6
                                )
                            );
                        } else {
                            endTime = null;
                        }

                        const message = i18n.global.t(
                            'messages.upsTrackingStatus',
                            {
                                status: i18n.global.t(
                                    'labels.shipment.upsTrackingDeliveryStatus.' +
                                        data.data.package[0].deliveryDate[
                                            deliveryDateItemLastItemIndex
                                        ].type
                                ),
                                date: date.toLocaleDateString('de-DE'),
                                time:
                                    data.data.package[0].deliveryTime.type ===
                                    'EOD'
                                        ? '(' +
                                          i18n.global.t(
                                              'labels.shipment.upsTrackingDeliveryStatus.EOD'
                                          ) +
                                          ')'
                                        : i18n.global.t(
                                              startTime
                                                  ? 'labels.between'
                                                  : 'labels.at'
                                          ) +
                                          ' ' +
                                          (startTime
                                              ? startTime.toLocaleTimeString(
                                                    'de-DE'
                                                ) +
                                                ' - ' +
                                                endTime.toLocaleTimeString(
                                                    'de-DE'
                                                )
                                              : endTime.toLocaleTimeString(
                                                    'de-DE'
                                                ))
                            }
                        );
                        if (
                            data.data.package[0].deliveryDate[
                                deliveryDateItemLastItemIndex
                            ].type === 'DEL'
                        ) {
                            toast.success(message);
                        } else {
                            toast.warning(message);
                        }
                    }
                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                });
        };

        const showTrackingLabel = async (proformaDocument: {
            id: string;
            name: string;
            mediaType: string;
        }) => {
            const file = await getSingle(proformaDocument.id);

            const filename = proformaDocument.name;
            FileSaver.saveAs(
                new Blob([file.data], {type: proformaDocument.mediaType}),
                filename
            );
        };

        const voidShipment = (shipmentNumber: string, carrierName: string) => {
            confirm.require({
                message: i18n.global.t('messages.cancelShipmentConfirmation', {
                    trackingNumber: shipmentNumber,
                    carrierName
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    loading.value = true;
                    cancelShipment(shipmentNumber)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loadLazyData();
                        })
                        .catch((error) => {
                            loading.value = false;
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        });
                }
            });
        };

        const printUpsLabel = (shipment: string, trackingNumber: string) => {
            selectedShipment.value = shipment;
            selectedTrackingNumber.value = trackingNumber;
            displaySendToRemotePrinterDialog.value = true;
        };

        const downloadPickupDocument = async (shipment: any) => {
            loading.value = true;
            try {
                const file = await getPickupDocument(shipment.shipmentNumber);
                const filename = file.headers['content-disposition']
                    .split('filename=')[1]
                    .split(';')[0];

                FileSaver.saveAs(
                    new Blob([file.data], {
                        type: file.headers['content-type']
                    }),
                    filename
                );
            } finally {
                loading.value = false;
            }
        };

        const groupedShipments = computed(() => {
            return shipments.value
                ? shipments.value.filter((item: any) => {
                      return groupedShipmentsSelection.value.some(
                          (groupedShipment: any) =>
                              groupedShipment.shipmentNumber ===
                              item.shipmentNumber
                      );
                  })
                : [];
        });

        const bulkItems = computed(() => {
            return [
                {
                    label: i18n.global.t(
                        'labels.shipment.shippingCarrierAnnouncePackage',
                        {carrierName: 'den Versanddienstleister'}
                    ),
                    icon: 'pi pi-truck',
                    disabled:
                        !groupedShipmentsSelection.value ||
                        groupedShipmentsSelection.value.length < 2,
                    command: () => {
                        selectedShipment.value = null;
                        displayUpsDialog.value = true;
                    }
                }
            ];
        });

        const existingPickupDocument = (shipment: any) => {
            return shipment.documents.find((document: {name: string}) => {
                return (
                    document.name.startsWith('LS-ABH-') ||
                    document.name.startsWith('SH-ABH-')
                );
            });
        };

        const clearFilters = () => {
            filters.value = Object.assign({}, filterDefintions);
            onFilter();
        };

        const applyFilters = (savedFilters: any) => {
            const filtersDefinitionKeys = Object.keys(filterDefintions)
                .slice()
                .sort();

            const filtered = Object.keys(savedFilters)
                .filter((key) => filtersDefinitionKeys.includes(key))
                .reduce((obj: any, key) => {
                    obj[key as keyof typeof obj] = savedFilters[key];
                    return obj;
                }, {});

            filters.value = Object.assign({}, filterDefintions, {
                ...filtered
            });
            onFilter();
        };

        const resolveShipmentCarrierType = (data: any) => {
            return (data?.shippingCarrierType ?? '').toUpperCase();
        };

        const printDocument = (document: any) => {
            selectedDocument.value = document;
            displayPickupRemotePrinterDialog.value = true;
        };

        const bulkMenuToggle = (event: any) => {
            bulkMenu.value.toggle(event);
        };

        const handleWorkflowResultsConfirmed = () => {
            showWorkflowExecutionOverviewDialog.value = false;
            workflowExecutionResults.value.length = 0;
            loadLazyData();
        };

        return {
            shipments,
            dt,
            totalRecords,
            loading,
            lazyParams,
            filters,
            onPage,
            onFilter,
            onSort,
            getStatusCellStyle,
            getShipmentTypeCellStyle,
            outputRecipientInGrid,
            editPermissionAvailable,
            upsViewPermissionAvailable,
            upsEditPermissionAvailable,
            onStateRestore,
            displayUpsDialog,
            displaySendToRemotePrinterDialog,
            selectedShipment,
            selectedTrackingNumber,
            closeDialogListener,
            closePrintDialogListener,
            matchModesNumeric,
            getUpsTrackingDetails,
            showTrackingLabel,
            printDocument,
            voidShipment,
            printUpsLabel,
            groupedSelectionColumnAvailable,
            groupedShipmentsSelection,
            groupedShipments,
            shippingCarrierAnnouncementAvailable,
            bulkItems,
            clearFilters,
            applyFilters,
            downloadPickupDocument,
            existingPickupDocument,
            displayPickupRemotePrinterDialog,
            selectedDocument,
            resolveShipmentCarrierType,
            bulkMenu,
            bulkMenuToggle,
            workflowExecutionResults,
            showWorkflowExecutionOverviewDialog,
            handleWorkflowResultsConfirmed,
            showPackageInformationDialog,
            packageInformationData,
            formatter: new Intl.NumberFormat(i18n.global.locale)
        };
    }
};
