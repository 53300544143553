import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const add = async (
    productNumber: string,
    title: string,
    files: Array<any>
): Promise<AxiosResponse> => {
    const formData = new FormData();

    formData.append('title', title);
    files.forEach((file: any) => {
        formData.append('file[]', file);
    });

    return apiClient.post(
        '/api/v1/products/' + productNumber + '/images',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
};

export const addFromUrl = async (
    productNumber: string,
    title: string,
    url: string,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/products/' + productNumber + '/images',
        {
            title,
            url
        },
        platform
            ? {
                  params: {
                      platform
                  }
              }
            : {}
    );
};

export const reorder = async (
    productNumber: string,
    imageIds: Array<{id: string; productMediaId: string}>,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/products/' +
            productNumber +
            '/images' +
            (platform ? '?platform=' + platform : ''),
        {
            imageIds: imageIds.map((a) => a.productMediaId)
        }
    );
};

export const setAsCover = async (
    productNumber: string,
    id: string,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/products/' +
            productNumber +
            '/images' +
            (platform ? '?platform=' + platform : ''),
        {
            coverId: id
        }
    );
};

export const remove = async (
    productNumber: string,
    id: string,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.delete(
        '/api/v1/products/' +
            productNumber +
            '/images/' +
            id +
            (platform ? '?platform=' + platform : '')
    );
};
