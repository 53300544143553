import {useRoute} from 'vue-router';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import LoadingPlugin from 'vue-loading-overlay';
import DescriptionTab from './description/description.vue';
import CommentsTab from './comments/comments.vue';
import ArchivedEmailsTab from './archived-emails/archived-emails.vue';
import ServiceTab from './service/service.vue';
import ReferenceDetailsTab from './reference-details/reference-details.vue';
import RepairDetailsTab from './repair-details/repair-details.vue';
import SendReturnLabelTab from './send-return-label/send-return-label.vue';
import ImagesTab from './images/images.vue';
import {computed, onMounted, ref} from 'vue';
import {useToast} from 'vue-toastification';
import router from '@/router';
import {
    getPriorityCellStyle,
    getSingle,
    getStatusCellStyle
} from '@/services/tickets';
import {add, remove} from '@/services/boomarks';
import store from '@/store';
import {stripTagsAndTruncate} from '@/utils/helpers';
import Panel from 'primevue/panel';

export default {
    components: {
        TabPanel,
        TabView,
        loading: LoadingPlugin,
        DescriptionTab,
        CommentsTab,
        ArchivedEmailsTab,
        ServiceTab,
        ReferenceDetailsTab,
        RepairDetailsTab,
        SendReturnLabelTab,
        Panel,
        ImagesTab
    },
    setup() {
        onMounted(() => {
            loadTicketDetails();
        });

        const route = useRoute();
        const ticketDetails = ref(null);
        const toast = useToast();
        const loading = ref(false);

        const loadTicketDetails = () => {
            loading.value = true;
            getSingle(route.params.id)
                .then((data) => {
                    ticketDetails.value = data.data;
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    router.push({
                        name: 'Tickets'
                    });
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const showServiceTab = computed(() => {
            return (
                ticketDetails.value &&
                ticketDetails.value.customAttributes &&
                (ticketDetails.value.customAttributes
                    .service_repair_description ||
                    ticketDetails.value.customAttributes
                        .service_internal_error_description ||
                    ticketDetails.value.customAttributes
                        .service_customer_error_description)
            );
        });

        const toggleBookmark = () => {
            if (isBookmarked.value) {
                remove(isBookmarked.value)
                    .then(() => {
                        store.dispatch('ui/deleteBookmark', {
                            id: isBookmarked.value
                        });
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    });
            } else {
                add(
                    'ticket',
                    route.params.id,
                    route.fullPath,
                    stripTagsAndTruncate(ticketDetails.value?.subject, 100, '')
                )
                    .then((data) =>
                        store.dispatch('ui/addBookmark', {
                            id: data.data.id,
                            entityName: 'ticket',
                            entityId: route.params.id,
                            description: stripTagsAndTruncate(
                                ticketDetails.value?.subject,
                                100,
                                ''
                            ),
                            path: route.fullPath
                        })
                    )
                    .catch((error) => {
                        toast.error(error.message);
                    });
            }
        };

        const isBookmarked = computed(() => {
            return store.getters['ui/bookmarks'].find(
                (obj: any) =>
                    'ticket' === obj.entityName &&
                    route.params.id === obj.entityId
            )?.id;
        });

        return {
            ticketDetails,
            getStatusCellStyle: getStatusCellStyle,
            getPriorityCellStyle: getPriorityCellStyle,
            showServiceTab,
            loading,
            loadTicketDetails,
            toggleBookmark,
            isBookmarked
        };
    }
};
