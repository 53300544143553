import {computed, onMounted, ref} from 'vue';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {useToast} from 'vue-toastification';
import {
    defaultTicketTemplates,
    getAll,
    ITicketTemplate,
    removeTemplate
} from '@/services/ticket-templates';
import store from '@/store';
import Tag from 'primevue/tag';
import {ILayoutConfig} from '@/interfaces/layout/config';
import AddEditTicketTemplateDialog from '@/components/dialog/tickets/add-edit-ticket-template.vue';
import Button from 'primevue/button';
import {useConfirm} from 'primevue/useconfirm';
import {i18n} from '@/utils/i18n';
import {FilterService} from 'primevue/api';
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        InputText,
        DataTable,
        Column,
        Tag,
        AddEditTicketTemplateDialog,
        'p-button': Button,
        'p-dropdown': Dropdown
    },
    setup() {
        onMounted(() => {
            loadLazyData();

            FilterService.register(sectionFilter.value, (value, filter) => {
                return value.includes(filter);
            });
        });

        const sectionFilter = ref('sectionFilter');

        const ticketTemplates = ref(null);
        const selectedTemplate = ref(null);
        const showTemplateDialog = ref(false);

        const toast = useToast();
        const confirm = useConfirm();

        const loadLazyData = () => {
            getAll({})
                .then((data) => {
                    ticketTemplates.value = [
                        ...defaultTicketTemplates.filter(
                            (cmt: ITicketTemplate) => {
                                return !(data.data || []).some(
                                    (dbItem: any) => dbItem.name === cmt.key
                                );
                            }
                        ),
                        ...data.data
                    ];
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        const closeTemplateDialog = (args: any) => {
            selectedTemplate.value = null;
            showTemplateDialog.value = false;
            if (args) {
                loadLazyData();
            }
        };

        const deleteTemplateHandler = (item: any) => {
            confirm.require({
                message: i18n.global.t('messages.deleteConfirmation', {
                    item: item.title
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    removeTemplate(item.id)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loadLazyData();
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        });
                }
            });
        };

        const filterDefintions: any = {
            section: {value: null, matchMode: sectionFilter.value}
        };
        const filters = ref(filterDefintions);

        return {
            ticketTemplates,
            showTemplateDialog,
            selectedTemplate,
            filters,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 100;
                return data?.id ? '' : 'bg-yellow-' + colorVariant;
            },
            closeTemplateDialog,
            deleteTemplateHandler,
            getSectionLabel: (section: string) => {
                switch (section) {
                    case 'retoure':
                        return i18n.global.t(
                            'labels.ticket.references.incomingGoods.CUSTOMER_RETURN'
                        );
                    case 'comments':
                        return i18n.global.t('labels.ticket.comments');
                    case 'compensationShipment':
                        return 'Kompensationslieferung';
                    default:
                        return section;
                }
            },
            sectionFilter
        };
    }
};
