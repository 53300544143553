import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import {computed, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';
import router from '@/router';
import {useVuelidate} from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import InputNumber from 'primevue/inputnumber';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {i18n} from '@/utils/i18n';
import {getSingle, updateDimensions} from '@/services/products';
import Panel from 'primevue/panel';

export default {
    components: {
        'p-button': Button,
        'app-checkbox': Checkbox,
        loading: LoadingPlugin,
        DataTable,
        Column,
        Panel,
        InputText,
        InputNumber
    },
    setup() {
        const route = useRoute();
        const loading = ref(false);
        const toast = useToast();
        const productDetails = ref(null);

        const shipmentNumberInputField = ref(null);
        const articleWeightInputField = ref(null);
        const articleHeightInputField = ref(null);
        const articleWidthInputField = ref(null);
        const articleLengthInputField = ref(null);
        const articleEanInputField = ref(null);
        const timeoutId = ref(null);

        const displayConfirmation = ref(false);

        const dt = ref();
        const expandedRows = ref([]);

        onMounted(() => {
            loadProductDetails();
        });

        const state = ref({
            articleGrossWeight: null,
            articleLength: null,
            articleWidth: null,
            articleHeight: null,
            ean: null,
            article_flag_akku_groesser_100wh: false,
            article_flag_aerosole: false,
            article_flag_akku_kleiner_100wh: false,
            article_single_package: false
        });

        const rules = {
            articleGrossWeight: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            articleLength: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            articleWidth: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                ),
                lessThanLength: helpers.withMessage(
                    i18n.global.t('messages.valueNeedsToBeLessThanLength'),
                    (value: any) => {
                        return (
                            !value ||
                            !state.value?.articleLength ||
                            value <= state.value.articleLength
                        );
                    }
                )
            },
            articleHeight: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                ),
                lessThanLength: helpers.withMessage(
                    i18n.global.t('messages.valueNeedsToBeLessThanLength'),
                    (value: any) => {
                        return (
                            !value ||
                            !state.value?.articleLength ||
                            value <= state.value.articleLength
                        );
                    }
                )
            },
            ean: {},
            article_flag_akku_groesser_100wh: {},
            article_flag_aerosole: {},
            article_flag_akku_kleiner_100wh: {},
            article_single_package: {}
        };

        const v$ = useVuelidate(rules, state);
        const submitted = ref(false);
        const savingInProgress = ref(false);

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;
            const toSubmit = Object.assign({}, state.value);

            updateDimensions(
                productDetails.value.weclapp?.articleNumber,
                toSubmit
            )
                .then((data) => {
                    savingInProgress.value = false;
                    if (data?.data?.shopwareFailed) {
                        toast.warning(
                            i18n.global.t('messages.changesSavedOnlyInWeclapp')
                        );
                    } else {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully'),
                            {
                                timeout: 500
                            }
                        );
                    }

                    router.push(
                        originalStoragePlace.value && originalPath.value
                            ? {
                                  name: 'ProductsWithIncompleteDimensions',
                                  query: {
                                      storagePlace: originalStoragePlace.value,
                                      path: originalPath.value.join('|')
                                  }
                              }
                            : {
                                  name: 'ProductsWithIncompleteDimensions'
                              }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    savingInProgress.value = false;
                });
        };

        const initializeCustomAttributes = () => {
            if (!productDetails.value) {
                return;
            }

            [
                'article_flag_akku_groesser_100wh',
                'article_flag_aerosole',
                'article_flag_akku_kleiner_100wh',
                'article_single_package'
            ].forEach((item) => {
                const temp: {[k: string]: boolean} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[item] = attributes[item] || null;
                Object.assign(state.value, temp);
            });
        };

        const loadProductDetails = async () => {
            loading.value = true;
            getSingle(route.params.id)
                .then((data) => {
                    if (data?.data?.weclapp) {
                        productDetails.value = data.data;
                        Object.assign(state.value, {
                            articleGrossWeight:
                                productDetails.value.weclapp
                                    ?.articleGrossWeight || null,
                            articleLength: productDetails.value.weclapp
                                ?.articleLength
                                ? productDetails.value.weclapp.articleLength *
                                  100
                                : null,
                            articleWidth: productDetails.value.weclapp
                                ?.articleWidth
                                ? productDetails.value.weclapp.articleWidth *
                                  100
                                : null,
                            articleHeight: productDetails.value.weclapp
                                ?.articleHeight
                                ? productDetails.value.weclapp.articleHeight *
                                  100
                                : null,
                            ean: productDetails.value.weclapp?.ean || null
                        });

                        setTimeout(() => {
                            setFocusOnDimensionField();
                        }, 100);

                        initializeCustomAttributes();
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                        router.push({
                            name: 'ProductsWithIncompleteDimensions'
                        });
                    }
                })
                .catch((error) => {
                    if (error.response?.status === 404) {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                    } else {
                        toast.error(error.message);
                    }
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const setFocusOnDimensionField = (fieldName: string = '') => {
            let inputField: HTMLElement | null = null;

            switch (fieldName) {
                case 'articleGrossWeight':
                    inputField = articleLengthInputField.value
                        ?.$el as HTMLElement;
                    break;
                case 'articleLength':
                    inputField = articleWidthInputField.value
                        ?.$el as HTMLElement;
                    break;
                case 'articleWidth':
                    inputField = articleHeightInputField.value
                        ?.$el as HTMLElement;
                    break;
                case 'articleHeight':
                    inputField = articleEanInputField.value?.$el as HTMLElement;
                    break;

                default:
                    inputField = articleWeightInputField.value
                        ?.$el as HTMLElement;
            }

            if (inputField?.querySelector('input')) {
                inputField.querySelector('input').focus();
            } else if (inputField) {
                inputField.focus();
            }
        };

        const originalPath = computed(() => {
            if (history?.state?.originalData) {
                const originalData = JSON.parse(history.state.originalData);
                return originalData['path'];
            }
            return null;
        });

        const originalStoragePlace = computed(() => {
            if (history?.state?.originalData) {
                const originalData = JSON.parse(history.state.originalData);
                return Object.keys(originalData['singleStoragePlace'])[0];
            }
            return null;
        });

        return {
            loading,
            productDetails,
            dt,
            expandedRows,
            state,
            v$,
            submitted,
            savingInProgress,
            handleSubmit,
            displayConfirmation,
            shipmentNumberInputField,
            articleWeightInputField,
            articleHeightInputField,
            articleWidthInputField,
            articleLengthInputField,
            articleEanInputField,
            originalPath,
            originalStoragePlace,
            locale: i18n.global.locale,
            onPastedDimension: (fieldName: string) => {
                if (timeoutId.value) {
                    clearTimeout(timeoutId.value);
                }
                timeoutId.value = setTimeout(() => {
                    setFocusOnDimensionField(fieldName);
                }, 50);
            }
        };
    }
};
