import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ccdf785"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-between w-full" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = {
  key: 0,
  class: "grid p-fluid mb-3"
}
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = { class: "grid p-fluid mb-3" }
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = {
  key: 0,
  class: "col field"
}
const _hoisted_9 = {
  key: 1,
  class: "grid p-fluid mb-3"
}
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = { class: "col field" }

export function render(_ctx, _cache) {
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Panel = _resolveComponent("Panel")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[6] || (_cache[6] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createVNode(_component_Panel, {
      toggleable: _ctx.toggleable,
      collapsed: _ctx.collapsed
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('labels.shipment.shippingCarrierAdditionalData',
                    {carrierName: (_ctx.shippingCarrierType
                    ||'ups').toUpperCase()})), 1)
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.shippingCarrierType === 'ups')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsDeliveryCosts')), 1),
                _createVNode(_component_p_dropdown, {
                  modelValue: _ctx.v$.deliveryCosts.$model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.deliveryCosts.$model) = $event)),
                  options: [{label: 'Versender (Teltec)', value: 0}, {label: 'Kunde/Belegadresse (Dropshipment)', value: 1}, {label: 'Lieferadresse', value: 2}],
                  optionLabel: "label",
                  optionValue: "value",
                  class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.deliveryCosts.$invalid && _ctx.submitted}])
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.deliveryCosts.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsCustomerAccountNumber')), 1),
                _createVNode(_component_InputText, {
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsAccountNumber.$model,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.upsAccountNumber.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsAccountNumber.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsAccountNumber.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsSignatureMandatory')), 1),
            _createVNode(_component_p_checkbox, {
              modelValue: _ctx.v$.upsSignature.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.upsSignature.$model) = $event)),
              binary: "",
              class: "ml-2 mb-1"
            }, null, 8, ["modelValue"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsSignature.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          (_ctx.shippingCarrierType === 'ups')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsAccessPointId')), 1),
                _createVNode(_component_InputText, {
                  icon: "align-justify",
                  type: "text",
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsAccessPointId.$model,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.upsAccessPointId.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsAccessPointId.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsAccessPointId.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.shippingCarrierType === 'ups')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsShipmentDescription')), 1),
                _createVNode(_component_InputText, {
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsShipmentDescription.$model,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.upsShipmentDescription.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsShipmentDescription.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsShipmentDescription.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.upsPackageDescription')), 1),
                _createVNode(_component_InputText, {
                  icon: "align-justify",
                  type: "text",
                  autocomplete: "off",
                  modelValue: _ctx.v$.upsPackageDescription.$model,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.upsPackageDescription.$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$.upsPackageDescription.$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.upsPackageDescription.$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["toggleable", "collapsed"])
  ], 32))
}