import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/print-configurations', {
        params: {
            start: input.first || 0,
            end: (input.first || 0) + (input.rows || 10),
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            filters: JSON.stringify(clearEmptyFilters(input.filters || {}))
        }
    });
};

export const addNew = async (payload: any): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/print-configurations', payload);
};

export const editExisting = async (
    configurationId: string,
    payload: any
): Promise<AxiosResponse> => {
    const {author, printer, printerSize, attributeValue} = payload;
    return apiClient.put('/api/v1/print-configurations/' + configurationId, {
        author,
        printer,
        printerSize,
        attributeValue
    });
};

export const remove = async (
    printConfigurationId: any
): Promise<AxiosResponse> => {
    return apiClient.delete(
        '/api/v1/print-configurations/' + printConfigurationId
    );
};
