import {ref, watch} from 'vue';
import Textarea from 'primevue/textarea';

export default {
    components: {
        'p-textarea': Textarea
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any) {
        const ticketDetails = ref(props.ticketDetails);

        watch(
            () => props.ticketDetails,
            (newValue) => {
                if (newValue) {
                    ticketDetails.value = newValue;
                }
            }
        );

        return {
            ticketDetails
        };
    }
};
