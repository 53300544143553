<PrimeDialog
    v-model:visible="showDialog"
    :header="$t('labels.shipment.createHeadlessShipment')"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '960px'}"
    :modal="true"
>
    <loading v-model:active="loading" />
    <form @submit.prevent="handleWeightSubmit">
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label> {{$t('labels.shipment.singleCustomer')}} </label>
                <div>
                    <InputSwitch v-model="singleShipment" />
                </div>
            </div>
            <div class="field col" v-if="singleShipment">
                <label> {{$t('labels.shipment.referenceNumber')}} </label>

                <InputText
                    v-model="v$.referenceNumber.$model"
                    :class="{'p-invalid':v$.referenceNumber.$invalid && submitted}"
                >
                </InputText>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.referenceNumber.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label> {{$t('labels.shipment.shippingCarrier')}} </label>
                <p-dropdown
                    v-model="v$.shippingCompany.$model"
                    :options="availableCarriers"
                    optionLabel="label"
                    optionValue="value"
                    @change="setDropdownValue('shippingCompany', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.shippingCompany.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.shippingCompany.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="field col">
                <label> {{$t('labels.shipment.warehouse')}} </label>

                <p-dropdown
                    v-model="v$.warehouse.$model"
                    :options="warehouses"
                    optionLabel="name"
                    optionValue="id"
                    @change="setDropdownValue('warehouse', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.warehouse.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.shippingCompany.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <template v-if="singleShipment">
            <div class="formgrid grid p-fluid">
                <div class="field col">
                    <label> {{$t('labels.customer')}} </label>

                    <InputText
                        v-model="v$.customerName.$model"
                        :class="{'p-invalid':v$.customerName.$invalid && submitted}"
                    >
                    </InputText>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.customerName.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="field col">
                    <label> {{$t('labels.address')}} </label>

                    <InputText
                        v-model="v$.address.$model"
                        :class="{'p-invalid':v$.address.$invalid && submitted}"
                    >
                    </InputText>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.address.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="field col">
                    <label> {{$t('labels.contactPerson')}} </label>

                    <InputText
                        v-model="v$.contactPerson.$model"
                        :class="{'p-invalid':v$.contactPerson.$invalid && submitted}"
                    >
                    </InputText>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.contactPerson.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="field col">
                    <label> {{$t('labels.phoneNumber')}} </label>

                    <InputText
                        v-model="v$.phoneNumber.$model"
                        :class="{'p-invalid':v$.phoneNumber.$invalid && submitted}"
                    >
                    </InputText>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.phoneNumber.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col">
                    <label> {{$t('labels.city')}} </label>

                    <InputText
                        v-model="v$.city.$model"
                        :class="{'p-invalid':v$.city.$invalid && submitted}"
                    >
                    </InputText>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.city.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="field col">
                    <label> {{$t('labels.zipCode')}} </label>
                    <InputText
                        v-model="v$.zipCode.$model"
                        :class="{'p-invalid':v$.zipCode.$invalid && submitted}"
                    >
                    </InputText>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.zipCode.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="field col">
                    <label> {{$t('labels.email')}} </label>
                    <InputText
                        v-model="v$.emailAddress.$model"
                        :class="{'p-invalid':v$.emailAddress.$invalid && submitted}"
                    >
                    </InputText>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.emailAddress.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="field col">
                    <label> {{$t('labels.country')}} </label>

                    <p-dropdown
                        v-model="v$.countryCode.$model"
                        :options="countryOptions"
                        filter
                        optionLabel="label"
                        optionValue="value"
                        @change="setDropdownValue('countryCode', $event)"
                        :showClear="true"
                        :class="{'p-invalid':v$.countryCode.$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.countryCode.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="w-full">
                <PackageDimensionsForm
                    :hasDangerousGoods="false"
                    headless
                    @data-entered="onPackageDimensionsEntered"
                ></PackageDimensionsForm>
            </div>
            <!--<div class="formgrid grid p-fluid">
                <div class="field col">
                    <label>{{ $t('labels.shipment.numberOfLabels') }} </label>

                    <InputNumber
                        :locale="locale"
                        showButtons
                        :min="1"
                        autocomplete="off"
                        v-model="v$.packageCount.$model"
                        :class="{'p-invalid':v$.packageCount.$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.packageCount.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="field col">
                    <label>{{ $t('labels.shipment.groupedWeight') }} </label>

                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :minFractionDigits="1"
                        autocomplete="off"
                        v-model="v$.weight.$model"
                        :class="{'p-invalid':v$.weight.$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.weight.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>-->
        </template>
        <template v-else>
            <div class="formgrid grid p-fluid">
                <div class="field col">
                    <label>
                        {{$t('labels.shipment.fileWithCustomerAddresses')}}
                    </label>

                    <p-dropdown
                        v-model="v$.importId.$model"
                        :options="importOptions"
                        optionLabel="label"
                        optionValue="value"
                        @change="setDropdownValue('importId', $event)"
                        :showClear="true"
                        :class="{'p-invalid':v$.importId.$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.importId.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </template>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button severity="success" type="submit">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
