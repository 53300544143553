<div class="m-2">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        :paginator="documents && documents.length > 20"
        :rows="20"
        :lazy="true"
        :value="documents"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50,100,200]"
        current-page-report-template="{first} to {last} of {totalRecords}"
    >
        <Column :header="$t('labels.file')" style="min-width: 10rem">
            <template #body="{data}">
                <span v-if="data.fileExtension"
                    >{{data.fileName}}.{{data.fileExtension}}</span
                >
                <span v-else>{{data.fileName}}</span>
            </template>
        </Column>
        <Column :header="$t('labels.description')" style="min-width: 10rem">
            <template #body="{data}"> {{data.title}} </template>
        </Column>
        <Column :header="$t('labels.fileSize')" style="min-width: 8rem">
            <template #body="{data}"> {{data.fileSize}} bytes </template>
        </Column>
        <Column style="min-width: 5rem">
            <template #body="{data}">
                <div class="button-container">
                    <i
                        class="pi pi-trash text-red-600 text-sm mr-2"
                        @click="showDeleteDialog(data)"
                    ></i>
                    <i
                        class="pi pi-pencil text-sm mr-2"
                        @click="showAddEditDialog(data)"
                    ></i>
                </div>
            </template>
        </Column>
    </DataTable>
</div>
<AddEditDialog
    :displayResponsive="displayAddEditDialog"
    :product-number="shopwareDetails?.productNumber"
    :selectedDocument="selectedDocument"
    @close-dialog="closeDialogListener"
    :platform="platform"
/>
<DeleteDialog
    :displayResponsive="displayDeleteDialog"
    :product-number="shopwareDetails?.productNumber"
    :selectedDocument="selectedDocument"
    @close-dialog="closeDialogListener"
    :platform="platform"
/>
