import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-602938f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_ExternalCarrierForm = _resolveComponent("ExternalCarrierForm")
  const _component_PackageDimensionsForm = _resolveComponent("PackageDimensionsForm")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.shipment.shippingCarrierAdditionalData', {carrierName: (_ctx.mainShipment?.shippingCarrierType || 'ups').toUpperCase()}),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '960px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => (_ctx.handleWeightSubmit && _ctx.handleWeightSubmit(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ExternalCarrierForm, {
            "shipping-carrier-type": _ctx.mainShipment?.shippingCarrierType,
            onDataEntered: _ctx.onExternalCarrierDataEntered
          }, null, 8, ["shipping-carrier-type", "onDataEntered"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PackageDimensionsForm, {
            shipment: _ctx.mainShipment,
            hasDangerousGoods: _ctx.showUpsDangerousGoodsButton,
            onDataEntered: _ctx.onPackageDimensionsEntered
          }, null, 8, ["shipment", "hasDangerousGoods", "onDataEntered"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            severity: "success",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}