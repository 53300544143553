import {createApp} from 'vue';
import App from './app/app.vue';
import router from './router';
import store from './store';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faLock,
    faEnvelope,
    faAddressBook,
    faAnchor,
    faAngleDoubleDown,
    faLink,
    faAlignJustify,
    faTruck,
    faKey,
    faUser,
    faBan,
    faFileCsv,
    faRuler,
    faBoxOpen,
    faHistory,
    faHandshake,
    faExclamationTriangle,
    faToolbox,
    faChartLine,
    faUpload,
    faDollarSign,
    faDolly,
    faClock,
    faExpandAlt,
    faBell,
    faHouseUser,
    faHandHolding,
    faWarehouse,
    faCartFlatbed,
    faBasketShopping,
    faHatWizard,
    faList,
    faGear,
    faDownload,
    faTags,
    faPlus,
    faBusinessTime,
    faMarker,
    faGift,
    faCommentDots,
    faPlay,
    faPrint,
    faCalendarAlt,
    faStar,
    faTrashCan,
    faAngleDoubleUp,
    faWaveSquare,
    faPhotoFilm,
    faCartShopping,
    faCodeFork,
    faCodeCompare,
    faSpellCheck,
    faArrowDown91
} from '@fortawesome/free-solid-svg-icons';
import Toast, {PluginOptions} from 'vue-toastification';
import {VueWindowSizePlugin} from 'vue-window-size/plugin';
import PrimeVue from 'primevue/config';
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import countries from 'i18n-iso-countries';
import ConfirmationService from 'primevue/confirmationservice';

import 'vue-loading-overlay/dist/css/index.css';
import 'vue-toastification/dist/index.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/brands.min.css';
import './assets/scss/styles.scss';

import countriesEnglish from 'i18n-iso-countries/langs/en.json';
import countriesGerman from 'i18n-iso-countries/langs/de.json';

import {i18n} from './utils/i18n';

import {submitAppError} from './services/system-logs';
import {faDhl, faUps} from '@fortawesome/free-brands-svg-icons';

library.add(
    faLock,
    faEnvelope,
    faAddressBook,
    faAnchor,
    faAngleDoubleDown,
    faAngleDoubleUp,
    faLink,
    faAlignJustify,
    faTruck,
    faKey,
    faUser,
    faBan,
    faFileCsv,
    faRuler,
    faBoxOpen,
    faHistory,
    faHandshake,
    faExclamationTriangle,
    faToolbox,
    faChartLine,
    faUpload,
    faDownload,
    faDollarSign,
    faDolly,
    faClock,
    faExpandAlt,
    faBell,
    faHouseUser,
    faHandHolding,
    faWarehouse,
    faCartFlatbed,
    faUps,
    faDhl,
    faBasketShopping,
    faHatWizard,
    faList,
    faGear,
    faTags,
    faPlus,
    faBusinessTime,
    faMarker,
    faGift,
    faCommentDots,
    faPlay,
    faPrint,
    faCalendarAlt,
    faStar,
    faTrashCan,
    faWaveSquare,
    faPhotoFilm,
    faCartShopping,
    faCodeFork,
    faCodeCompare,
    faSpellCheck,
    faArrowDown91
);

const options: PluginOptions = {
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'button',
    icon: true,
    rtl: false
};

countries.registerLocale(countriesEnglish);
countries.registerLocale(countriesGerman);

const app = createApp(App);

app.config.errorHandler = function (err: any) {
    submitAppError({
        message: err.message || err,
        stack: JSON.stringify({
            fullPath: router.currentRoute?.value?.fullPath,
            name: router.currentRoute?.value?.name,
            path: router.currentRoute?.value?.path
        })
    });
};

app.component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(VueWindowSizePlugin)
    .use(Toast, options)
    .use(ConfirmationService)
    .use(i18n)
    .use(PrimeVue)
    .directive('tooltip', Tooltip)
    .directive('badge', BadgeDirective)
    .mount('#app');
