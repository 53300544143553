<PrimeDialog
    :header="$t('labels.ticket.addNewRetoure', {ticketNumber: ticketDetails?.ticketNumber, ticketSubject: truncatedSubject})"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '65vw'}"
    :closable="false"
    :modal="true"
>
    <AddRetoureWizard
        :ticket-details="ticketDetails"
        @retoure-saved="closeResponsive(true)"
        @retoure-canceled="closeResponsive"
    />
</PrimeDialog>
