<PrimeDialog
    :header="selectedTemplate ? $t('labels.editTemplate'): $t('labels.addTemplate')"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '960px'}"
    modal
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.subject')}} </label>
                <InputText
                    :placeholder="$t('labels.subject')"
                    autocomplete="off"
                    v-model="v$.subject.$model"
                    :class="{'p-invalid':v$.subject.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.subject.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            <div class="col-1 field">
                <label> {{$t('labels.public')}} </label>
                <div>
                    <p-checkbox v-model="v$.public.$model" binary></p-checkbox>
                </div>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> Bereich </label>
                <SelectButton
                    v-model="v$.section.$model"
                    :options="[{'label': getSectionLabel('comments'), value: 'comments'}, {'label': getSectionLabel('retoure'), value: 'retoure'}, {'label': getSectionLabel('compensationShipment'), value: 'compensationShipment'}]"
                    optionLabel="label"
                    multiple
                    aria-labelledby="multiple"
                />

                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.section.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label> {{$t('labels.content')}} </label>
                <tiny
                    :tinymceScriptSrc="tinyUrl"
                    v-model="v$.body.$model"
                    :init="{
                    height: 300,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                    toolbar: 'undo redo | styleselect | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    browser_spellcheck: true,
                }"
                ></tiny>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.body.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="w-full flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>

            <p-button severity="success" type="submit">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
